import * as Yup from "yup";
import { IChangePassword, IProfile } from "../../../types/user/User";

export const profileInitialValues = (values: IProfile | null, companyName?: string) => {
    const _values = {
        first_name: values?.first_name || "",
        last_name: values?.last_name || "",
        company_name: companyName || "",
        mobile: values?.mobile || "",
        job_title: values?.job_title || "",
    };
    return _values;
};

export const resetPasswordInitialValues = {
    old_password: "",
    new_password: "",
    confirm_password: "",
};


export const profileValidationSchema = Yup.object().shape({
    first_name: Yup.string()
        .min(2, "Too short!")
        .max(70, "Too long!")
        .required("*First name is required"),
    last_name: Yup.string()
        .min(2, "Too short!")
        .max(70, "Too long!")
        .required("*Last name is required"),
    mobile: Yup.string().required("*Phone number is required"),
    job_title: Yup.string().required("*Job title is required"),
});

export const resetPasswordValidationSchema = Yup.object().shape({
    old_password: Yup.string().required("*Old password is required"),
    new_password: Yup.string()
        .required("*New password is required")
        .matches(
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
            "Password must contain atleast 1 number,1 special character,1 uppercase and 1 lowercase"),
    confirm_password: Yup.string()
        .required("*Re-enter new password for confirmation")
        .oneOf([Yup.ref("new_password")], "Passwords must match"),

});

export const generateProfilePayload = (data: IProfile, isOwner?: boolean) => {

    const payload = {
        first_name: data?.first_name,
        last_name: data?.last_name,
        ...(isOwner ? { company_name: data?.company_name } : null),
        mobile: data?.mobile?.toString(),
        job_title: data?.job_title,
    };
    return payload;
};

export const generateResetPasswordPayload = (data: IChangePassword) => {

    const payload = {
        oldPassword: data?.old_password,
        newPassword: data?.new_password,
    };
    return payload;
};
