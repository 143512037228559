import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CardComponent } from '../../../components/card/CardComponent';
import CompanyTable from './components/CompanyTable';
import ModalView from '../../../components/modal/Modal';
import CompanyEditDetail from './components/CompanyEditDetail';
import { debounce, removeQueryParams, setQueryParams } from '../../../utils/Index';
import { getCompanyList } from '../../../services/SuperAdmin';
import { ICompanyList } from '../../../types/dashboard/company/Company';
import CompanyLimitDetail from './components/CompanyLimitDetail';

const Companies = () => {

  const [companyDetailModal, setCompanyDetailModal] = useState<boolean>(false);
  const [companyLimitModal, setCompanyLimitModal] = useState<boolean>(false);
  const [searchCompany, setSearchCompany] = useState<string>('');
  const [searchCompanyQuery, setSearchCompanyQuery] = useState<string | null>(null);
  const [companyList, setCompanyList] = useState<ICompanyList[]>([]);
  const [companyCount, setCompanyCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleView = (id: number) => {
    setQueryParams({ id: id.toString() });
    setCompanyDetailModal(true);
  };

  const handleTier = (id: number) => {
    setQueryParams({ id: id.toString() });
    setCompanyLimitModal(true);
  };

  const handleCompanyModalClose = () => {
    removeQueryParams();
    setCompanyDetailModal(false);
  };

  const handleCompanyLimitModalClose = () => {
    removeQueryParams();
    setCompanyLimitModal(false);
  };

  const handleSearchCompany = useCallback(
    (val: string) => {
      setSearchCompanyQuery(val);
    }, []);

  const debouncedSearch = debounce(handleSearchCompany, 800);

  const handleSearchCompanyChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchCompany(e.target.value);
      debouncedSearch(e.target.value);
      setCurrentPage(1);
    }, [debouncedSearch]
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const query = useMemo(() => {
    let _query = {
      "select": "id,company_name,created_at",
      ...(searchCompanyQuery ? { 'q[company_name][like]': `%${searchCompanyQuery}%` } : {}),
      limit: 10,
      page: currentPage
    };
    return _query;
  }, [currentPage, searchCompanyQuery]);

  const fetchCompanyList = useCallback(async () => {
    try {
      const result = await getCompanyList(query);
      if (result.data.data) {
        setCompanyList(result.data.data.rows);
        setCompanyCount(result.data.data?.count);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }, [query]);

  useEffect(() => {
    fetchCompanyList();
  }, [fetchCompanyList]);

  return (
    <>
      <CardComponent
        title="Companies"
        search
        searchValue={searchCompany}
        searchPlaceholder="Search Company"
        handleSearch={handleSearchCompanyChange}
      >
        <CompanyTable data={companyList}
          count={companyCount}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          handleView={handleView}
          handleTier={handleTier}
        />
      </CardComponent>
      <ModalView open={companyDetailModal} closeModal={handleCompanyModalClose} >
        <CompanyEditDetail updateCompanyList={fetchCompanyList} closeModal={handleCompanyModalClose} />
      </ModalView>
      <ModalView open={companyLimitModal} closeModal={handleCompanyLimitModalClose} >
        <CompanyLimitDetail updateCompanyList={fetchCompanyList} closeModal={handleCompanyLimitModalClose} />
      </ModalView>
    </>
  );
};

export default Companies;