export enum ModuleNames {
  EMAILS = "emails",
  TEMPLATES = "templates",
  TEAMS = "teams",
  CONTACTS = "contacts",
  SETTINGS = "settings",
  USERS = "users",
  CATEGORY = "category",
}

export enum BasicPermissionTypes {
  CREATE = "create",
  UPDATE = "update",
  READ = "read",
  DELETE = "delete",
  UPDATE_PLAN = "update subscription plan",
  SET_BRAND_COLOR = "set brand colours",
}