import * as Yup from 'yup';
import { IPlanListForm } from '../../../types/dashboard/tier-limit/TierCompany';

export const getInitialValues = (data: IPlanListForm | null) => {
  const values = {
    admin_limit: data?.admin_limit || '',
    email_limit: data?.email_limit || '',
    team_limit: data?.team_limit || '',
    team_members_limit: data?.team_members_limit || '',
    user_limit: data?.user_limit || '',
    secondary_team_functionality: data?.secondary_team_functionality || false,
    product_id: data?.product_id || '',
  };
  return values;
};

export const validationSchema = Yup.object().shape({
  admin_limit: Yup.string().required('Admin limit is required'),
  email_limit: Yup.string().required('Email limit is required'),
  team_limit: Yup.string().required('Team limit is required'),
  user_limit: Yup.string().required('User limit is required'),
  team_members_limit: Yup.string().required('Team member limit is required'),
  product_id: Yup.string().required('Product id limit is required'),
});

export const generatePayload = (data: IPlanListForm | null) => {
  const payload = {
    admin_limit: data?.admin_limit,
    email_limit: data?.email_limit,
    team_limit: data?.team_limit,
    team_members_limit: data?.team_members_limit,
    user_limit: data?.user_limit,
    secondary_team_functionality: data?.secondary_team_functionality,
    product_id: data?.product_id,
  };

  return payload;
};
