import { combineReducers } from '@reduxjs/toolkit';
import { reducer as commonReducer } from './slices/CommonSlice';
import { reducer as authReducer } from './slices/AuthSlice';
import { reducer as toastReducer } from './slices/ToastSlice';
import { reducer as themeReducer } from './slices/SettingSlice';
import { reducer as teamModalReducer } from './slices/TeamModalSlice';
import { reducer as permissionReducer } from './slices/permissionSlice';
import { reducer as planModalReducer } from './slices/PlanModalSlice';
import { reducer as superAdminReducer } from './slices/SuperAdminSlice';
import { reducer as dashboardAdminReducer } from './slices/DashboardModalSlice';

const rootReducer = combineReducers({
  common: commonReducer,
  auth: authReducer,
  toast: toastReducer,
  theme: themeReducer,
  modal: teamModalReducer,
  permission: permissionReducer,
  planModal: planModalReducer,
  superAdmin: superAdminReducer,
  dashboardModals: dashboardAdminReducer,
});

export default rootReducer;
