import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";

interface ICardCount {
  title: string;
  count?: number | string;
  handleClick?: () => void;
  icon?: JSX.Element;
}

const CardCount = ({ title, count, handleClick, icon }: ICardCount) => {
  return (
    <Card
      sx={{ background: "var(--PrimaryBlue)", cursor: "pointer" }}
      onClick={handleClick}
    >
      <CardContent>
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              width: { xs: "3rem", xl: "4rem" },
              height: { xs: "3rem", xl: "4rem" },
              color: "var(--PrimaryBlue)",
              borderRadius: "100px",
              backgroundColor: "var(--White)",
              padding: "0.75rem",
            }}
          >
            {icon}
          </Box>
          <Box
            sx={{
              width: { xs: "calc(100% - 3rem)", xl: "calc(100% - 4rem)" },
              paddingLeft: {
                xs: "0.625rem",
                md: "0.75rem",
                lg: "1rem",
                xl: "1.25rem",
              },
              color: "var(--PrimaryBlue)",
            }}
          >
            <Typography
              variant="h4"
              component="p"
              style={{ color: "var(--White)" }}
            >
              {count ? count : "--"}
            </Typography>

            <Typography
              variant="h6"
              component="p"
              color="white"
              sx={{ fontSize: { xs: "1rem", lg: "1.125rem", xl: "1.25rem" } }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardCount;

// CorporateFare MarkEmailReadOutlined PeopleAltOutlined
