import { experimentalStyled } from "@mui/material/styles";
// import styled from "@emotion/styled";
export const H1 = experimentalStyled("h1")(({ theme }) => ({
  fontSize: "var(--text56)",
  lineHeight: "4.25rem",
  fontWeight: "700",
  fontFamily:'var(--headerFont)',
}));
export const H2 = experimentalStyled("h2")(({ theme }) => ({
  fontSize: "var(--text56)",
  lineHeight: "4.25rem",
  fontWeight: "700",
  fontFamily:'var(--headerFont)',
}));
export const H3 = experimentalStyled("h3")(({ theme }) => ({
  fontSize: "var(--text56)",
  lineHeight: "4.25rem",
  fontWeight: "700",
  fontFamily:'var(--headerFont)',
}));
export const H4 = experimentalStyled("h4")(({ theme }) => ({
  fontSize: "var(--text56)",
  lineHeight: "4.25rem",
  fontWeight: "700",
  fontFamily:'var(--headerFont)', 
}));
export const H5 = experimentalStyled("h5")(({ theme }) => ({
  fontSize: "var(--text56)",
  lineHeight: "4.25rem",
  fontWeight: "700",
  fontFamily:'var(--headerFont)',
}));
export const H6 = experimentalStyled("h6")(({ theme }) => ({
  fontSize: "var(--text56)",
  lineHeight: "4.25rem",
  fontWeight: "700",
  fontFamily:'var(--headerFont)',
}));

export const Anchor = experimentalStyled("a")(({ theme }) => ({
  fontSize: "var(--text12)",
  lineHeight: "1.25rem",
  textDecoration: "underline",
  color: "var(--PrimaryBrandColour)",
  textUnderlineOffset: "3px",
  display: "inline-block",
  cursor: "pointer",
  fontFamily: "var(--bodyFont)",
  fontWeight: "500",
  "&:hover": {
    color: "var(--Gray)",
  },
}));
export const SiteButton = experimentalStyled("button")(({ theme }) => ({
  minWidth: "200px",
  padding: "0.75rem 1.375rem",
  fontSize: "var(--text18)",
  lineHeight: "1.8125rem",
  fontFamily: "var(--bodyFont)",
}));

export const CardTitle = experimentalStyled("h4")(({ theme }) => ({
  fontSize: "var(--text32)",
  lineHeight: "2.3375rem",
  fontWeight: "600",
  color: "var(--PrimaryBrandColour)",
  fontFamily: "var(--headerFont)",
}));

export const CardTitleSmall = experimentalStyled("p")(({ theme }) => ({
  fontSize: " var(--text20)",
  lineHeight: "1.25rem",
  fontWeight: "600",
  color: "var(--PrimaryBrandColour)",
  fontFamily: "var(--headerFont)",
}));

// TYPOGRAPHY
export const MenuText = experimentalStyled("span")(({ theme }) => ({
  fontSize: "var(--text24)",
  lineHeight: "1.8288rem",
  fontWeight: "600",
  color: "var(--PrimaryBrandColour)",
  fontFamily: "var(--headerFont)",
  // fontFamily: "var(--bodyFont)",
}));

export const FormHeadText = experimentalStyled("span")(({ theme }) => ({
  fontSize: "var(--text16)",
  lineHeight: "1.8288rem",
  fontWeight: "600",
  color: "var(--PrimaryBrandColour)",
  fontFamily: "var(--headerFont)",
  // fontFamily: "var(--bodyFont)",
}));

export const FormText = experimentalStyled("span")(({ theme }) => ({
  fontSize: "var(--text16)",
  lineHeight: "1.2188rem",
  fontWeight: "400",
  fontFamily: "var(--bodyFont)",
}));

export const SupportText = experimentalStyled("p")(({ theme }) => ({
  fontSize: "var(--text16)",
  lineHeight: "1.4375rem",
  fontWeight: "400",
}));

export const SelectedNavigation = experimentalStyled("p")(({ theme }) => ({
  fontSize: "var(--text16)",
  lineHeight: "1.8125rem",
  fontWeight: "600",
}));

export const NotSelectedNavigation = experimentalStyled("p")(({ theme }) => ({
  fontSize: "var(--text14)",
  lineHeight: "1.25rem",
  fontWeight: "500",
}));

export const ChartHeader = experimentalStyled("span")(({ theme }) => ({
  fontSize: "var(--text15)",
  lineHeight: "1.25rem",
  fontWeight: "500",
  display: "block",
}));
export const BigText = experimentalStyled("span")(({ theme }) => ({
  fontSize: "var(--text40)",
  lineHeight: "2.9375rem",
  fontWeight: "600",
  color: "var(--PrimaryBlue)",
  display: "block",
}));
// const ChartText = experimentalStyled("p")(({ theme }) => ({
//   fontSize: 'var(--text12)',
//   lineHeight: '1.25rem',
//   fontWeight: '400',
// }));
interface ChartTextProps {
  mb?: string;
  mt?: string;
  dBlock?: boolean;
}
// color: currentcolor;
export const ChartText = experimentalStyled("span")<ChartTextProps>`
    font-size: var(--text12);
    line-height: 1.25rem;
    font-weight: 500;
    display: block;
    font-family: var(--bodyFont);
    
    ${({ dBlock }) => (dBlock ? `display: block` : "")};
    ${({ mb }) => (mb ? "margin-bottom:" + mb : "")};
    ${({ mt }) => (mt ? "margin-top:" + mt : "")};
    `;
export const MuiDiv = experimentalStyled("div")`
  font-size: ${(props: any) => props.scale * 8}px;
  padding: ${(props: any) => props.scale}px;
`;

export const TargetText = experimentalStyled("p")(({ theme }) => ({
  fontSize: "var(--text14)",
  lineHeight: "1.25rem",
  fontWeight: "400",
  fontFamily: "var(--bodyFont)",
}));

export const FilterText = experimentalStyled("p")(({ theme }) => ({
  fontSize: "var(--text14)",
  lineHeight: "1.0669rem",
  fontWeight: "400",
  fontFamily: "var(--bodyFont)",
}));

export const CTAText = experimentalStyled("p")(({ theme }) => ({
  fontSize: "var(--text18)",
  lineHeight: "1.8125rem",
  fontWeight: "600",
  fontFamily: "var(--bodyFont)",
}));

export const ButtonText = experimentalStyled("p")(({ theme }) => ({
  fontSize: "var(--text18)",
  lineHeight: "1.3713rem",
  fontWeight: "500",
  fontFamily: "var(--bodyFont)",
}));

export const EmailHeader = experimentalStyled("p")(({ theme }) => ({
  fontSize: "var(--text20)",
  lineHeight: "1.5237rem",
  fontWeight: "600",
  fontFamily: "var(--bodyFont)",
}));

export const EmailText = experimentalStyled("p")(({ theme }) => ({
  fontSize: "var(--text18)",
  lineHeight: "1.3713rem",
  fontWeight: "400",
  fontFamily: "var(--bodyFont)",
}));

export const SmallText = experimentalStyled("p")(({ theme }) => ({
  fontSize: "var(--text8)",
  lineHeight: "1.8125rem",
  fontWeight: "400",
  fontFamily: "var(--bodyFont)",
}));

export const MediumText = experimentalStyled("p")(({ theme }) => ({
  fontSize: "var(--text12)",
  lineHeight: "1.8125rem",
  fontWeight: "400",
  fontFamily: "var(--bodyFont)",
}));
export const CardSubTitle = experimentalStyled("p")(({ theme }) => ({
  fontSize: "var(--text12)",
  lineHeight: "1.25rem",
  fontWeight: "600",
  color: "var(--PrimaryBlue)",
  fontFamily: "var(--bodyFont)",
}));

// interface ChartTextProps {
//   marginBottom?: string;
// }

// export const ChartText = styled.p<ChartTextProps>`
//   font-size: var(--text12);
//   line-height: 1.25rem;
//   font-weight: 400;
//   ${({ marginBottom }) => (marginBottom ? "margin-bottom:" + marginBottom : "")};
// `;
