import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Store';

export interface DashboardModalInterface {
  createAdminModal: boolean;
}

const initialState = {
  createAdminModal: false,
};

export const dashboardModalSlice = createSlice({
  name: 'dashboardModals',
  initialState,
  reducers: {
    setIsCreateAdmin: (state: any, action: PayloadAction<boolean>) => {
      state.createAdminModal = action.payload;
    },
  },
});

export const getDashboardModal = (state: RootState) => state.dashboardModals;

export const { actions, reducer } = dashboardModalSlice;

export const { setIsCreateAdmin } = actions;

export default reducer;
