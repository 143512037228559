import React from 'react';
import RouterComponent from './router/RouterComponent';

function App() {
  return (
    <RouterComponent />
  );
}

export default App;
