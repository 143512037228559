import { Box, Grid, Switch, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  FormText,
  MenuText,
} from "../../../../components/commonStyled/Typography";
import InputField from "../../../../components/input-field/InputField";
import CTAButton from "../../../../components/button/CTAButton";
import { generatePayload, getInitialValues, validationSchema } from "../helper";
import {
  getRoles,
  getTeamListByAccountId,
  getUserById,
  updateUserDetails,
} from "../../../../services/SuperAdmin";
import { SuperAdminUser } from "../../../../types/permission/Permissions";
import SingleSelect from "../../../../components/single-select/SingleSelect";
import MultiSelect from "../../../../components/multi-select/MultiSelect";

interface IUsersEditDetailProps {
  updateUserList: () => void;
  closeModal: () => void;
}

interface List {
  id: number;
  name: string;
}

const UsersEditDetail = ({
  updateUserList,
  closeModal,
}: IUsersEditDetailProps) => {
  const mainFormRef = useRef<any>(null);
  const [userDetails, setUserDetails] =
    useState<Partial<SuperAdminUser | null>>(null);
  const [rolesList, setRolesList] = useState<List[]>([]);
  const [teamsList, setTeamsList] = useState<List[]>([]);
  const [teamsCount, setTeamsCount] = useState<number>(0);

  const userId = useMemo(() => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    const _id = searchParams.get("id");
    return _id;
  }, []);

  const handleUpdateUser = useCallback(
    async (values: Partial<SuperAdminUser>) => {
      const removed_teams: any = [];
      const new_teams: any = [];
      let data = values?.teams;

      if (userDetails?.user_contact) {
        for (const item of userDetails?.user_contact?.team_contacts) {
          const teamId = item.team_id;
          let matches;
          if (data?.length > 0 && data?.[0].hasOwnProperty("value")) {
            matches = data.some((team: any) => team?.value === teamId);
          }
          if (data?.length > 0 && data?.[0].hasOwnProperty("id")) {
            matches = data.some((team: any) => team?.id === teamId);
          }

          if (!matches && teamId) {
            removed_teams.push(teamId);
          }
        }
        for (const item of data) {
          const teamId = item.team_id || item.value;
          const matches = userDetails?.user_contact?.team_contacts.some(
            (team) => team.team_id === teamId
          );

          if (!matches && teamId) {
            new_teams.push(teamId);
          }
        }
      }

      if (userId) {
        try {
          const _payload = generatePayload(values);
          const payload = {
            ..._payload,
            new_teams,
            removed_teams,
          };
          const result = await updateUserDetails(payload, userId);
          if (result.data.data) {
            updateUserList();
            closeModal();
          }
        } catch (error) {
          console.log("error: ", error);
        }
      }
    },
    [userDetails?.user_contact, userId, closeModal, updateUserList]
  );

  const fetchUserById = useCallback(async (id: string) => {
    try {
      const result = await getUserById(id);
      if (result.data.data) {
        setUserDetails(result.data.data);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }, []);

  const assignValues = useCallback(
    (name: string) => (values: any) => {
      mainFormRef.current.setFieldValue(name, values.value);
    },
    []
  );

  const roleQuery = useMemo(() => {
    return {
      select: "id,name",
    };
  }, []);

  const initialValues = useMemo(() => {
    return getInitialValues(userDetails, rolesList);
  }, [userDetails, rolesList]);

  const fetchMoreSecondary = useCallback(
    async (pageNumber: number) => {
      const query = {
        page: pageNumber,
        select: "id,name",
      };
      if (userDetails?.user_accounts?.[0]?.account_id) {
        try {
          const result = await getTeamListByAccountId(
            userDetails?.user_accounts?.[0]?.account_id,
            query
          );
          if (result.data.data) {
            setTeamsList((prev) => [...prev, ...result.data?.data?.rows]);
          }
        } catch (error) {
          console.log("error: ", error);
        }
      }
    },
    [userDetails?.user_accounts]
  );

  const fetchTeams = useCallback(async () => {
    const query = {
      select: "id,name",
    };
    if (userDetails?.user_accounts?.[0]?.account_id) {
      try {
        const result = await getTeamListByAccountId(
          userDetails?.user_accounts?.[0]?.account_id,
          query
        );
        if (result.data.data) {
          setTeamsList(result.data.data.rows);
          setTeamsCount(result.data.data.count);
        }
      } catch (error) {
        console.log("error: ", error);
      }
    }
  }, [userDetails?.user_accounts]);

  const fetchRoles = useCallback(async () => {
    if (userDetails?.user_accounts?.[0]?.account_id) {
      try {
        const result = await getRoles(
          userDetails?.user_accounts?.[0]?.account_id,
          roleQuery
        );
        if (result.data.data) {
          setRolesList([...result.data.data.rows]);
        }
      } catch (error) {
        console.log("error: ", error);
      }
    }
  }, [userDetails?.user_accounts, roleQuery]);

  const handleSecondaryTeamList = useCallback(async () => {
    fetchTeams();
  }, [fetchTeams]);

  useEffect(() => {
    if (userId) fetchUserById(userId);
  }, [fetchUserById, userId]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  useEffect(() => {
    fetchTeams();
  }, [fetchTeams]);

  const assignMultipleValue = useCallback(
    (name: string) => (value: any) => {
      mainFormRef.current.setFieldValue(name, value);
    },
    []
  );

  const roleInitialValue = useMemo(() => {
    const _temp = rolesList.find(
      (option: any) => option.id === userDetails?.user_roles?.[0]?.role_id
    );
    return _temp;
  }, [rolesList, userDetails?.user_roles]);

  return (
    <Box
      sx={{
        padding: "30px 30px 15px",
        marginTop: "30px",
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdateUser}
        enableReinitialize={true}
      >
        {(formik) => {
          mainFormRef.current = formik;
          return (
            <Form>
              <Grid container spacing={{ xs: 4, sm: 6 }}>
                <Grid item xs={12} lg={8}>
                  <Grid container spacing={{ xs: 4, sm: 6 }}>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className="form-label-typography">
                          <MenuText>First Name</MenuText>
                        </Typography>
                        <InputField name="first_name" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className="form-label-typography">
                          <MenuText>Last Name</MenuText>
                        </Typography>
                        <InputField name="last_name" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className="form-label-typography">
                          <MenuText>Company</MenuText>
                        </Typography>
                        <InputField name="company_name" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className="form-label-typography">
                          <MenuText>Email Address</MenuText>
                        </Typography>
                        <InputField name="email" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className="form-label-typography">
                          <MenuText>Phone Number</MenuText>
                        </Typography>
                        <InputField name="mobile" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className="form-label-typography">
                          <MenuText>Role</MenuText>
                        </Typography>
                        <SingleSelect
                          name="roles"
                          selected={roleInitialValue}
                          onChange={assignValues("roles")}
                          options={rolesList}
                          labelKey="name"
                          valueKey="id"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className="form-label-typography">
                          <MenuText>Teams</MenuText>
                        </Typography>
                        <MultiSelect
                          selected={formik.values.teams}
                          name="teams"
                          icon="DROPDOWN"
                          placeholder="Select Team"
                          onChange={assignMultipleValue("teams")}
                          options={teamsList}
                          onFocus={handleSecondaryTeamList}
                          labelKey={"name"}
                          valueKey={"id"}
                          loadMore={fetchMoreSecondary}
                          totalCount={teamsCount}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className="form-label-typography">
                          <MenuText>Status</MenuText>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                        }}
                      >
                        <Typography className="form-label-typography">
                          <FormText>Verified</FormText>
                        </Typography>
                        <Switch
                          checked={formik.values.status}
                          disableRipple={true}
                          onChange={(e) =>
                            formik.setFieldValue("status", e.target.checked)
                          }
                          sx={{ marginLeft: 3 }}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ marginTop: { xs: 4 }, marginBottom: { xs: 2 }, textAlign: "center" }}>
                        <CTAButton
                          text="Update"
                          varient="Primary"
                          type="submit"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default UsersEditDetail;
