import { Outlet } from "react-router-dom";
import Sidebar from "../sidebar/Sidebar";
import { Box } from "@mui/material";
import Header from "../header/Header";
import { useSelector } from "react-redux";
import { userDetails } from "../../redux/slices/AuthSlice";

const DashboardLayout = () => {
  const userDetail = useSelector(userDetails);
  const userlogged = `${userDetail?.full_name}`;

  return (
    <>
      <Box
        className="site-wrapper"
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Box className="site-sidebar" sx={{ maxWidth: "250px", width: "100%" }}>
          <Sidebar />
        </Box>
        <Box
          className="site-content"
          sx={{ maxWidth: "calc(100% - 250px)", width: "100%" }}
        >
          <Header userLogged={userlogged} />
          <Box
            sx={{
              padding: { xs: '16px', md: '17px 25px', xl: "17px 35px" },
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DashboardLayout;
