// =================== import packages ==================
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../Store';

interface IFilterPayload {
  id: number;
  name: string;
  type: string;
}
export interface CommonInterface {
  loadingState: boolean;
  checkedArray: Array<number>;
  unCheckedArray: Array<number>;
  correctedId: Array<number>;
  filterValues: IFilterPayload[];
  domainError: string;
  showBuildEmailInsights: boolean;
}

const initialState: CommonInterface = {
  loadingState: false,
  checkedArray: [],
  unCheckedArray: [],
  correctedId: [],
  filterValues: [],
  domainError: '',
  showBuildEmailInsights: true,
};

const slice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setLoadingState(state: CommonInterface, action: PayloadAction<boolean>) {
      state.loadingState = action.payload;
    },
    addAsAdmin(state: CommonInterface, action: PayloadAction<number>) {
      const itemId = action.payload;
      const isChecked = state.checkedArray.includes(itemId);
      const isUnchecked = state.unCheckedArray.includes(itemId);

      if (!isChecked && !isUnchecked) {
        // The item is not in either array, so add it to checkedArray
        state.checkedArray = [...state.checkedArray, itemId];
      } else if (isUnchecked) {
        // The item is in unCheckedArray, so remove it from there
        const index = state.unCheckedArray.indexOf(itemId);
        if (index > -1) {
          state.unCheckedArray.splice(index, 1);
        }
      }
    },

    removeAsAdmin(state: CommonInterface, action: PayloadAction<number>) {
      const itemId = action.payload;
      const isChecked = state.checkedArray.includes(itemId);
      const isUnchecked = state.unCheckedArray.includes(itemId);

      if (!isChecked && !isUnchecked) {
        // The item is not in either array, so add it to unCheckedArray
        state.unCheckedArray = [...state.unCheckedArray, itemId];
      } else if (isChecked) {
        // The item is in checkedArray, so remove it from there
        const index = state.checkedArray.indexOf(itemId);
        if (index > -1) {
          state.checkedArray.splice(index, 1);
        }
      }
    },

    clearArrays(state: CommonInterface, action: PayloadAction<string>) {
      state.checkedArray = [];
      state.unCheckedArray = [];
    },
    setCorrectedId(state: CommonInterface, action: PayloadAction<number>) {
      state.correctedId = [...state.correctedId, action.payload];
    },
    clearCorrectedIds(state: CommonInterface) {
      state.correctedId = [];
    },
    setFilterValues(state: CommonInterface, action: PayloadAction<IFilterPayload[]>) {
      state.filterValues = [...action.payload];
    },
    clearFilterValues(state: CommonInterface) {
      state.filterValues = [];
    },
    setDomainError(state: CommonInterface, action: PayloadAction<string>) {
      state.domainError = action.payload;
    },
    setShowBuildEmailInsights(state: CommonInterface, action: PayloadAction<boolean>) {
      state.showBuildEmailInsights = action.payload;
    },
  },
});

export const { actions, reducer } = slice;

export const getLoadingState = (state: RootState) => state.common.loadingState;
export const getCommonArray = (state: RootState) => state.common;
export const getCorrectedId = (state: RootState) => state.common.correctedId;
export const getFilterValues = (state: RootState) => state.common.filterValues;
export const getDomainError = (state: RootState) => state.common.domainError;
export const getBuildEmailInsights = (state: RootState) => state.common.showBuildEmailInsights;

export const {
  addAsAdmin,
  removeAsAdmin,
  clearArrays,
  setCorrectedId,
  clearCorrectedIds,
  setLoadingState,
  setFilterValues,
  clearFilterValues,
  setDomainError,
  setShowBuildEmailInsights,
} = slice.actions;

export default slice;
