import { Box, Grid, Switch, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MenuText } from '../../../../components/commonStyled/Typography';
import InputField from '../../../../components/input-field/InputField';
import CTAButton from '../../../../components/button/CTAButton';
import { generatePayload, getInitialValues, validationSchema } from '../helper';
import { IPlanListForm } from '../../../../types/dashboard/tier-limit/TierCompany';
import { getPlanById, updatePlan } from '../../../../services/SuperAdmin';

interface IEditPlanLimit {
  updatePlanList: () => void;
  closeModal: () => void;
}

const EditPlanLimit = ({ updatePlanList, closeModal }: IEditPlanLimit) => {

  const [planDetail, setPlanDetail] = useState<IPlanListForm | null>(null);

  const planId = useMemo(() => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    const _id = searchParams.get('id');
    return _id;
  }, []);

  const fetchPlanById = useCallback(async (id: string) => {
    try {
      const result = await getPlanById(id);
      if (result.data.data) {
        setPlanDetail(result.data.data);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }, []);

  const handleUpdatePlan = useCallback(async (values: IPlanListForm) => {
    if (planId) {
      try {
        const payload = generatePayload(values);
        const result = await updatePlan(payload, planId);
        if (result.data.data) {
          updatePlanList();
          closeModal();
        }
      } catch (error) {
        console.log("error: ", error);
      }
    }
  }, [closeModal, updatePlanList, planId]);


  const initialValuesPlan = useMemo(() => {
    return getInitialValues(planDetail);
  }, [planDetail]);

  useEffect(() => {
    if (planId)
      fetchPlanById(planId);
  }, [fetchPlanById, planId]);

  return (
    <Box
      sx={{
        padding: "30px 30px 15px",
        marginTop: "30px"
      }}
    >
      <Formik
        initialValues={initialValuesPlan}
        validationSchema={validationSchema}
        onSubmit={handleUpdatePlan}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={{ xs: 2, sm: 6, }}>
              <Grid item xs={12} lg={8}>
                <Grid container spacing={{ xs: 2, sm: 6, }}>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography className='form-label-typography'>
                        <MenuText>Admin Limit</MenuText>
                      </Typography>
                      <InputField name="admin_limit" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography className='form-label-typography'>
                        <MenuText>Email Limit</MenuText>
                      </Typography>
                      <InputField name="email_limit" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography className='form-label-typography'>
                        <MenuText>User Limit</MenuText>
                      </Typography>
                      <InputField name="user_limit" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography className='form-label-typography'>
                        <MenuText>Team Limit</MenuText>
                      </Typography>
                      <InputField name="team_limit" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography className='form-label-typography'>
                        <MenuText>Team Member Limit</MenuText>
                      </Typography>
                      <InputField name="team_members_limit" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography className='form-label-typography'>
                        <MenuText>Product Id</MenuText>
                      </Typography>
                      <InputField name="product_id" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} >
                    <Box >
                      <Typography className='form-label-typography'>
                        <MenuText>Secondary Team Functionality</MenuText>
                        <Switch
                          checked={formik.values.secondary_team_functionality}
                          onChange={(e: any) => formik.setFieldValue('secondary_team_functionality', e.target.checked)}
                          sx={{marginLeft : 7}}
                        />
                      </Typography>
                    </Box>
                  </Grid>

                <Grid item xs={12} >
                  <Box sx={{ marginTop: { xs: 4 }, marginBottom: { xs: 2 }, textAlign: 'center' }}>
                    <CTAButton text='Update' varient='Primary' type='submit' />
                  </Box>
                </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EditPlanLimit;