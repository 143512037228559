import { IRoles } from '../types/admin/Admin';

export function getRoleIds(rolesList: IRoles[] | null, ...arg: string[]) {
  const temp = rolesList?.filter?.((item) => arg.includes(item.name))?.map((item) => item.id);

  return temp?.toString();
}

export function removeEmptyValues(obj: any) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === '' ||
      obj[propName] === 0 ||
      obj[propName] === '%%' ||
      obj[propName] === '%null%' ||
      obj[propName] === 'null' ||
      obj[propName] === '0' ||
      obj[propName] === 'undefined' ||
      obj[propName].length === 0
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

export function debounce(func: Function, delay: number) {
  let timeoutId: NodeJS.Timeout;

  return function (...args: any[]) {
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func.apply(null, args);
    }, delay);
  };
}

export const isEmptyObject = (obj: any) => {
  return Object.keys(obj).length === 0;
};

export const getFormattedDate = (date: Date) => {
  let _date = new Date(date);
  let year = _date.getFullYear();
  let month = _date.getMonth() + 1; // Months are zero-based, so we add 1

  let day = _date.getDate();

  const paddedMonth = month.toString().padStart(2, '0');
  const paddedDay = day.toString().padStart(2, '0');

  let formattedDate = paddedDay + '/' + paddedMonth + '/' + year;
  return formattedDate;
};

export const convertISTtoDDMMYYYY = (istTimestamp: any) => {
  var date = new Date(istTimestamp);

  var day = date.getDate();
  var month = date.getMonth() + 1; // Months are zero-based
  var year = date.getFullYear();

  // Format day and month with leading zeros if necessary

  const paddedMonth = month.toString().padStart(2, '0');
  const paddedDay = day.toString().padStart(2, '0');

  var ddMMYYYY = paddedDay + '/' + paddedMonth + '/' + year;
  return ddMMYYYY;
};

export function extractFirstCharacters(str: string) {
  var words = str.split(' ');
  var firstCharacters = words.map(function (word: string) {
    return word.charAt(0);
    // Alternatively, you can use word[0] to access the first character
  });
  var result = firstCharacters.join('');
  return result;
}

export function generatePdfName(str: string) {
  const currentDate = new Date();
  const hours = String(currentDate.getHours()).padStart(2, '0');
  const minutes = String(currentDate.getMinutes()).padStart(2, '0');
  const day = String(currentDate.getDate()).padStart(2, '0');
  const month = String(currentDate.getMonth() + 1).padStart(2, '0');
  const year = currentDate.getFullYear();

  return `${str}_[${hours}:${minutes}:${day}/${month}/${year}]`;
}

interface QueryParams {
  [key: string]: string | number | boolean;
}

export function setQueryParams(params: QueryParams) {
  const queryParams = new URLSearchParams(window.location.search);

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      queryParams.set(key, params[key].toString());
    }
  }

  const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
  window.history.replaceState({}, '', newUrl);
}

export function removeQueryParams() {
  const newUrl = window.location.pathname;
  window.history.replaceState({}, '', newUrl);
}
