import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import CardCount from './components/CardCount';
import { getDashboardCount } from '../../../services/SuperAdmin';
import { IDashboardCount } from '../../../types/dashboard/dashboard/Dashboard';
import { CONSTANTS } from '../../../constant/general.constant';
import { useNavigate } from 'react-router-dom';
import MembersIcon from '../../../components/vector-icons/MembersIcon';
import MailSentIcon from '../../../components/vector-icons/MailSentIcon';
import CompanyIcon from '../../../components/vector-icons/CompanyIcon';

const Dashboard = () => {

  const [counts, setCounts] = useState<IDashboardCount | null>(null);
  const navigate = useNavigate();

  const fetchCounts = useCallback(async () => {
    try {
      const result = await getDashboardCount();
      if (result.data.data) {
        setCounts(result.data.data);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }, []);

  useEffect(() => {
    fetchCounts();
  }, [fetchCounts]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <CardCount title={CONSTANTS.TOTAL_COMPANIES} icon={<CompanyIcon />} count={counts?.accountCount} handleClick={() => navigate('/admin/companies')} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <CardCount title={CONSTANTS.TOTAL_EMAIL} icon={<MailSentIcon />}  count={counts?.sentEmailCount} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <CardCount title={CONSTANTS.TOTAL_TEAM_MEMBERS} icon={<MembersIcon />} count={counts?.contactCount} handleClick={() => navigate('/admin/team-member')} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
          <CardCount title={CONSTANTS.TOTAL_USERS} icon={<MembersIcon />} count={counts?.userCount} handleClick={() => navigate('/admin/users')} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;