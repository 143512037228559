import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { LogoFull } from "../icons";
import "./sidebar.css";
import { SmallText } from "../commonStyled/Typography";
import { useLocation, useNavigate } from "react-router-dom";

const drawerWidth = 250;
const AdminSidebar = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const redirect = (url: string) => () => {
    navigate(url);
  };

  const getActiveClass = (url: string) => {
    return location.pathname.startsWith(url) ? "sidebarActive" : "";
  };

  return (
    <>
      <Drawer
        sx={{
          maxWidth: drawerWidth,
          width: "100%",
          "& .MuiDrawer-paper": {
            paddingBottom: "1.375rem",
            maxWidth: drawerWidth,
            width: "100%",
            boxSizing: "border-box",
            borderRightColor: "var(--PrimaryBrandColour)",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box
          className=""
          sx={{
            width: "100%",
            paddingTop: "1.875rem",
            paddingLeft: "2rem",
            marginBottom: "5.25rem",
          }}
        >
          <div className="logo-wrap">
            <LogoFull className="w-full h-auto" />
          </div>
        </Box>

        <List>
          <ListItem
            key={"Home"}
            disablePadding
            sx={{
              "&:not(:last-child)": {
                marginBottom: "5px",
              },
            }}
            onClick={redirect("admin/dashboard")}
          >
            <ListItemButton
              className={`custom ${getActiveClass("/admin/dashboard")}`}
              sx={{
                all: "initial",
                width: "100%",
                fontWeight: "500",
                cursor: "pointer",
                padding: "13px 16px 13px 32px",
                "&::before": {
                  backgroundColor: "var(--PrimaryBrandColour)",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  content: '""',
                  opacity: "0",
                },
                "&.sidebarActive": {
                  "&::before": {
                    opacity: '0.05'
                  },
                  backgroundColor: "var(--OffWhite)",
                  color: "var(--PrimaryBrandColour)",
                  fontWeight: "600",
                  "&:hover": {
                    "&::before": {
                      opacity: '0.1'
                    },
                  },
                },
              }}
            >
              <ListItemText
                primary={"Home"}
                sx={{ margin: "0", lineHeight: "20px" }}
                className="NotSelectedNavigation"
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={"companies"}
            disablePadding
            sx={{
              "&:not(:last-child)": {
                marginBottom: "5px",
              },
            }}
            onClick={() => navigate("admin/companies")}
          >
            <ListItemButton
              className={`custom ${getActiveClass("/admin/companies")}`}
              sx={{
                all: "initial",
                width: "100%",
                fontWeight: "500",
                cursor: "pointer",
                padding: "13px 16px 13px 32px",
                "&::before": {
                  backgroundColor: "var(--PrimaryBrandColour)",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  content: '""',
                  opacity: "0",
                },
                "&.sidebarActive": {
                  "&::before": {
                    opacity: '0.05'
                  },
                  backgroundColor: "var(--OffWhite)",
                  color: "var(--PrimaryBrandColour)",
                  fontWeight: "600",
                  "&:hover": {
                    "&::before": {
                      opacity: '0.1'
                    },
                  },
                },
              }}
            >
              <ListItemText
                primary={"Companies"}
                sx={{ margin: "0", lineHeight: "20px" }}
                className="NotSelectedNavigation"
              />
            </ListItemButton>
            {/* </Link> */}
          </ListItem>
          <ListItem
            key={"Users"}
            disablePadding
            sx={{
              "&:not(:last-child)": {
                marginBottom: "5px",
              },
            }}
            onClick={redirect("admin/users")}
          >
            <ListItemButton
              className={`custom ${getActiveClass("/admin/users")}`}
              sx={{
                all: "initial",
                width: "100%",
                fontWeight: "500",
                cursor: "pointer",
                padding: "13px 16px 13px 32px",
                "&::before": {
                  backgroundColor: "var(--PrimaryBrandColour)",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  content: '""',
                  opacity: "0",
                },
                "&.sidebarActive": {
                  "&::before": {
                    opacity: '0.05'
                  },
                  backgroundColor: "var(--OffWhite)",
                  color: "var(--PrimaryBrandColour)",
                  fontWeight: "600",
                  "&:hover": {
                    "&::before": {
                      opacity: '0.1'
                    },
                  },
                },
              }}
            >
              <ListItemText
                primary={"Users"}
                sx={{ margin: "0", lineHeight: "20px" }}
                className="NotSelectedNavigation"
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={"Team Member"}
            disablePadding
            sx={{
              "&:not(:last-child)": {
                marginBottom: "5px",
              },
            }}
            onClick={redirect("admin/team-member")}
          >
            <ListItemButton
              className={`custom ${getActiveClass("/admin/team-member")}`}
              sx={{
                all: "initial",
                width: "100%",
                fontWeight: "500",
                cursor: "pointer",
                padding: "13px 16px 13px 32px",
                "&::before": {
                  backgroundColor: "var(--PrimaryBrandColour)",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  content: '""',
                  opacity: "0",
                },
                "&.sidebarActive": {
                  "&::before": {
                    opacity: '0.05'
                  },
                  backgroundColor: "var(--OffWhite)",
                  color: "var(--PrimaryBrandColour)",
                  fontWeight: "600",
                  "&:hover": {
                    "&::before": {
                      opacity: '0.1'
                    },
                  },
                },
              }}
            >
              <ListItemText
                primary={"Team Member"}
                sx={{ margin: "0", lineHeight: "20px" }}
                className="NotSelectedNavigation"
              />
            </ListItemButton>
          </ListItem>


          <ListItem
            key={"Tier Limit"}
            disablePadding
            sx={{
              "&:not(:last-child)": {
                marginBottom: "5px",
              },
            }}
            onClick={redirect("admin/tier-limit")}
          >
            <ListItemButton
              className={`custom ${getActiveClass("/admin/tier-limit")}`}
              sx={{
                all: "initial",
                width: "100%",
                fontWeight: "500",
                cursor: "pointer",
                padding: "13px 16px 13px 32px",
                "&::before": {
                  backgroundColor: "var(--PrimaryBrandColour)",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  content: '""',
                  opacity: "0",
                },
                "&.sidebarActive": {
                  "&::before": {
                    opacity: '0.05'
                  },
                  backgroundColor: "var(--OffWhite)",
                  color: "var(--PrimaryBrandColour)",
                  fontWeight: "600",
                  "&:hover": {
                    "&::before": {
                      opacity: '0.1'
                    },
                  },
                },
              }}
            >
              <ListItemText
                primary={"Tier Limit"}
                sx={{ margin: "0", lineHeight: "20px" }}
                className="NotSelectedNavigation"
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={"Admins"}
            disablePadding
            sx={{
              "&:not(:last-child)": {
                marginBottom: "5px",
              },
            }}
            onClick={redirect("admin/miova-admin")}
          >
            <ListItemButton
              className={`custom ${getActiveClass("/admin/miova-admin")}`}
              sx={{
                all: "initial",
                width: "100%",
                fontWeight: "500",
                cursor: "pointer",
                padding: "13px 16px 13px 32px",
                "&::before": {
                  backgroundColor: "var(--PrimaryBrandColour)",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  content: '""',
                  opacity: "0",
                },
                "&.sidebarActive": {
                  "&::before": {
                    opacity: '0.05'
                  },
                  backgroundColor: "var(--OffWhite)",
                  color: "var(--PrimaryBrandColour)",
                  fontWeight: "600",
                  "&:hover": {
                    "&::before": {
                      opacity: '0.1'
                    },
                  },
                },
              }}
            >
              <ListItemText
                primary={"Admin"}
                sx={{ margin: "0", lineHeight: "20px" }}
                className="NotSelectedNavigation"
              />
            </ListItemButton>
          </ListItem>
        </List>
        <Box
          className="sidebar-bottom-box"
          sx={{ padding: "0 1.0625rem", marginTop: "auto" }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              backgroundColor: "var(--OffWhite)",
              padding: "0.625rem 1.25rem",
              borderRadius: "0.9375rem",
            }}
          >
            <div className="logo-wrap">
              <LogoFull className="w-full h-auto" />
            </div>
            <SmallText>Copywrite &copy; 2022, all rights reserved</SmallText>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default AdminSidebar;
