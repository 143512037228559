import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { FormHeadText } from "../../components/commonStyled/Typography";
import { CardComponent } from "../../components/card/CardComponent";
import InputField from "../../components/input-field/InputField";
import { Form, Formik } from "formik";
import CTAButton from "../../components/button/CTAButton";
import { setLogoutData } from "../../redux/slices/AuthSlice";
import { generateResetPasswordPayload, resetPasswordInitialValues, resetPasswordValidationSchema } from "./form-helper/Index";
import { useCallback } from "react";
import { changePassword, } from "../../services/UserAuthentication";
import { useDispatch } from "react-redux";
import { flushThemeData } from "../../redux/slices/SettingSlice";
import { flushPermissions } from "../../redux/slices/permissionSlice";
import { IChangePassword } from "../../types/user/User";


const ChangePassword = () => {

    const dispatch = useDispatch()

    const handleResetPassword = useCallback(async (values: IChangePassword) => {
        try {
            const payload = generateResetPasswordPayload(values)
            const result = await changePassword(payload);
            if (result.data) {
                dispatch(setLogoutData())
                dispatch(flushThemeData())
                dispatch(flushPermissions())
            }
        } catch (error) {
            console.log("error: ", error);
        }
    }, [dispatch])

    return (
        <CardComponent title={'Change Password'} >
            <Card
                sx={{
                    width: "100%",
                    maxWidth: '500px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    boxShadow: "none",
                    borderRadius: "20px",
                    backgroundColor: "var(--ThemeWhite)",
                    padding: "50px 50px",
                }}
            >
                <CardContent
                    sx={{
                        padding: "0 !important",
                        marginTop: "0 50px",
                    }}
                >

                    <Formik
                        initialValues={resetPasswordInitialValues}
                        validationSchema={resetPasswordValidationSchema}
                        onSubmit={handleResetPassword}
                        enableReinitialize
                    >
                        {(formik) => {
                            // mainFormRef.current = formik
                            return (
                                <Form >
                                    <Grid spacing={{ xs: '24px', md: '48px', lg: '65px', xl: '96px' }} marginTop={'0 !important'}>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                paddingTop: "0 !important",
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        all: "initial",
                                                        // marginBottom: "12px",
                                                        display: "block",
                                                        fontFamily: "var(--Montserrat)",
                                                    }}
                                                >
                                                    <FormHeadText >Old Password</FormHeadText>
                                                </Typography>
                                                <InputField
                                                    size="small"
                                                    name="old_password"
                                                    placeholder="Old Password"
                                                    margin="normal"
                                                    type="password"
                                                // if(mainFormRef.current.e)

                                                />
                                            </Box>
                                        </Grid>

                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                paddingTop: {
                                                    sm: "20px !important",
                                                    md: "28px !important",
                                                    lg: "34px !important",
                                                },
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        all: "initial",
                                                        // marginBottom: "12px",
                                                        display: "block",
                                                        fontFamily: "var(--Montserrat)",
                                                    }}
                                                >
                                                    <FormHeadText>New Password</FormHeadText>
                                                </Typography>
                                                <InputField
                                                    size="small"
                                                    name="new_password"
                                                    placeholder="New Password"
                                                    margin="normal"
                                                    type="password"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                paddingTop: {
                                                    sm: "20px !important",
                                                    md: "28px !important",
                                                    lg: "34px !important",
                                                },
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        all: "initial",
                                                        display: "block",
                                                        fontFamily: "var(--Montserrat)",
                                                    }}
                                                >
                                                    <FormHeadText>Confirm Password</FormHeadText>
                                                </Typography>
                                                <InputField
                                                    size="small"
                                                    name="confirm_password"
                                                    placeholder="Confirm Password"
                                                    margin="normal"
                                                    type="password"
                                                />
                                            </Box>
                                        </Grid>

                                    </Grid>
                                    <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', marginTop: "60px", gap: 15, marginBottom: "30px" }}>
                                        <CTAButton type="submit" varient="Primary" text="Change Password" />
                                    </Box>
                                </Form>
                            )
                        }}

                    </Formik>
                </CardContent>
            </Card>

        </CardComponent>
    );
};

export default ChangePassword;
