import { ReactNode } from "react";
import { ErrorMessage, useField } from "formik";
import { Box, TextField, Typography } from "@mui/material";

interface IInputField {
  type?: string;
  name: string;
  label?: string;
  onChange?: (val: string) => void
  placeholder?: string;
  disabled?: boolean;
  margin?: "dense" | "normal" | "none";
  extraElement?: ReactNode;
  size?: "small" | "medium";
  isValid?: boolean
}

const InputField = ({
  type,
  name,
  label,
  placeholder,
  disabled,
  margin,
  size,
  isValid = false,
  onChange
}: IInputField) => {

  const [field] = useField(name);

  return (
    <>
      <Box
        sx={{
          ":not(lastchild)": {
          },
        }}
      >
        <TextField
          placeholder={placeholder}
          type={type ? type : "text"}
          {...field}
          label={label}
          name={name}
          disabled={disabled ? disabled : false}
          fullWidth
          margin={margin}
          size={size}
          sx={{
            marginBottom: '0 !important',
            borderRadius: '10px',
            '& .MuiInputBase-input': {
              height: 'unset',
              paddingTop: '12px',
              paddingBottom: '12px',
              transition: 'all 0.3s',
              borderRadius: '10px !important',
            },
            '&:hover': {
              ".MuiOutlinedInput-notchedOutline": {
                borderColor: 'var(--PrimaryBlue) !important',
              }
            },
            '& .Mui-focused': {
              'fieldset': {
                borderColor: isValid ? 'red' : 'var(--PrimaryBlue) !important',
                borderRadius: '10px',
                // background: 'red'
              },
            },
            '& fieldset': {
              borderRadius: '10px',
              borderColor: isValid ? 'red' : 'var(--Gray) !important',
              // borderColor: isValid ? 'red' : '',
            }
          }}
        />
        <ErrorMessage name={name}>
          {(msg) => (
            <Typography
              style={{
                color: "red",
                textAlign: "left",
                fontSize: "var(--text14)",
                fontWeight: "500",
                fontFamily: "var(--bodyFont)",
                marginTop: "2px",
              }}
            >
              {msg}
            </Typography>
          )}
        </ErrorMessage>
      </Box>
    </>
  );
};

export default InputField;
