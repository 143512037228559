import { Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import Header from "../header/Header";
import AdminSidebar from "../sidebar/AdminSidebar";
import { useSelector } from "react-redux";
import { getAuthData } from "../../redux/slices/SuperAdminSlice";

const AdminDashboardLayout = () => {

  const superAdmin = useSelector(getAuthData);
  const userlogged = superAdmin?.superAdminDetails?.first_name || '';

  return (
    <>
      <Box
        className="site-wrapper"
        sx={{
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Box className="site-sidebar" sx={{ maxWidth: "250px", width: "100%" }}>
          <AdminSidebar />
        </Box>
        <Box
          className="site-content"
          sx={{ maxWidth: "calc(100% - 250px)", width: "100%" }}
        >
          <Header userLogged={userlogged} />
          <Box
            sx={{
              padding: { xs: '16px', md: '17px 25px', xl: "17px 35px" },
            }}
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AdminDashboardLayout;
