import React, { useCallback, useEffect, useState } from 'react';
import { CardComponent } from '../../../components/card/CardComponent';
import ModalView from '../../../components/modal/Modal';
import { getPlanList } from '../../../services/SuperAdmin';
import PricingCard from './components/PricingCards';
import { Grid } from '@mui/material';
import { IPlanList } from '../../../types/dashboard/tier-limit/TierCompany';
import EditPlanLimit from './components/EditPlanLimit';
import { removeQueryParams, setQueryParams } from '../../../utils/Index';

const TierLimit = () => {

  const [usersModalOpen, setUsersModalOpen] = useState<boolean>(false);
  const [planList, setPlanList] = useState<IPlanList[]>([]);

  const handleUsersModalClose = () => {
    removeQueryParams();
    setUsersModalOpen(false);
  };

  const handleUpdatePlan = useCallback((id: number) => {
    setQueryParams({ id });
    setUsersModalOpen(true);
  }, []);

  const fetchPlanList = useCallback(async () => {
    try {
      const result = await getPlanList();
      if (result.data.data) {
        setPlanList(result.data.data.rows);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }, []);

  useEffect(() => {
    fetchPlanList();
  }, [fetchPlanList]);

  return (
    <>
      <CardComponent
        title="Tier Plans"
      >
        <>
          <Grid container spacing={2}>
            {planList?.map?.((item: IPlanList) =>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <PricingCard
                  planDetails={item}
                  handleUpdate={handleUpdatePlan}
                />
              </Grid>
            )}
          </Grid>
        </>

      </CardComponent>
      <ModalView open={usersModalOpen} closeModal={handleUsersModalClose} >
        <EditPlanLimit updatePlanList={fetchPlanList} closeModal={handleUsersModalClose} />
      </ModalView>
    </>
  );
};

export default TierLimit;
