import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router";
import { Anchor, CardTitle,  ChartText, MenuText, } from "../../../components/commonStyled/Typography";
import CTAButton from "../../../components/button/CTAButton";
import { LogoFull } from "../../../components/icons";
import { useSearchParams } from "react-router-dom";
import { verifyEmail } from "../../../services/UserAuthentication";
import { useSelector } from "react-redux";
import { userDetails } from "../../../redux/slices/AuthSlice";
import { Links } from "../../../constant/general.constant";

const VerifyEmail = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams()
    const userDetail = useSelector(userDetails);

    const [isLoadingVerifyEmail, setIsLoadingVerifyEmail] = useState<boolean>(false)
   
    const userLogged = `${userDetail?.full_name}`;

    const handleVerify = useCallback(
        async () => {
            try {
                const token = searchParams.get('token');
                const payload = {
                    token: token?.trim(),
                }
                setIsLoadingVerifyEmail(true)
                const result = await verifyEmail(payload)
                if (result.data?.data?.is_sender_verified) {
                    setTimeout(() => {
                        setIsLoadingVerifyEmail(false)
                        navigate('/');
                    }, 500);
                }
            } catch (error) {
                setIsLoadingVerifyEmail(false)
                console.log("err", error)
            }
        },
        [navigate, searchParams]
    );

    return (
        <Box
            sx={{}}
            bgcolor={"#FAFBFC"}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={'column'}
            minHeight={"100dvh"}
            flexWrap={'wrap'}
        >
            <Box
                sx={{ maxWidth: { sm: "90vw", md: "90vw", lg: "824px" } }}
                p={5}
                width={"100%"}
                bgcolor="white"
                borderRadius={"20px"}
                textAlign="center"
                position={"relative"}
            >
                <Typography position={"absolute"} right={"20px"} top={"12px"}>
                    <ChartText>
                        <Anchor href={Links.MAIN_DOMAIN}>return to website</Anchor>
                    </ChartText>
                </Typography>

                <Typography
                    sx={{
                        all: "initial",
                        display: "block",
                        textAlign: "center",
                        fontFamily: "var(--bodyFont)",
                        marginBottom: { sm: "60px", md: "90px", lg: "118px" },
                    }}
                >
                    <CardTitle sx={{ maxWidth: '340px', margin: '0 auto', lineHeight: '34px' }}>Verify your email</CardTitle>
                </Typography>
                <Box maxWidth={545} marginLeft={"auto"} marginRight={"auto"} textAlign={'center'}>
                    <MenuText sx={{ display: 'block', color: '#000000' }}>
                        Hi {userLogged},
                    </MenuText>
                    <MenuText sx={{ display: 'block', color: '#000000', marginTop: '20px' }}>
                        Please verify your email by clicking on below button.
                    </MenuText>
                </Box>
                <Typography sx={{ marginTop: { sm: '40px', md: '60px', lg: '80px', xl: '100px' } }}>
                    <CTAButton type="button" onClick={handleVerify} isLoading={isLoadingVerifyEmail} varient="Primary" sx={{ color: '#0D0C32' }} text="Verify" />
                </Typography>

            </Box>

            <Box width={'100%'} sx={{ marginTop: { sm: '30px', md: '60px', lg: '90px', xl: '120px' } }}>
                <Box color={'var(--PrimaryBrandColour)'} width={152} height={42} margin={'0 auto'}>
                    <LogoFull className="w-full h-full" />
                </Box>
            </Box>
        </Box>
    );
};

export default VerifyEmail;