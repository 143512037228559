import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPermissions } from '../../types/permission/Permissions';

export interface PermissionsInterface {
  permission: IPermissions;
  secondaryTeamEnable: boolean;
}

const initialState = {
  permission: {},
  secondaryTeamEnable: false,
};

export const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    setPermissions: (state: any, action: PayloadAction<string>) => {
      state.permission = action.payload;
    },
    flushPermissions: (state: any) => {
      state.permission = {};
    },
    setSecondaryTeamEnable: (state: any, action: PayloadAction<boolean>) => {
      state.secondaryTeamEnable = action.payload;
    },
  },
});

export const getPermissions = (state: { permission: PermissionsInterface }) => state.permission;

export const getUserRoles = (state: { permission: PermissionsInterface }) => state.permission.permission.user;

export const getPlanLimitDetails = (state: { permission: PermissionsInterface }) => state.permission.permission.planUsageValidationMap;

export const getSecondaryTeamEnable = (state: { permission: PermissionsInterface }) =>
  state.permission.secondaryTeamEnable;

export const { actions, reducer } = permissionSlice;

export const { setPermissions, flushPermissions, setSecondaryTeamEnable } = actions;

export default reducer;
