import { Box, Grid, Switch, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { MenuText } from '../../../../components/commonStyled/Typography';
import InputField from '../../../../components/input-field/InputField';
import { generatePayloadLimit, getPlanLimitInitialValues, validationSchemaLimit } from '../helper';
import CTAButton from '../../../../components/button/CTAButton';
import { getCompanyById, getPlans, getPlansById, updateCompanyPlanLimit } from '../../../../services/SuperAdmin';
import SingleSelect from '../../../../components/single-select/SingleSelect';
import { ICompanyPlanDetails } from '../../../../types/admin/Admin';

interface ICompanyLimitDetail {
  updateCompanyList: () => void;
  closeModal: () => void;
}

interface PlanList {
  id: number;
  name: string;
}

const CompanyLimitDetail = ({ updateCompanyList, closeModal }: ICompanyLimitDetail) => {

  const mainFormRef = useRef<any>(null);
  const [companyLimitDetails, setCompanyLimitDetails] = useState<ICompanyPlanDetails | null>(null);
  const [planList, setPlanList] = useState<PlanList[]>([]);

  const companyId = useMemo(() => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    const _id = searchParams.get('id');
    return _id;
  }, []);

  const handleUpdateCompanyLimit = useCallback(async (values: Partial<ICompanyPlanDetails>) => {
    if (companyId) {
      try {
        const payload = generatePayloadLimit(values);
        const result = await updateCompanyPlanLimit(payload, companyId);
        if (result.data.data) {
          updateCompanyList();
          closeModal();
        }
      } catch (error) {
        console.log("error: ", error);
      }
    }
  }, [closeModal, companyId, updateCompanyList]);

  const query = useMemo(() => {
    return {
      "include[plan_usage_details][select]": "plan_detail_id,name,admin_limit,user_limit,team_limit,team_members_limit,pending_email_count,secondary_team_functionality,email_limit",
      "include[plan_usage_details][include][account][select]": "billing_id"
    };
  }, []);

  const planQuery = useMemo(() => {
    return {
      "select": "id,name",
    };
  }, []);

  const fetchCompanyById = useCallback(async (id: string) => {
    try {
      const result = await getCompanyById(id, query);
      if (result.data.data) {
        setCompanyLimitDetails({ ...result.data?.data?.plan_usage_details, billing_id: result.data?.data?.billing_id});
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }, [query,]);

  const fetchPlans = useCallback(async () => {
    try {
      const result = await getPlans(planQuery);
      if (result.data.data) {
        setPlanList([
          ...result.data.data.rows,
        ]);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }, [planQuery]);

  const handleLimitChange = useCallback((name: string) => async (values: any) => {
    mainFormRef.current.setFieldValue(name, values.value);
    if (values && values.value !== null) {
      try {
        const result = await getPlansById(values.value);
        const data = result?.data?.data;
        if (data) {
          mainFormRef.current.setFieldValue('admin_limit', data?.admin_limit || '');
          mainFormRef.current.setFieldValue('user_limit', data?.user_limit || '');
          mainFormRef.current.setFieldValue('team_limit', data?.team_limit || '');
          mainFormRef.current.setFieldValue('team_members_limit', data?.team_members_limit || '');
          mainFormRef.current.setFieldValue('email_limit', data?.email_limit || '');
          mainFormRef.current.setFieldValue('secondary_team_functionality', data?.secondary_team_functionality || true);
        }
      } catch (error) {
        console.log("error: ", error);
      }
    }
  }, []);

  const initialValues = useMemo(() => {
    return getPlanLimitInitialValues(companyLimitDetails);
  }, [companyLimitDetails]);

  useEffect(() => {
    if (companyId)
      fetchCompanyById(companyId);
  }, [fetchCompanyById, companyId]);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  const planInitialValue = useMemo(() => {
    const _temp = planList.find(((option: any) =>
      option.id === companyLimitDetails?.plan_detail_id));
    return _temp;
  }, [planList, companyLimitDetails?.plan_detail_id]);

  return (
    <Box
      sx={{
        padding: "30px 30px 15px",
        marginTop: "30px"
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchemaLimit}
        onSubmit={handleUpdateCompanyLimit}
        enableReinitialize={true}
      >
        {(formik) => {
          mainFormRef.current = formik;
          return (
            <Form>
              <Grid container spacing={{ xs: 4, sm: 6, }}>
                <Grid item xs={12} lg={8}>
                  <Grid container spacing={{ xs: 4, sm: 6, }}>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className='form-label-typography'>
                          <MenuText>Account Plan</MenuText>
                        </Typography>
                        <SingleSelect
                          name="account_plan"
                          selected={planInitialValue}
                          onChange={handleLimitChange("account_plan")}
                          options={planList}
                          labelKey="name"
                          valueKey="id"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className='form-label-typography'>
                          <MenuText>Admin Limit</MenuText>
                        </Typography>
                        <InputField name="admin_limit" type="number" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className='form-label-typography'>
                          <MenuText>User Limit</MenuText>
                        </Typography>
                        <InputField name="user_limit" type="number" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className='form-label-typography'>
                          <MenuText>Team Limit</MenuText>
                        </Typography>
                        <InputField name="team_limit" type="number" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className='form-label-typography'>
                          <MenuText>Monthly Send Limit</MenuText>
                        </Typography>
                        <InputField name="email_limit" type="number" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className='form-label-typography'>
                          <MenuText>Team Member Limit</MenuText>
                        </Typography>
                        <InputField name="team_members_limit" type="number" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography className='form-label-typography'>
                        <MenuText>Billing Id</MenuText>
                      </Typography>
                      <InputField disabled={true} name="billing_id" />
                    </Box>
                  </Grid>
                    <Grid item xs={12} sm={6}>
                      {/* <Box>
                        <Typography className='form-label-typography'>
                          <MenuText></MenuText>
                        </Typography>
                      </Box> */}
                      <Box >
                        <Typography className='form-label-typography'>
                          <MenuText>Enable / Disable Secondary Teams Functionality</MenuText>
                          <Switch
                            checked={formik.values.secondary_team_functionality}
                            onChange={(e: any) => formik.setFieldValue('secondary_team_functionality', e.target.checked)}
                            sx={{ marginLeft: 7 }}
                          />
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} >
                      <Box sx={{ marginTop: { xs: 4 }, marginBottom: { xs: 2 },textAlign: 'center' }}>
                        <CTAButton text='Update' varient='Primary' type='submit' />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default CompanyLimitDetail;