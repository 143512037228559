import { axiosDelete, axiosGet, axiosPost, axiosPut } from "../axios/config";

// Id is static for now will change it.Just checking the call
export const getAdminAccountList = (params: any) => {
  return axiosGet(`user`, params);
};

export const changeRoles = (data: any) => {
  return axiosPost(`account/add-admins`, data);
};

export const changeUserRole = (data: any) => {
  return axiosPost(`account/set-contact-as-user`, data);
};

export const deleteContact = (data: any) => {
  console.log('data: ', data);
  return axiosDelete(`contact/`, data);
};

export const updateAccount = (data: any, id: number) => {
  return axiosPut(`account/${id}`, data);
};

export const createAdmin = (data: any) => {
  return axiosPost(`user`, data);
};

export const getRoles = () => {
  return axiosGet(`roles`);
};

export const getPrimaryTeams = (params: any = {}) => {
  return axiosGet(`team`, params);
};
