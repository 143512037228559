import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISuperAdmin } from '../../types/super-admin/SuperAdmin';
import { RootState } from '../Store';

export interface SuperAuthInterface {
  token: string | null;
  superAdminDetails: ISuperAdmin;
  isSuperAdmin: boolean;
}

const initialState = {
  token: null,
  superAdminDetails: {},
  isSuperAdmin: false,
};

export const superAdminSlice = createSlice({
  name: 'superAdmin',
  initialState,
  reducers: {
    setSuperAdminAccessToken: (state: any, action: PayloadAction<string>) => {
      state.token = action.payload;
      state.isSuperAdmin = state.token ? true : false;
    },

    setSuperAdminDetails: (state: any, action: PayloadAction<object>) => {
      state.superAdminDetails = action.payload;
    },

    setSuperAdminLogoutData(state: any) {
      state.token = null;
      state.superAdminDetails = null;
      state.isSuperAdmin = false;
    },
  },
});

export const getAuthData = (state: any) => state.superAdmin;

export const getSuperAdminToken = (state: RootState) => state.superAdmin.token;

export const getIsSuperAdmin = (state: RootState) => state.superAdmin.isSuperAdmin;

export const getSuperAdminDetails = (state: RootState) => state.superAdmin.superAdminDetails;

export const { actions, reducer } = superAdminSlice;

export const { setSuperAdminAccessToken, setSuperAdminDetails, setSuperAdminLogoutData } = actions;

export default reducer;
