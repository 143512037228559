import { useCallback, useState } from "react";
import { Box, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import ModalView from "../../../../components/modal/Modal";
import { Anchor, CardTitleSmall, MediumText } from "../../../../components/commonStyled/Typography";
import CTAButton from "../../../../components/button/CTAButton";
import { IDashboardAdminList } from "../../../../types/dashboard/admin/Admin";
import PaginationComponent from "../../../../components/pagination/PaginationComponent";


interface IMiovaAdminTable {
  data: IDashboardAdminList[];
  modelType?: string | null,
  count?: number;
  currentPage?: number,
  handleReset?: (email: string) => void;
  handleAdminDelete?: (id: number) => void;
  handlePageChange?: (value: number) => void;
}

const MiovaAdminTable = ({ data, handleReset, handleAdminDelete, count, handlePageChange, currentPage }: IMiovaAdminTable) => {

  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [selectedId, setSelectedId] = useState<number | null>(null);

  const teamLength = data?.length;

  const handleResetClick = (email: string) => () => {
    if (handleReset)
      handleReset(email);
  };

  const handlePage = (page: number) => {
    if (handlePageChange)
      handlePageChange(page);
  };

  const handleConfirmationModal = (id: number) =>
    (e: React.MouseEvent<HTMLAnchorElement> | undefined): void => {
      setSelectedId(id);
      setOpenConfirmation(true);
    };

  const handleClick = () => {
    if (handleAdminDelete && selectedId) {
      handleAdminDelete(selectedId);
      setOpenConfirmation(false);
      setSelectedId(null);
    }

  };

  const handleCancel = () => {
    setOpenConfirmation(false);
  };

  const handleCloseConfirmationModal = useCallback(() => {
    setOpenConfirmation(false);
  }, []);

  return (<>
    <Card
      sx={{
        width: "100%",
        boxShadow: "none",
        borderRadius: "20px",
        backgroundColor: "var(--White)",
        padding: "19px 23px",
      }}
    >
      <CardContent
        sx={{
          padding: "0 !important",
          marginTop: "0",
        }}
      >
        <TableContainer sx={{ paddingBottom: '10px' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead
              sx={{
                th: {
                  borderBottom: "1px solid var(--PurpleExtraLight)",
                  fontFamily: "var(--headerFont)",
                  fontSize: " 1rem",
                  lineHeight: "1.25rem",
                  fontWeight: "600",
                  color: 'var(--PrimaryBlue)',
                  padding: "16px 0",
                  "&:not(:first-of-type)": {
                    paddingLeft: "10px",
                  },
                },
              }}
            >
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Reset Password</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map?.((row, idx) => {
                return (
                  <TableRow
                    key={idx}
                    sx={{
                      td: {
                        fontFamily: "var(--bodyFont)",
                        fontSize: " 0.75rem",
                        lineHeight: "1.25rem",
                        borderBottom: "1px solid var(--PurpleExtraLight)",
                        padding: "16px 0",
                        "&:not(:first-of-type)": {
                          paddingLeft: "10px",
                        },
                      },
                    }}
                  >
                    <TableCell>{`${row.first_name} ${row.last_name}` || '-'}</TableCell>
                    <TableCell>{row.email || '-'}</TableCell>
                    <TableCell>
                      <Anchor sx={{ color: 'var(--PrimaryBlue)' }} onClick={handleResetClick(row.email)}>
                        <MediumText>Reset</MediumText>
                      </Anchor>
                    </TableCell>
                    <TableCell>
                      {row.is_master ? <MediumText>-</MediumText>
                        :
                        <Anchor sx={{ color: 'var(--PrimaryBlue)' }} onClick={handleConfirmationModal(row.id)}>
                          <MediumText>Delete</MediumText>
                        </Anchor>
                      }
                    </TableCell>
                  </TableRow>
                );
              })}
              {teamLength === 0 && (
                <TableRow
                  sx={{
                    td: {
                      addAsAdmin: "all 0.3s",
                      borderBottom: "1px solid var(--PurpleExtraLight)",
                      padding: "16px 0",
                      "&:not(:first-of-type)": {
                        paddingLeft: "10px",
                      },
                    },
                    position: "relative",
                    zIndex: "9",
                  }}
                >
                  <TableCell colSpan={6} align="center">
                    No data Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            marginTop: "20px",
            fontFamily: "var(--bodyFont)",
            textAlign: "center",
            td: {
              fontSize: " 0.75rem",
              lineHeight: "1.25rem",
              transition: "all 0.3s",
              borderBottom: "1px solid var(--PurpleExtraLight)",
              padding: "16px 0",
              "&:not(:first-of-type)": {
                paddingLeft: "10px",
              },
            },
            position: "relative",
            zIndex: "9",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'

          }}
        >
          {(teamLength && teamLength > 0) ?
            <PaginationComponent
              count={count || 10}
              limit={10}
              currentPage={currentPage || 1}
              onChange={handlePage}
            />
            : null
          }
        </Box>
      </CardContent>
    </Card >
    <ModalView Height="350px" MaxWidth="550px" open={openConfirmation} closeModal={handleCloseConfirmationModal}>
      <>
        <Box sx={{ margin: '50px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'column', height: '250px' }}>
          <CardTitleSmall sx={{ marginTop: '80px' }}>
            Are you sure you want to delete?
          </CardTitleSmall>
          <Box sx={{ display: "flex", justifyContent: 'space-between', gap: 5 }}>
            <CTAButton text="Confirm" varient="Primary" onClick={handleClick} />
            <CTAButton text="Cancel" varient="Secondary" onClick={handleCancel} />
          </Box>
        </Box>
      </>
    </ModalView>
  </>

  );
};

export default MiovaAdminTable;;