import { Button } from "@mui/material";
import React from "react";
import { experimentalStyled } from "@mui/material/styles";
import Loader from "../loader/Loader";

enum ButtonType {
  "button",
  "submit",
  "reset",
}
enum VarientType {
  "Primary",
  "Secondary",
  "Transparent",
  'GreenButton',
}
export interface ICTAButton {
  parentClass?: string;
  className?: string;
  type?: keyof typeof ButtonType;
  text: string;
  disabled?: boolean;
  varient: keyof typeof VarientType | undefined;
  children?: string | JSX.Element;
  icon?: JSX.Element;
  sx?: React.CSSProperties,
  onClick?: (e: any) => void;
  isLoading?: boolean
}

const PrimaryButton = experimentalStyled(Button)(({ theme }) => ({
  // padding: theme.spacing(2.2, 1.5),
  fontSize: "var(--text18)",
  lineHeight: "21px",
  textAlign: "center",
  borderRadius: "10px",
  textTransform: "capitalize",
  minWidth: "200px",
  fontWeight: "600",
  fontFamily: "var(--bodyFont)",
  padding: "12px 23px 11px 23px",
  color: "var(--CTATextColour)",
  backgroundColor: "var(--CTAButtonColour)",
  "&:hover": {
    color: "var(--CTAButtonColour)",
    backgroundColor: "var(--CTATextColour)",
  },
}));

const SecondaryButton = experimentalStyled(Button)(({ theme }) => ({
  fontSize: "var(--text18)",
  lineHeight: "21px",
  textAlign: "center",
  borderRadius: "10px",
  textTransform: "capitalize",
  minWidth: "200px",
  fontWeight: "600",
  border: "1px solid var(--PrimaryBrandColour)",
  padding: "11px 23px 10px 23px",
  color: "var(--PrimaryBrandColour)",
  backgroundColor: "var(--ThemeWhite)",
  fontFamily: "var(--bodyFont)",
  "&:hover": {
    color: "var(--CTAButtonColour)",
    backgroundColor: "var(--CTATextColour)",
  },
}));
const TransparentButton = experimentalStyled(Button)(({ theme }) => ({
  fontSize: "var(--text18)",
  lineHeight: "21px",
  textAlign: "center",
  borderRadius: "10px",
  textTransform: "capitalize",
  minWidth: "200px",
  fontWeight: "600",
  padding: "12px 23px 11px 23px",
  color: "var(--CTATextColour)",
  backgroundColor: "var(--ThemeOffWhite1)",
  fontFamily: "var(--bodyFont)",
  "&:hover": {
    color: "var(--ThemeWhite)",
    backgroundColor: "var(--Gray)",
  },
}));

const GreenButton = experimentalStyled(Button)(({ theme }) => ({
  fontSize: "var(--text18)",
  lineHeight: "21px",
  textAlign: "center",
  borderRadius: "10px",
  textTransform: "capitalize",
  minWidth: "200px",
  fontWeight: "600",
  padding: "12px 23px 11px 23px",
  color: "var(--PrimaryDark) !important",
  backgroundColor: "var(--GreenLight) !important",
  fontFamily: "var(--bodyFont)",
  "&:hover": {
    color: "var(--GreenLight) !important",
    backgroundColor: "var(--PrimaryDark) !important",
  },
}));

const renderButton = (props: ICTAButton) => {
  const { text, icon, children, varient, className, type, disabled, onClick, isLoading } = props;

  switch (varient) {
    case "Primary":
      return (
        <PrimaryButton
          {...props}
          disableRipple={true}
          onClick={onClick}
          className={`${className ? className : ""} site-button`}
        >
          {isLoading ? <Loader /> : text}
          {icon && icon}
          {children}
        </PrimaryButton>
      );

    case "Secondary":
      return (
        <SecondaryButton
          {...props}
          disableRipple={true}
          onClick={onClick}
          className={`${className ? className : ""} site-button`}
        >
          {isLoading ? <Loader /> : text}
          {icon && icon}
          {children}
        </SecondaryButton>
      );
    case "GreenButton":
      return (
        <GreenButton
          {...props}
          onClick={onClick}
          disableRipple={true}
          className={`${className ? className : ""} site-button`}
        >
          {isLoading ? <Loader /> : text}
          {icon && icon}
          {children}
        </GreenButton>
      );

    case "Transparent":
      return (
        <TransparentButton
          {...props}
          disableRipple={true}
          onClick={onClick}
          className={`${className ? className : ""} site-button`}
        >
          {isLoading ? <Loader /> : text}
          {icon && icon}
          {children}
        </TransparentButton>
      );


    default:
      return (
        <Button
          className={`${className ? className : ""} site-button`}
          sx={{
            fontSize: "var(--text18)",
            lineHeight: "21px",
            textAlign: "center",
            color: "var(--PrimaryBrandColour)",
            padding: "12px 23px 11px 23px",
            borderRadius: "10px",
            textTransform: "capitalize",
            minWidth: "200px",
            backgroundColor: "transparent",
            fontWeight: "500",
            textDecoration: "underline",
            fontFamily: "var(--bodyFont)",
            textUnderlineOffset: "3px",
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
          onClick={onClick}
          type={type ? type : "button"}
          disabled={disabled}
          disableRipple={true}
        >

          {text}
          {children}
        </Button>
      );
  }
};


const CTAButton = (props: ICTAButton) => {
  const { parentClass } = props;

  return (
    <>
      <div className={`${parentClass ? parentClass : ""}`} >
        {renderButton(props)}
      </div>
    </>
  );
};

export default CTAButton;
