import { Avatar, Box, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import { useDispatch } from "react-redux";
import { setLogoutData } from "../../redux/slices/AuthSlice";
import { useCallback, useEffect, useMemo } from "react";
import CTAButton from "../button/CTAButton";
import { setIsCreateTeam, setOpenTeamModal } from "../../redux/slices/TeamModalSlice";
import {  useLocation, useNavigate } from "react-router-dom";
import { flushThemeData } from "../../redux/slices/SettingSlice";
import { flushPermissions } from "../../redux/slices/permissionSlice";
import React from "react";
import LogoutIcon from "../vector-icons/LogoutIcon";
import UserIcon from "../vector-icons/UserIcon";
import AuthGuard from "../../pages/auth/AuthGuard";
import { BasicPermissionTypes, ModuleNames } from "../../constant/permission.constant";
import ResetIcon from "../vector-icons/ResetIcon";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSelector } from "react-redux";
import { getBuildEmailInsights } from "../../redux/slices/CommonSlice";
import { extractFirstCharacters, generatePdfName } from "../../utils/Index";
import { getAuthData, setSuperAdminLogoutData } from "../../redux/slices/SuperAdminSlice";
import { setIsCreateAdmin } from "../../redux/slices/DashboardModalSlice";
import useAuth from "../../hooks/useAuth";

interface IHeader {
  userLogged?: string,
}

const Header = ({ userLogged }: IHeader) => {

  const userLabel = userLogged && extractFirstCharacters(userLogged);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const showBuildEmailInsight = useSelector(getBuildEmailInsights);
  const superAdminDetail = useSelector(getAuthData);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  let permission = [{
    module: ModuleNames.SETTINGS,
    type: BasicPermissionTypes.SET_BRAND_COLOR,
  },];
  const { hasAuthorized } = useAuth();
  const hasPermission = hasAuthorized(permission);


  const handleCreateTeam = () => {
    dispatch(setIsCreateTeam(true));
    dispatch(setOpenTeamModal(true));
  };

  const handleCreateTemplate = () => {
    navigate(`template/create`);
  };

  const handleCategoriesClick = () => {
    navigate(`template/categories`);
  };

  const handleBuildEmail = () => {
    navigate(`emails/template`);
  };

  const handleResetPassword = () => {
    navigate(`change-password`);
  };

  const handleLogOut = useCallback(() => {
    handleClose();
    if (superAdminDetail && superAdminDetail.token !== null)
      dispatch(setSuperAdminLogoutData());
    else {
      dispatch(setLogoutData());
      dispatch(flushThemeData());
      dispatch(flushPermissions());
    }
  }, [dispatch, superAdminDetail]);



  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    setAnchorEl(null);
    navigate('/profile');
    // handleClose()
  };

  const handleDownloadInsights = () => {
    generatePDF();
  };

  const handleCreateAdmin = () => {
    dispatch(setIsCreateAdmin(true));
  };

  const generatePDF = () => {
    // Convert the HTML element to a canvas
    const pdfRef = document.getElementById('insights');
    if (pdfRef) {
      html2canvas(pdfRef).then(canvas => {
        const imgData = canvas.toDataURL('image/png');

        // Calculate the dimensions of the PDF page
        const imgWidth = 210; // A4 size: 210mm x 297mm
        const imgHeight = (canvas.height * imgWidth) / canvas.width;

        // Create a new jsPDF instance
        const pdf = new jsPDF('p', 'mm', 'a4');

        // Add the canvas image as a page in the PDF
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

        // Save the PDF
        let _pdfName = generatePdfName('Miova_Reports');
        pdf.save(`${_pdfName}.pdf`);
      });
    }
  };

  useEffect(() => {
    if (location.pathname === '/profile') {
      setAnchorEl(null);
    } else if (location.pathname === '/settings') {
      if (!hasPermission) {
        navigate('/unauth');
      }
    }
  }, [location.pathname, hasPermission, navigate]);

  const showCreateTeam = location.pathname.startsWith('/teams');
  const showDownload = location.pathname === '/' && (!showBuildEmailInsight);
  const showBuildEmailInsights = location.pathname === '/' && showBuildEmailInsight;
  const showCreateTemplate = location.pathname === '/template';
  const showBuildEmail = location.pathname === '/emails';
  const showCategories = location.pathname.startsWith('/template');
  const hideCategories = location.pathname.startsWith('/template/categories');
  const showCreateAdmin = location.pathname.startsWith('/admin/miova-admin');
  const open = useMemo(() => {
    return Boolean(anchorEl);
  }, [anchorEl]);

  return (
    <>
      <Box className="header" sx={{ padding: { xs: '16px', md: '28px 35px 0', xl: '28px 35px' } }}>
        <Box className="header-inner" sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          rowGap: '20px'
        }}>
          <Box className="" display={"flex"} sx={{ width: { xs: '100%', lg: 'auto' }, justifyContent: "space-between" }}>
            <Box>
              <p className="header-name EmailHeader">Hi {userLogged},</p>
              <p className="header-subtitle SupportText text-Gray">
                Welcome back!
              </p>
            </Box>
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2, display: { xs: 'block', lg: 'none' } }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar sx={{ width: 40, height: 40 }}>{userLabel}</Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          {/* marginTop: { xs: '1.25rem', lg: '0' } */}
          <Box sx={{ width: { xs: '100%', lg: 'auto' }, display: 'flex', gap: { sm: 3, lg: 5 }, flexWrap: 'wrap', justifyContent: 'flex-end' }}>
            {
              (showCategories && !hideCategories) &&
              <AuthGuard
                permissions={[
                  {
                    module: ModuleNames.CATEGORY,
                    type: BasicPermissionTypes.CREATE,
                  },
                ]}
              >
                <CTAButton varient="Secondary" text="Manage Categories" onClick={handleCategoriesClick} />
              </AuthGuard>
            }
            {
              showCreateTemplate &&

              <CTAButton varient="Primary" text="Create Template" onClick={handleCreateTemplate} />
            }

            {
              showCreateTeam &&
              <AuthGuard
                permissions={[
                  {
                    module: ModuleNames.TEAMS,
                    type: BasicPermissionTypes.CREATE,
                  },
                ]}
              >
                <CTAButton varient="Primary" text="Create Team" onClick={handleCreateTeam} />
              </AuthGuard>
            }

            {
              showBuildEmail &&
              <CTAButton varient="Primary" text="Build Email" onClick={handleBuildEmail} />
            }
            {
              showBuildEmailInsights &&
              <CTAButton varient="Primary" text="Build Email" onClick={handleBuildEmail} />
            }

            {
              showDownload &&
              <CTAButton varient="Primary" text="Download" onClick={handleDownloadInsights} />
            }
            {
              showCreateAdmin &&
              <CTAButton varient="Primary" text="Create Admin" onClick={handleCreateAdmin} />
            }

            <Tooltip title="Account settings" sx={{}}>
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ display: { xs: 'none', lg: 'block' } }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar sx={{ width: 40, height: 40 }}>
                  {userLabel}
                </Avatar>
              </IconButton>
            </Tooltip>
          </Box>
          {open &&
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&:before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {
                !superAdminDetail.isSuperAdmin &&
                <>
                  <MenuItem onClick={handleProfileClick}>
                    <ListItemIcon>
                      <UserIcon />
                    </ListItemIcon>
                    Profile
                  </MenuItem>
                  <MenuItem onClick={handleResetPassword}>
                    <ListItemIcon>
                      <ResetIcon />
                    </ListItemIcon>
                    Change Password
                  </MenuItem>
                </>
              }
              <MenuItem onClick={handleLogOut}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          }
        </Box>
      </Box>
    </>
  );
};

export default Header;
