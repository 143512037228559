import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { LogoFull } from "../icons";
import "./sidebar.css";
import { SmallText } from "../commonStyled/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import AuthGuard from "../../pages/auth/AuthGuard";
import { BasicPermissionTypes, ModuleNames } from "../../constant/permission.constant";

const drawerWidth = 250;
const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const redirect = (url: string) => () => {
    navigate(url);
  };

  const getActiveClass = (url: string) => {
    return location.pathname.startsWith(url) ? "sidebarActive" : "";
  };

  const getInsightClass = () => {
    return location.pathname === '/' ? "sidebarActive" : "";
  };

  return (
    <>
      <Drawer
        sx={{
          maxWidth: drawerWidth,
          width: "100%",
          "& .MuiDrawer-paper": {
            paddingBottom: "1.375rem",
            maxWidth: drawerWidth,
            width: "100%",
            boxSizing: "border-box",
            borderRightColor: "var(--PrimaryBrandColour)",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box
          className=""
          sx={{
            width: "100%",
            paddingTop: "1.875rem",
            paddingLeft: "2rem",
            marginBottom: "5.25rem",
          }}
        >
          <div className="logo-wrap">
            <LogoFull className="w-full h-auto" />
          </div>
        </Box>

        <List>
          <ListItem
            key={"Insights"}
            disablePadding
            sx={{
              "&:not(:last-child)": {
                marginBottom: "5px",
              },
            }}
            onClick={redirect("/")}
          >
            <ListItemButton
              className={`custom ${getInsightClass()}`}
              sx={{
                all: "initial",
                width: "100%",
                fontWeight: "500",
                cursor: "pointer",
                padding: "13px 16px 13px 32px",
                "&::before": {
                  backgroundColor: "var(--PrimaryBrandColour)",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  content: '""',
                  opacity: "0",
                },
                "&.sidebarActive": {
                  "&::before": {
                    opacity: '0.05'
                  },
                  backgroundColor: "var(--OffWhite)",
                  color: "var(--PrimaryBrandColour)",
                  fontWeight: "600",
                  "&:hover": {
                    "&::before": {
                      opacity: '0.1'
                    },
                  },
                },
              }}
            >
              <ListItemText
                primary={"Insights"}
                sx={{ margin: "0", lineHeight: "20px" }}
                className="NotSelectedNavigation"
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={"Emails"}
            disablePadding
            sx={{
              "&:not(:last-child)": {
                marginBottom: "5px",
              },
            }}
            onClick={redirect("/emails")}
          >
            <ListItemButton
              className={`custom ${getActiveClass("/emails")}`}
              sx={{
                all: "initial",
                width: "100%",
                fontWeight: "500",
                cursor: "pointer",
                padding: "13px 16px 13px 32px",
                "&::before": {
                  backgroundColor: "var(--PrimaryBrandColour)",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  content: '""',
                  opacity: "0",
                },
                "&.sidebarActive": {
                  "&::before": {
                    opacity: '0.05'
                  },
                  backgroundColor: "var(--OffWhite)",
                  color: "var(--PrimaryBrandColour)",
                  fontWeight: "600",
                  "&:hover": {
                    "&::before": {
                      opacity: '0.1'
                    },
                  },
                },
              }}
            >
              <ListItemText
                primary={"Emails"}
                sx={{ margin: "0", lineHeight: "20px" }}
                className="NotSelectedNavigation"
              />
            </ListItemButton>
            {/* </Link> */}
          </ListItem>
          <ListItem
            key={"Templates"}
            disablePadding
            sx={{
              "&:not(:last-child)": {
                marginBottom: "5px",
              },
            }}
            onClick={redirect("/template")}
          >
            <ListItemButton
              className={`custom ${getActiveClass("/template")}`}
              sx={{
                all: "initial",
                width: "100%",
                fontWeight: "500",
                cursor: "pointer",
                padding: "13px 16px 13px 32px",
                "&::before": {
                  backgroundColor: "var(--PrimaryBrandColour)",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  content: '""',
                  opacity: "0",
                },
                "&.sidebarActive": {
                  "&::before": {
                    opacity: '0.05'
                  },
                  backgroundColor: "var(--OffWhite)",
                  color: "var(--PrimaryBrandColour)",
                  fontWeight: "600",
                  "&:hover": {
                    "&::before": {
                      opacity: '0.1'
                    },
                  },
                },
              }}
            >
              <ListItemText
                primary={"Templates"}
                sx={{ margin: "0", lineHeight: "20px" }}
                className="NotSelectedNavigation"
              />
            </ListItemButton>
          </ListItem>
          <ListItem
            key={"Teams"}
            disablePadding
            sx={{
              "&:not(:last-child)": {
                marginBottom: "5px",
              },
            }}
            onClick={redirect("/teams")}
          >
            <ListItemButton
              className={`custom ${getActiveClass("/teams")}`}
              sx={{
                all: "initial",
                width: "100%",
                fontWeight: "500",
                cursor: "pointer",
                padding: "13px 16px 13px 32px",
                "&::before": {
                  backgroundColor: "var(--PrimaryBrandColour)",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  content: '""',
                  opacity: "0",
                },
                "&.sidebarActive": {
                  "&::before": {
                    opacity: '0.05'
                  },
                  backgroundColor: "var(--OffWhite)",
                  color: "var(--PrimaryBrandColour)",
                  fontWeight: "600",
                  "&:hover": {
                    "&::before": {
                      opacity: '0.1'
                    },
                  },
                },
              }}
            >
              <ListItemText
                primary={"Teams"}
                sx={{ margin: "0", lineHeight: "20px" }}
                className="NotSelectedNavigation"
              />
            </ListItemButton>
          </ListItem>

          <AuthGuard
            permissions={[
              {
                module: ModuleNames.SETTINGS,
                type: BasicPermissionTypes.SET_BRAND_COLOR,
              },
            ]}
          >

            <ListItem
              key={"Settings"}
              disablePadding
              sx={{
                "&:not(:last-child)": {
                  marginBottom: "5px",
                },
              }}
              onClick={redirect("/settings")}
            >
              <ListItemButton
                className={`custom ${getActiveClass("/settings")}`}
                sx={{
                  all: "initial",
                  width: "100%",
                  fontWeight: "500",
                  cursor: "pointer",
                  padding: "13px 16px 13px 32px",
                  "&::before": {
                    backgroundColor: "var(--PrimaryBrandColour)",
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    content: '""',
                    opacity: "0",
                  },
                  "&.sidebarActive": {
                    "&::before": {
                      opacity: '0.05'
                    },
                    backgroundColor: "var(--OffWhite)",
                    color: "var(--PrimaryBrandColour)",
                    fontWeight: "600",
                    "&:hover": {
                      "&::before": {
                        opacity: '0.1'
                      },
                    },
                  },
                }}
              >
                <ListItemText
                  primary={"Settings"}
                  sx={{ margin: "0", lineHeight: "20px" }}
                  className="NotSelectedNavigation"
                />
              </ListItemButton>
            </ListItem>
          </AuthGuard>
        </List>
        <Box
          className="sidebar-bottom-box"
          sx={{ padding: "0 1.0625rem", marginTop: "auto" }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              backgroundColor: "var(--OffWhite)",
              padding: "0.625rem 1.25rem",
              borderRadius: "0.9375rem",
            }}
          >
            <div className="logo-wrap">
              <LogoFull className="w-full h-auto" />
            </div>
            <SmallText>Copywrite &copy; 2022, all rights reserved</SmallText>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default Sidebar;
