import React, { useEffect, useState } from 'react';
import { getClientSecretKey } from '../../services/UserAuthentication';
declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const PricingTable = () => {
  // Paste the stripe-pricing-table snippet in your React component

  const [clientSecretKey, setClientSecretKey] = useState<string>('');

  useEffect(() => {
    (async () => {
      try {
        const result = await getClientSecretKey();
        if (result.data) {
          setClientSecretKey(result.data?.data?.data?.client_secret);
        }
      } catch (error) {
        console.log("Error");
      }
    })();
  }, []);

  const pricingTableId = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_STRIPE_PRICING_TABLE_ID : process.env.REACT_APP_STRIPE_PRICING_TABLE_ID_TEST;
  const publishableKey = process.env.REACT_APP_ENVIRONMENT === 'production' ? process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST;

  return (
    <>
      {
        clientSecretKey &&
        <stripe-pricing-table
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
          pricing-table-id={pricingTableId}
          publishable-key={publishableKey}
          customer-session-client-secret={clientSecretKey}
        >
        </stripe-pricing-table>
      }
    </>
  );
};

export default PricingTable;