import { Box, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, { useCallback } from "react";
import { MenuText } from "../../../../components/commonStyled/Typography";
import InputField from "../../../../components/input-field/InputField";
import CTAButton from "../../../../components/button/CTAButton";
import { generatePayload, initialValues, validationSchema } from "../helper";
import { useDispatch } from "react-redux";
import { setIsCreateAdmin } from "../../../../redux/slices/DashboardModalSlice";
import { IDashboardAdmin } from "../../../../types/dashboard/admin/Admin";
import { createDashboardAdmin } from "../../../../services/SuperAdmin";

interface IMiovaAdminCreateDetails {
  updateList: () => Promise<void>;
}

const MiovaAdminCreateDetails = ({ updateList }: IMiovaAdminCreateDetails) => {
  const dispatch = useDispatch();

  const handleCreateAdmin = useCallback(
    async (values: IDashboardAdmin) => {
      try {
        const payload = generatePayload(values);
        const result = await createDashboardAdmin(payload);
        if (result.data.data) {
          dispatch(setIsCreateAdmin(false));
          updateList();
        }
      } catch (error) {
        console.log("error: ", error);
      }
    },
    [dispatch, updateList]
  );

  return (
    <Box
      sx={{
        padding: "30px 30px 15px",
        marginTop: "30px",
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleCreateAdmin}
        enableReinitialize={true}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={{ xs: 4, md: 6 }}>
              <Grid item xs={12} lg={8}>
                <Grid container spacing={{ xs: 4, md: 6 }}>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography className="form-label-typography">
                        <MenuText>First Name</MenuText>
                      </Typography>
                      <InputField name="first_name" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography className="form-label-typography">
                        <MenuText>Last Name</MenuText>
                      </Typography>
                      <InputField name="last_name" />
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box>
                      <Typography className="form-label-typography">
                        <MenuText>Email Address</MenuText>
                      </Typography>
                      <InputField name="email" />
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    {/* <Box sx={{ marginTop: { lg: 35, md: 30, sm: 25 }, textAlign: 'center' }}> */}
                    <Box sx={{ marginTop: { xs: 4 }, marginBottom: { xs: 2 }, textAlign: "center" }}>
                      <CTAButton text="Save" varient="Primary" type="submit" />
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default MiovaAdminCreateDetails;
