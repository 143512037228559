import React from "react";

interface ICompanyIcons {
  onClick?: () => void;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const CompanyIcon = ({ onClick, style, disabled }: ICompanyIcons) => {
  return disabled ? null : (
    <svg
      className=""
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      onClick={onClick} style={style}
    >
      <path d="M12 7V3H2v18h20V7H12zm-2 12H4v-2h6v2zm0-4H4v-2h6v2zm0-4H4V9h6v2zm0-4H4V5h6v2zm10 12h-8V9h8v10zm-2-8h-4v2h4v-2zm0 4h-4v2h4v-2z"></path>
    </svg>
  );
};

export default CompanyIcon;
