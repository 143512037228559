// ** Hooks **
// import useAuth from 'hooks/useAuth';

import useAuth from "../../hooks/useAuth";
import { AuthGuardProps } from "../../types/permission/Permissions";

// // ** Types **
// import {
//   AuthGuardProps,
//   permissionOperatorEnum,
// } from '../types/authGuard.types';

const AuthGuard = ({
    permissions,
    //   operator = permissionOperatorEnum.AND,
    children,
}: AuthGuardProps) => {
    // ** Custom Hooks **
    const { hasAuthorized } = useAuth();
    const hasPermission = hasAuthorized(permissions);

    return hasPermission ? children : <></>;
};

export default AuthGuard;