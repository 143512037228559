export enum Links {
  TERM_AND_CONDITIONS = 'https://miova.io/terms-and-conditions',
  SENDER_VERIFICATION = 'https://miova.io/sender-verification',
  MAIN_DOMAIN = 'https://miova.io',
}

export enum CONSTANTS {
  DOMAIN_REGISTERED = 'DOMAIN_ADDRESS_ALREADY_RECOGNISED',
  DISABLED = 'Disabled',
  ENABLED = 'Enabled',
  CUSTOM = 'Custom',
  TOTAL_COMPANIES = 'Companies',
  TOTAL_USERS = 'Users',
  TOTAL_TEAM_MEMBERS = 'Team Members',
  TOTAL_EMAIL = 'Emails Sent',
}

export enum planUsageTypes {
  ADMIN = 'ADMIN',
  USER = 'USER',
  TEAM = 'TEAM',
  TEAM_MEMBERS = 'TEAM_MEMBERS',
}

export const PlanError = {
  ERROR_DESCRIPTION:
    'We see you have recently updated your account plan and are currently over your revised account limits.',
  ERROR_SUBDESCRIPTIONS: 'Please update the following to:',
};
