import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import stores from './redux/Store';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';
import setupAxios from './axios/config';
import ToastNotification from './components/toastNotification/ToastNotification';
import ErrorBoundary from './components/error-boundary/ErrorBoundary';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

setupAxios(stores.store)
root.render(
  <ErrorBoundary >
    <Provider store={stores.store}>
      <PersistGate persistor={stores.persist}>
        {/* <ThemeWrapper> */}
        <BrowserRouter>
          <ToastNotification />
          <App />
        </BrowserRouter>
        {/* </ThemeWrapper> */}
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
