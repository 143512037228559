// ** Import Packages **
import { useSelector } from "react-redux";
import {
  BasicPermissionTypes,
  ModuleNames,
} from "../constant/permission.constant";
import { getPermissions } from "../redux/slices/permissionSlice";

// ** Redux **

// ** hooks-services ** //

// ** Type **

// ** Constant **
// ** Other **

const useAuth = () => {
  // ** Hooks **
  const currentUserPermissions = useSelector(getPermissions);

  const hasAuthorized = (
    permissions: {
      module?: ModuleNames;
      type?: BasicPermissionTypes;
    }[]
  ) => {
    let _currentUserPermissions = currentUserPermissions.permission.permissions;
    return permissions?.some((permission) =>
      _currentUserPermissions?.some(
        (currModule) =>
          currModule.name === permission.module &&
          currModule.permissions.find((per) => per.name === permission.type)
            ?.status === "ACTIVE"
      )
    );
  };

  return { hasAuthorized };
};

export default useAuth;
