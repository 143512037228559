import { Box, Typography } from '@mui/material';
import { ErrorMessage, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select, { MultiValue, StylesConfig, components } from 'react-select';
interface MultiSelectProps<T> {
  name?: string
  options?: T[];
  selected?: MultiValue<T[]>
  labelKey: keyof T;
  valueKey: keyof T;
  icon?: string
  placeholder?: string;
  classname?: string;
  withCheckbox?: boolean;
  renderValues?: boolean;
  hideSelectedOptions?: boolean
  totalCount?: number,
  initialPage?: number;
  onChange?: (selectedOptions: MultiValue<T[]>) => void;
  onInputChange?: (searchVal: string) => void
  onFocus?: () => Promise<void>
  loadMore?: (pageNumber: number) => Promise<void>;
  background?: string,
  fontPrimary?:boolean
}


export interface Option {
  label: string;
  value: string | number;
}

export const customStylesdefault = (fontPrimary?: boolean, background?: string): StylesConfig<any> => ({
  control: (provided) => ({
    ...provided,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    backgroundColor: background ? background : "transparent",
    flexWrap: "nowrap",
    minHeight: '47px',
    // boxShadow: "0 0 0 0px var(--Gray) !important",
    boxShadow: "none !important",

    borderRadius: '6px',
    "&:focus": {
      // borderColor: "var(--PrimaryBlue)",
      border: '1px solid transparent',
      boxShadow: "0 0 0 2px var(--PrimaryBlue) !important",
      outline: "0 !important",
    },
    "&:hover": {
      // borderColor: "var(--PrimaryBlue)",
      border: '1px solid transparent',
      boxShadow: "0 0 0 1.5px var(--PrimaryBlue) !important",
      outLine: "none !important",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    minWidth: "100px",
    paddingRight: "0",
    paddingLeft: '15px',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  multiValue: (provided) => ({
    ...provided,
  }),
  multiValueLabel: (provided) => ({
    ...provided,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
  }),
  menu: (provided) => ({
    ...provided,
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  input: (provided) => ({
    ...provided,
    padding: "0 0",
    margin: '7px 0px',
    fontSize: "1rem",
    lineHeight: "1.4375em",
        color: fontPrimary ? "var(--PrimaryBrandColour)" : 'inherit',

  }),
  option: (provided) => ({
    ...provided,
    fontSize: "1rem",
    lineHeight: "1.4375em",
    color: "var(--PrimaryDark)",
    backgroundColor: "var(--ThemeWhite)",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "var(--themeColor)",
    },
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      color: 'var(--Gray)',
      fontWeight: '400',
      fontSize: '1rem',
      lineHeight: '17px',
    };
  },
});

const CustomOption = ({
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest }: any) => {

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      innerProps={innerProps}
    >
      <input type="checkbox" checked={isSelected} style={{ marginRight: '10px' }} />
      {children}
    </components.Option>
  );
};

const MultiSelect = <T,>({ onInputChange, onFocus, totalCount, fontPrimary,options, loadMore, onChange, labelKey, valueKey, name, selected, placeholder, icon, classname, renderValues = true, withCheckbox = false, hideSelectedOptions = false, initialPage = 1, background }: MultiSelectProps<T>) => {

  const formik = useFormikContext()
  const [selectedOptions, setSelectedOptions] = useState<MultiValue<T[]>>([]);
  const [pageNumber, setPageNumber] = useState(initialPage);
  // const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const handleSelectChange = useCallback((selectedOptions: MultiValue<T[]>) => {
    // setPageNumber(pageNumber)
    setSelectedOptions(selectedOptions);
    if (onChange)
      onChange(selectedOptions);
  }, [onChange]);

  const handleInputChange = (inputValue: string) => {
    if (onInputChange)
      onInputChange(inputValue)
  };

  const mappedOptions: any = useMemo(() => {
    if (options?.length === 0) {
      return []
    } else {
      return options?.map?.((option) => ({
        label: option[labelKey] as string,
        value: option[valueKey],
      }));
    }
  }, [labelKey, options, valueKey])

  const mappedInitialValues: any = useMemo(() => {
    if (selected?.length === 0) {
      return []
    } else {
      return selected?.map?.((option) => ({
        label: option[labelKey as keyof MultiValue<T>] as string,
        value: option[valueKey as keyof MultiValue<T>],
      }));
    }
  }, [labelKey, valueKey, selected])

  useEffect(() => {
    if (selectedOptions?.length === 0) {
      setSelectedOptions(mappedInitialValues)
    }
  }, [selectedOptions, mappedInitialValues])

  const isWrappedInFormik = !!formik

  const handleMenuScroll = async (event: WheelEvent | TouchEvent) => {
    const target = event.target as HTMLDivElement;
    const { scrollHeight, scrollTop, clientHeight } = target;
    const isAtBottom = scrollHeight - (scrollTop + clientHeight) <= 10;

    const shouldLoadMore = !(totalCount && mappedOptions.length === totalCount);

    if (isAtBottom && loadMore && shouldLoadMore) {
      await loadMore(pageNumber + 1);
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const handlePageNumber = () => {
    setPageNumber(1)
  }

  return (
    <>
      <Box>
        <Select
          className={classname}
          name={name}
          isMulti
          options={mappedOptions}
          value={selectedOptions}
          onChange={handleSelectChange}
          isSearchable
          onFocus={onFocus}
          placeholder={placeholder || 'Filter'}
          onInputChange={handleInputChange}
          styles={customStylesdefault(fontPrimary,background)}
          onMenuClose={handlePageNumber}
          controlShouldRenderValue={renderValues}
          onMenuScrollToBottom={handleMenuScroll}
          components={{
            DropdownIndicator: () => icon !== 'DROPDOWN' ? <div style={{ marginRight: '10px', display: 'flex' }}>
              <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 1C10.6716 1 10 1.67157 10 2.5C10 3.32843 10.6716 4 11.5 4C12.3284 4 13 3.32843 13 2.5C13 1.67157 12.3284 1 11.5 1ZM9.05001 2C9.28164 0.85888 10.2905 0 11.5 0C12.7095 0 13.7184 0.85888 13.95 2H16V3H13.95C13.7184 4.14112 12.7095 5 11.5 5C10.2905 5 9.28164 4.14112 9.05001 3H0V2H9.05001ZM4.5 6C3.67157 6 3 6.67157 3 7.5C3 8.32843 3.67157 9 4.5 9C5.32843 9 6 8.32843 6 7.5C6 6.67157 5.32843 6 4.5 6ZM2.05001 7C2.28164 5.85888 3.29052 5 4.5 5C5.70948 5 6.71836 5.85888 6.94999 7H16V8H6.94999C6.71836 9.14112 5.70948 10 4.5 10C3.29052 10 2.28164 9.14112 2.05001 8H0V7H2.05001ZM11.5 11C10.6716 11 10 11.6716 10 12.5C10 13.3284 10.6716 14 11.5 14C12.3284 14 13 13.3284 13 12.5C13 11.6716 12.3284 11 11.5 11ZM9.05001 12C9.28164 10.8589 10.2905 10 11.5 10C12.7095 10 13.7184 10.8589 13.95 12H16V13H13.95C13.7184 14.1411 12.7095 15 11.5 15C10.2905 15 9.28164 14.1411 9.05001 13H0V12H9.05001Z" fill="#9E9E9E" />
              </svg>
            </div> :
              <div style={{ marginRight: "10px", display: "flex" }}>
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.646447 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L7 6.29289L12.6464 0.646447C12.8417 0.451184 13.1583 0.451184 13.3536 0.646447C13.5488 0.841709 13.5488 1.15829 13.3536 1.35355L7.35355 7.35355C7.15829 7.54882 6.84171 7.54882 6.64645 7.35355L0.646447 1.35355C0.451184 1.15829 0.451184 0.841709 0.646447 0.646447Z"
                    fill="#9E9E9E"
                  ></path>
                </svg>
              </div>,
            ...(withCheckbox ? { Option: CustomOption } : {})
          }}

          hideSelectedOptions={hideSelectedOptions}
          closeMenuOnSelect={false}
          isClearable={false}
        />
      </Box>
      {isWrappedInFormik && name && (
        <ErrorMessage name={name}>
          {(msg) => (
            <Typography
              style={{
                color: "red",
                textAlign: "left",
                fontSize: "var(--text14)",
                fontWeight: "500",
                fontFamily: "var(--bodyFont)",
                marginTop: "2px",
              }}
            >
              {msg}
            </Typography>
          )}
        </ErrorMessage>
      )}
    </>
  );
};

export default MultiSelect;
