import axios from 'axios';
import { Store } from 'redux';
import { ToastShow } from '../redux/slices/ToastSlice';
import { setLogoutData } from '../redux/slices/AuthSlice';
import { setDomainError } from '../redux/slices/CommonSlice';
import { CONSTANTS } from '../constant/general.constant';
import { flushThemeData } from '../redux/slices/SettingSlice';
import { flushPermissions } from '../redux/slices/permissionSlice';
import { removeEmptyValues } from '../utils/Index';

const setupAxios = (store: Store) => {
  axios.interceptors.request.use(
    (request: any) => {
      const storeData = store.getState();
      const authToken =
        storeData?.superAdmin?.superAdminDetails && Object.keys(storeData?.superAdmin?.superAdminDetails).length > 0
          ? storeData?.superAdmin?.token
          : storeData.auth.token;
      const orguid = storeData?.auth?.orguuid;

      if (authToken) {
        request.headers.Authorization = `JWT ${authToken}`;
        request.headers.xaccount = orguid;
      }
      return removeEmptyValues(request);
    },
    (err) => {
      return Promise.reject(err);
    },
  );
  axios.interceptors.response.use(
    (res) => {
      const toast = res.data?.toast;

      if (toast) {
        store.dispatch(
          ToastShow({
            message: res.data?.message,
            type: res.data?.responseType,
          }),
        );
      }
      return res;
    },
    (err) => {
      if (err.response && err.response.status >= 400 && err.response.status <= 500) {
        const toast = err.response?.data?.toast;
        const checkDomainError = err.response?.data?.data?.type === CONSTANTS.DOMAIN_REGISTERED;
        const checkPermission = err.response?.data?.data?.permissionDenied;
        if (checkDomainError) {
          store.dispatch(setDomainError(err.response?.data?.message));
          return { data: null };
        }
        if (checkPermission) {
          store.dispatch(setLogoutData());
          store.dispatch(flushThemeData());
          store.dispatch(flushPermissions());
        }

        if (toast) {
          store.dispatch(
            ToastShow({
              message: err.response?.data?.message,
              type: err.response?.data?.responseType,
            }),
          );
        }
        if (err.response?.status === 401) {
          store.dispatch(setLogoutData());

          setTimeout(() => {
            window.location.href = '/';
          }, 500);
        }
        return Promise.reject(new Error('Bad status code'));
      } else {
        return err.response;
      }
    },
  );
};

export default setupAxios;

export function axiosGet<T>(url: string, data: T | null = null) {
  return axios.get(`${process.env.REACT_APP_API_URL}${url}`, {
    params: data,
  });
}

export function axiosPost<T>(url: string, data: T | object) {
  return axios.post(`${process.env.REACT_APP_API_URL}${url}`, data);
}

export const axiosConfig = (method: string, url: string, config: any, data: object) => {
  return axios({
    method: method,
    url: `${process.env.REACT_APP_API_URL}${url}`,
    ...config,
    data,
  });
};

export const axiosPatch = (url: string, data: object) => {
  return axios.patch(`${process.env.REACT_APP_API_URL}${url}`, data);
};

export const axiosPut = (url: string, data: object) => {
  return axios.put(`${process.env.REACT_APP_API_URL}${url}`, data);
};

export const axiosDelete = (url: string, data: object) => {
  return axios.delete(`${process.env.REACT_APP_API_URL}${url}`, { data });
};
