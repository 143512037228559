import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CardComponent } from '../../../components/card/CardComponent';
import ModalView from '../../../components/modal/Modal';
import { debounce, removeQueryParams, setQueryParams } from '../../../utils/Index';
import UsersTable from './components/UsersTable';
import UsersEditDetail from './components/UsersEditDetails';
import { getUserList, removeUserById, sendSuperAdminVerificationMail } from '../../../services/SuperAdmin';
import { User } from '../../../types/permission/Permissions';

const Users = () => {

  const [usersModalOpen, setUsersModalOpen] = useState<boolean>(false);
  const [usersList, setUsersList] = useState<User[]>([]);
  const [searchUsers, setSearchUsers] = useState<string>('');
  const [searchUsersQuery, setSearchUsersQuery] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [userCount, setUserCount] = useState<number>(0);

  const query = useMemo(() => {
    let _query = {
      ...(searchUsersQuery ? { 'search': searchUsersQuery } : {}),
      limit: 10,
      page: currentPage
    };
    return _query;
  }, [currentPage, searchUsersQuery]);

  const handleView = (id: number) => {
    setQueryParams({ id: id.toString() });
    setUsersModalOpen(true);
  };

  const handleVerificationEmail = useCallback(async (id: number) => {
    try {
      const result = await sendSuperAdminVerificationMail({ userId: id });
      if (result.data.data) {
        setUsersList(result.data.data.rows);
        setUserCount(result.data.data?.count);
      }
    } catch (error) {
      console.log("error: ", error);
    }

  }, []);

  const handleUsersModalClose = () => {
    removeQueryParams();
    setUsersModalOpen(false);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleSearchUsers = useCallback(
    (val: string) => {
      setSearchUsersQuery(val);
    }, []);

  const debouncedSearch = debounce(handleSearchUsers, 800);


  const handleSearchUsersChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchUsers(e.target.value);
      debouncedSearch(e.target.value);
      setCurrentPage(1);
    }, [debouncedSearch]
  );

  const fetchUserList = useCallback(async () => {
    try {
      const result = await getUserList(query);
      if (result.data.data) {
        setUsersList(result.data.data.rows);
        setUserCount(result.data.data?.count);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }, [query]);

  const handleDeleteAdmin = useCallback(async (id: number | null) => {
    if (id) {
      try {
        const result = await removeUserById(id);
        if (result.data.data) {
          fetchUserList();
        }
      } catch (error) {
        console.log("error: ", error);
      }
    }
  }, [fetchUserList]);

  useEffect(() => {
    fetchUserList();
  }, [fetchUserList]);

  return (
    <>
      <CardComponent
        title="Users"
        search
        searchValue={searchUsers}
        searchPlaceholder="Search Users"
        handleSearch={handleSearchUsersChange}
      >
        <UsersTable
          data={usersList}
          handleView={handleView}
          count={userCount}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          handleDelete={handleDeleteAdmin}
          handleSendVerification={handleVerificationEmail}
        />
      </CardComponent>
      <ModalView open={usersModalOpen} closeModal={handleUsersModalClose} >
        <UsersEditDetail closeModal={handleUsersModalClose} updateUserList={fetchUserList} />
      </ModalView>
    </>
  );
};

export default Users;
