import { CircularProgress } from '@mui/material'
import React from 'react'

interface ILoaderProps {
    color?: string,

}

const Loader = (props: ILoaderProps) => {

    const { color = "var(--CTATextColour)" } = props

    return (
        <CircularProgress
            sx={{ color: color, '& svg': { marginLeft: "0px !important" } }}
            size={24} />
    )
}

export default Loader