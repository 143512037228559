import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CardComponent } from '../../../components/card/CardComponent';
import ModalView from '../../../components/modal/Modal';
import { debounce, removeQueryParams, setQueryParams } from '../../../utils/Index';
import TeamMemberTable from './components/TeamMemberTable';
import TeamMembersEditDetail from './components/TeamMemberEditDetails';
import { getContactList, removeTeamMemberById } from '../../../services/SuperAdmin';
import { ISuperAdminContactDetails, } from '../../../types/teams/Team';

const TeamMember = () => {

  const [teamMemberModalOpen, setTeamMemberModalOpen] = useState<boolean>(false);
  const [contactList, setContactList] = useState<ISuperAdminContactDetails[]>([]);
  const [searchTeamMember, setSearchTeamMember] = useState<string>('');
  const [searchTeamMemberQuery, setSearchTeamMemberQuery] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [contactCount, setContactCount] = useState<number>(0);

  const query = useMemo(() => {
    let _query = {
      "include[account][select]": "id,company_name",
      ...(searchTeamMemberQuery ? {
        searchFields: "first_name,last_name,email",
        searchText: searchTeamMemberQuery,
      } : {}),
      limit: 10,
      page: currentPage
    };
    return _query;
  }, [currentPage, searchTeamMemberQuery]);

  const handleView = (id: number) => {
    setQueryParams({ id: id.toString() });
    setTeamMemberModalOpen(true);
  };
  const handleTeamMemberModalClose = () => {
    removeQueryParams();
    setTeamMemberModalOpen(false);
  };

  const handleSearchTeamMembers = useCallback(
    (val: string) => {
      setSearchTeamMemberQuery(val);
    }, []);

  const debouncedSearch = debounce(handleSearchTeamMembers, 800);

  const handleSearchTeamMemberChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTeamMember(e.target.value);
      debouncedSearch(e.target.value);
      setCurrentPage(1);
    }, [debouncedSearch]
  );

  const fetchContactList = useCallback(async () => {
    try {
      const result = await getContactList(query);
      if (result.data.data) {
        setContactList(result.data.data.rows);
        setContactCount(result.data.data?.count);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }, [query]);

  const handleDeleteTeamMember = useCallback(async (id: number | null) => {
    if (id) {
      try {
        const result = await removeTeamMemberById(id);
        if (result.data.data) {
          fetchContactList();
        }
      } catch (error) {
        console.log("error: ", error);
      }
    }
  }, [fetchContactList]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    fetchContactList();
  }, [fetchContactList]);

  return (
    <>
      <CardComponent
        title="Team Member"
        search
        searchValue={searchTeamMember}
        searchPlaceholder="Search Team Member"
        handleSearch={handleSearchTeamMemberChange}
      >
        <TeamMemberTable
          data={contactList}
          count={contactCount}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
          handleView={handleView}
          handleDelete={handleDeleteTeamMember}
        />
      </CardComponent>
      <ModalView open={teamMemberModalOpen} closeModal={handleTeamMemberModalClose} >
        <TeamMembersEditDetail updateTeamList={fetchContactList} closeModal={handleTeamMemberModalClose} />
      </ModalView>
    </>
  );
};

export default TeamMember;
