import React, { useState } from 'react';

interface CheckboxOption {
    label: string;
    value: string;
}

interface SingleSelectCheckboxProps {
    options: CheckboxOption[];
    onChange: (selectedValue: string) => void;
    filterValue?: string
}

const SingleSelectCheckbox: React.FC<SingleSelectCheckboxProps> = ({ options, onChange, filterValue }) => {
    const [selectedValue, setSelectedValue] = useState<string>('');

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        if (selectedValue === 'clear')
            setSelectedValue('')
        else
            setSelectedValue(value);
        onChange(value);
    };

    const clear = {
        label: 'Clear',
        value: ''
    }

    let _options = [
        ...options,
        clear
    ]

    return (
        <div>
            {_options.map((option) => {
                return (
                    <div key={option.value}>
                        <label>
                            <input
                                type="checkbox"

                                value={option.value}
                                checked={filterValue === option.value}
                                onChange={handleCheckboxChange}
                            />
                            {option.label}

                            {selectedValue === option.value}
                        </label>
                    </div>
                )
            })}
        </div>
    );
};

export default SingleSelectCheckbox;
