import * as Yup from "yup";
import { IDashboardAdmin } from "../../../types/dashboard/admin/Admin";

export const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
};

export const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email address is required"),
});

export const generatePayload = (values: IDashboardAdmin) => {

  const payload = {
    first_name: values.first_name,
    last_name: values.last_name,
    email: values.email
  };

  return payload;
};
