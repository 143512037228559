import { Box } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';

interface ICustomTextFieldProps {
    onFilterClick?: (filterFlag: boolean) => void
    dropRef?: React.RefObject<HTMLDivElement> | null
}

const CustomTextField = ({ onFilterClick, dropRef }: ICustomTextFieldProps) => {

    const [isClicked, setIsClicked] = useState(false);
    const ref = useRef<any>(null);

    useEffect(() => {

        const handleClickOutside = (event: any) => {
            let check1 = dropRef && dropRef.current && !dropRef.current?.contains(event.target)
            let check2 = ref && ref.current && !ref.current?.contains(event.target)
            if (check1 && check2) {
                onFilterClick && onFilterClick(false)
                setIsClicked(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onFilterClick, dropRef]);

    const handleClick = () => {
        onFilterClick && onFilterClick(!isClicked)
        setIsClicked(!isClicked);
    };

    return (
        <Box
            ref={ref}
            tabIndex={0}
            onClick={handleClick}
            sx={{
                padding: '10px',
                paddingLeft: "15px",
                border: isClicked ? '2px solid var(--PrimaryBrandColour)' : '1px solid rgba(0, 0, 0, 0.23)',
                borderRadius: '4px',
                cursor: 'pointer',
                position: 'relative',
                background: 'var(--ThemeWhite)',
                "& >input": {
                    "&::placeholder": {
                        color: 'var(--Gray)',
                    }
                }
            }}
        >
            <input
                type="text"
                style={{
                    border: 'none',
                    outline: 'none',
                    width: '100%',
                    fontWeight: '400',
                    fontSize: '1rem',
                    paddingRight: '20px',
                    cursor: 'pointer',
                    background: 'var(--ThemeWhite)',
                }}
                placeholder='Filter'
                disabled={true}
            />

            <Box className='icon' sx={{
                position: 'absolute',
                top: '50%',
                translate: '0 -50%',
                right: '10px',
                height: '18px'
            }}>
                <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5 1C10.6716 1 10 1.67157 10 2.5C10 3.32843 10.6716 4 11.5 4C12.3284 4 13 3.32843 13 2.5C13 1.67157 12.3284 1 11.5 1ZM9.05001 2C9.28164 0.85888 10.2905 0 11.5 0C12.7095 0 13.7184 0.85888 13.95 2H16V3H13.95C13.7184 4.14112 12.7095 5 11.5 5C10.2905 5 9.28164 4.14112 9.05001 3H0V2H9.05001ZM4.5 6C3.67157 6 3 6.67157 3 7.5C3 8.32843 3.67157 9 4.5 9C5.32843 9 6 8.32843 6 7.5C6 6.67157 5.32843 6 4.5 6ZM2.05001 7C2.28164 5.85888 3.29052 5 4.5 5C5.70948 5 6.71836 5.85888 6.94999 7H16V8H6.94999C6.71836 9.14112 5.70948 10 4.5 10C3.29052 10 2.28164 9.14112 2.05001 8H0V7H2.05001ZM11.5 11C10.6716 11 10 11.6716 10 12.5C10 13.3284 10.6716 14 11.5 14C12.3284 14 13 13.3284 13 12.5C13 11.6716 12.3284 11 11.5 11ZM9.05001 12C9.28164 10.8589 10.2905 10 11.5 10C12.7095 10 13.7184 10.8589 13.95 12H16V13H13.95C13.7184 14.1411 12.7095 15 11.5 15C10.2905 15 9.28164 14.1411 9.05001 13H0V12H9.05001Z" fill="#9E9E9E" />
                </svg>
            </Box>
        </Box>
    );
};

export default CustomTextField;
