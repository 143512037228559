import * as Yup from "yup";
import { SuperAdminUser } from "../../../types/permission/Permissions";

export const getInitialValues = (data: any, roleList: any) => {

  let teams, roles;
  if (data?.user_contact?.team_contacts?.length > 0) {
    teams = data?.user_contact?.team_contacts?.map((item: any) => {
      return {
        id: item.team.id,
        name: item.team.name,
      };
    });
  }

  if (roleList) {
    roles = roleList.find(((option: any) =>
      option.id === data?.user_roles?.[0]?.role_id));
  }

  const values = {
    first_name: data?.first_name || '',
    last_name: data?.last_name || '',
    company_name: data?.user_accounts?.[0]?.account?.company_name || '',
    email: data?.email || '',
    mobile: data?.mobile || '',
    roles: roles || "",
    teams: teams || [],
    status: data?.is_sender_verified || false,

  };
  return values;
};

export const validationSchema = Yup.object().shape({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  company_name: Yup.string().required("Company name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email address is required"),
  mobile: Yup.string().required("Phone number is required"),
  roles: Yup.mixed().required("Role is required"),
  teams: Yup.array().min(1, "At least one team must be selected"),
  status: Yup.string().required("Status is required"),
});

export const generatePayload = (data: Partial<SuperAdminUser> | null) => {

  const payload = {
    first_name: data?.first_name || '',
    last_name: data?.last_name || '',
    company_name: data?.company_name || '',
    email: data?.email || '',
    mobile: data?.mobile || '',
    role: data?.roles?.id ? parseInt(data?.roles?.id) : data?.roles,
    is_sender_verified: data?.status || false,
  };

  return payload;
};