import { Box, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { MenuText } from "../../../../components/commonStyled/Typography";
import InputField from "../../../../components/input-field/InputField";
import CTAButton from "../../../../components/button/CTAButton";
import { SuperAdminUser } from "../../../../types/permission/Permissions";
import { generatePayload, getInitialValues, validationSchema } from "../helper";
import SingleSelect from "../../../../components/single-select/SingleSelect";
import MultiSelect from "../../../../components/multi-select/MultiSelect";
import {
  getRoles,
  getTeamListByAccountId,
  getTeamMemberById,
  updateTeamMemberDetails,
} from "../../../../services/SuperAdmin";

interface List {
  id: number;
  name: string;
}

interface IUsersEditDetailProps {
  updateTeamList: () => void;
  closeModal: () => void;
}

const TeamMembersEditDetail = ({
  updateTeamList,
  closeModal,
}: IUsersEditDetailProps) => {
  const mainFormRef = useRef<any>(null);
  const [teamMemberDetails, setTeamMemberDetails] =
    useState<Partial<SuperAdminUser | null>>(null);
  const [rolesList, setRolesList] = useState<List[]>([]);
  const [teamsList, setTeamsList] = useState<List[]>([]);
  const [teamsCount, setTeamsCount] = useState<number>(0);

  const teamMemberId = useMemo(() => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    const _id = searchParams.get("id");
    return _id;
  }, []);

  const assignValues = useCallback(
    (name: string) => (values: any) => {
      mainFormRef.current.setFieldValue(name, values.value);
    },
    []
  );

  const assignMultipleValue = useCallback(
    (name: string) => (value: any) => {
      mainFormRef.current.setFieldValue(name, value);
    },
    []
  );

  const initialValues = useMemo(() => {
    return getInitialValues(teamMemberDetails, rolesList);
  }, [teamMemberDetails, rolesList]);

  const roleInitialValue = useMemo(() => {
    const _temp = rolesList.find(
      (option: any) => option.id === teamMemberDetails?.user_roles?.[0]?.role_id
    );
    return _temp;
  }, [rolesList, teamMemberDetails?.user_roles]);

  const fetchTeamMemberById = useCallback(async (id: string) => {
    try {
      const result = await getTeamMemberById(id);
      if (result.data.data) {
        setTeamMemberDetails(result.data.data);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }, []);

  const fetchTeams = useCallback(async () => {
    const query = {
      select: "id,name",
    };
    if (teamMemberDetails?.user_accounts?.[0]?.account_id) {
      try {
        const result = await getTeamListByAccountId(
          teamMemberDetails?.user_accounts?.[0]?.account_id,
          query
        );
        if (result.data.data) {
          setTeamsList(result.data.data.rows);
          setTeamsCount(result.data.data.count);
        }
      } catch (error) {
        console.log("error: ", error);
      }
    }
  }, [teamMemberDetails?.user_accounts]);

  const fetchMoreSecondary = useCallback(
    async (pageNumber: number) => {
      const query = {
        page: pageNumber,
        select: "id,name",
      };
      if (teamMemberDetails?.user_accounts?.[0]?.account_id) {
        try {
          const result = await getTeamListByAccountId(
            teamMemberDetails?.user_accounts?.[0]?.account_id,
            query
          );
          if (result.data.data) {
            setTeamsList((prev) => [...prev, ...result.data?.data?.rows]);
          }
        } catch (error) {
          console.log("error: ", error);
        }
      }
    },
    [teamMemberDetails?.user_accounts]
  );

  const fetchRoles = useCallback(async () => {
    if (teamMemberDetails?.user_contact?.account_id) {
      try {
        const query = {
          select: "id,name",
        };
        const result = await getRoles(
          teamMemberDetails?.user_contact?.account_id,
          query
        );
        if (result.data.data) {
          setRolesList([...result.data.data.rows]);
        }
      } catch (error) {
        console.log("error: ", error);
      }
    }
  }, [teamMemberDetails?.user_contact?.account_id]);

  const handleUpdateUser = useCallback(
    async (values: Partial<SuperAdminUser>) => {
      const removed_teams: any = [];
      const new_teams: any = [];
      let data = values?.teams;

      if (teamMemberDetails?.user_contact) {
        for (const item of teamMemberDetails?.user_contact?.team_contacts) {
          const teamId = item.team_id;
          // const matchesV1 = data.some((item: any) => item?.value || item?.id === teamId);
          let matches;
          if (data?.length > 0 && data?.[0].hasOwnProperty("value")) {
            matches = data.some((team: any) => team?.value === teamId);
          }
          if (data?.length > 0 && data?.[0].hasOwnProperty("id")) {
            matches = data.some((team: any) => team?.id === teamId);
          }

          if (!matches) {
            removed_teams.push(teamId);
          }
        }
        for (const item of data) {
          const teamId = item.value || item?.id;
          const matches = teamMemberDetails?.user_contact?.team_contacts.some(
            (item) => item.team_id === teamId
          );

          if (!matches) {
            new_teams.push(teamId);
          }
        }
      } else {
        for (const item of data) {
          const teamId = item.value || item?.id;
          new_teams.push(teamId);
        }
      }

      if (teamMemberId) {
        try {
          const _payload = generatePayload(values);
          const payload = {
            ..._payload,
            removed_teams,
            new_teams,
          };
          const result = await updateTeamMemberDetails(payload, teamMemberId);
          if (result.data.data) {
            updateTeamList();
            closeModal();
          }
        } catch (error) {
          console.log("error: ", error);
        }
      }
    },
    [teamMemberDetails?.user_contact, teamMemberId, closeModal, updateTeamList]
  );

  const handleSecondaryTeamList = useCallback(async () => {
    fetchTeams();
  }, [fetchTeams]);

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  useEffect(() => {
    if (teamMemberId) fetchTeamMemberById(teamMemberId);
  }, [fetchTeamMemberById, teamMemberId]);

  useEffect(() => {
    fetchTeams();
  }, [fetchTeams]);

  return (
    <Box
      sx={{
        padding: "30px 30px 15px",
        marginTop: "30px",
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdateUser}
        enableReinitialize={true}
      >
        {(formik) => {
          mainFormRef.current = formik;
          return (
            <Form>
              <Grid container spacing={{ xs: 4, sm: 6 }}>
                <Grid item xs={12} lg={8}>
                  <Grid container spacing={{ xs: 4, sm: 6 }}>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className="form-label-typography">
                          <MenuText>First Name</MenuText>
                        </Typography>
                        <InputField name="first_name" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className="form-label-typography">
                          <MenuText>Last Name</MenuText>
                        </Typography>
                        <InputField name="last_name" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className="form-label-typography">
                          <MenuText>Company</MenuText>
                        </Typography>
                        <InputField name="company_name" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className="form-label-typography">
                          <MenuText>Email Address</MenuText>
                        </Typography>
                        <InputField name="email" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className="form-label-typography">
                          <MenuText>Phone Number</MenuText>
                        </Typography>
                        <InputField name="mobile" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className="form-label-typography">
                          <MenuText>Role</MenuText>
                        </Typography>
                        <SingleSelect
                          name="roles"
                          selected={roleInitialValue}
                          onChange={assignValues("roles")}
                          options={rolesList}
                          labelKey="name"
                          valueKey="id"
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>
                        <Typography className="form-label-typography">
                          <MenuText>Teams</MenuText>
                        </Typography>
                        <MultiSelect
                          selected={formik.values.teams}
                          name="teams"
                          icon="DROPDOWN"
                          placeholder="Select Team"
                          onChange={assignMultipleValue("teams")}
                          options={teamsList}
                          onFocus={handleSecondaryTeamList}
                          labelKey={"name"}
                          valueKey={"id"}
                          loadMore={fetchMoreSecondary}
                          totalCount={teamsCount}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box sx={{ marginTop: { xs: 4 }, marginBottom: { xs: 2 }, textAlign: "center" }}>
                        <CTAButton
                          text="Update"
                          varient="Primary"
                          type="submit"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};

export default TeamMembersEditDetail;
