import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ModalInterface {
  openTeamModal: boolean;
  isCreateTeam: boolean;
  isCSVUpload: boolean;
  uploadCount: number;
}

const initialState = {
  openTeamModal: false,
  isCreateTeam: false,
  isCSVUpload: false,
  uploadCount: 0,
};

export const authSlice = createSlice({
  name: "teamModal",
  initialState,
  reducers: {
    setOpenTeamModal: (state: any, action: PayloadAction<boolean>) => {
      state.openTeamModal = action.payload;
    },
    setIsCreateTeam: (state: any, action: PayloadAction<boolean>) => {
      state.isCreateTeam = action.payload;
    },
    setIsCSVUpload: (state: any, action: PayloadAction<boolean>) => {
      state.isCSVUpload = action.payload;
    },
    setUploadCount: (state: any, action: PayloadAction<number>) => {
      state.uploadCount = action.payload;
    },
  },
});

export const getOpenModalValue = (state: { modal: ModalInterface }) =>
  state.modal.openTeamModal;

export const getCreateTeamValue = (state: { modal: ModalInterface }) =>
  state.modal.isCreateTeam;

export const getIsCSVUpload = (state: { modal: ModalInterface }) =>
  state.modal.isCSVUpload;

export const getUploadCount = (state: { modal: ModalInterface }) =>
  state.modal.uploadCount;

export const getAllTeamModal = (state: { modal: ModalInterface }) =>
  state.modal;

export const { actions, reducer } = authSlice;

export const {
  setIsCreateTeam,
  setOpenTeamModal,
  setIsCSVUpload,
  setUploadCount,
} = actions;

export default reducer;
