import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { RootState } from "../../redux/Store";

interface IAuthProvider {
  children: JSX.Element;
}

const AuthProvider = ({
  children,
}: IAuthProvider) => {
  // ** Hooks **
  const authData = useSelector((state: RootState) => state.auth);
  const adminToken = useSelector((state: RootState) => state.superAdmin.token);

  const { isAuthenticated } = authData;
  // ** Not Logged In **
  if (window.location.pathname.includes('admin/')) {
    if (!adminToken) {
      return <Navigate to={'admin/login'} />;
    }
  } else {
    if (!isAuthenticated) {
      return <Navigate to={'/login'} />;
    }
  }

  return children;
};

export default AuthProvider;