import React, { useState } from "react";
import {
  Popper,
  Paper,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Box,
} from "@mui/material";
import SingleSelectCheckbox from "./CustomCheckbox";
import { IconFilterSlider } from "../icons";

interface CheckboxOption {
  label: string;
  value: string;
}

interface CustomFilterProps {
  name: string;
  handleFilterValue?: (selectedValue: string) => void;
  options: CheckboxOption[];
  filterValue?: string;
}

const CustomFilter: React.FC<CustomFilterProps> = ({
  name,
  handleFilterValue,
  options,
  filterValue,
}: CustomFilterProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [selectedValue, setSelectedValue] = useState<string>("");

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleOptionClick = (value: string) => {
    setSelectedValue(value);
    setAnchorEl(null);
    if (handleFilterValue) {
      handleFilterValue(value);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? "filter-popper" : undefined;

  return (
    <Box
      sx={{ display: "flex", justifyContent: "flex-end", position: "relative" }}
    >
      <OutlinedInput
        name={name}
        placeholder="Filter"
        value={selectedValue}
        onClick={handleClick}
        sx={{
          background : 'var(--ThemeWhite) !important',
          borderColor: 'borderColor: "rgba(0, 0, 0, 0.23)',
          width: "100%",
          input: {
            padding: "10.5px 14px",
          },
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton aria-label="toggle password visibility" edge="end">
              <IconFilterSlider />
            </IconButton>
          </InputAdornment>
        }
      />
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        style={{ zIndex: 1000, marginTop: 2, width: "275px" }}
        placement="bottom"
      >
        <Paper style={{ padding: 2 }}>
          <Typography variant="body1">Select One</Typography>
          <SingleSelectCheckbox
            options={options}
            filterValue={filterValue}
            onChange={handleOptionClick}
          />
        </Paper>
      </Popper>
    </Box>
  );
};

export default CustomFilter;
