import React, { useState } from 'react';
import { Box, Pagination, Stack, Typography } from '@mui/material';
import NextIcon from '../vector-icons/NextIcon';
import { ChartText } from '../commonStyled/Typography';
import PreviousIcon from '../vector-icons/PreviousIcon';

interface PaginationComponentProps {
  count: number;
  currentPage: number;
  limit: number;
  color?: string;
  buttonColor?: string
  onChange: (page: number) => void;
}

const PaginationComponent: React.FC<PaginationComponentProps> = ({
  count,
  currentPage,
  limit,
  color,
  buttonColor,
  onChange,
}) => {

  const [totalPages, setTotalPages] = useState(0);

  const handleChange = (_: React.ChangeEvent<unknown>, page: number) => {
    onChange(page);
  };

  const handlePreviousPage = () => {
    onChange(currentPage - 1);
  };

  const handleNextPage = () => {
    onChange(currentPage + 1);
  };

  React.useEffect(() => {
    const calculatedTotalPages = Math.ceil(count / limit);
    setTotalPages(calculatedTotalPages);
  }, [count, limit]);


  const showPreviusButton = currentPage === 1;
  const showNextButton = currentPage === totalPages;

  return (
    <Stack direction="row" spacing={2} display={'flex'} flexDirection={'column'}>
      <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
        {!showPreviusButton &&
          <Box onClick={handlePreviousPage} display={'flex'} sx={{ cursor: 'pointer' }} >
            <PreviousIcon
              style={{ cursor: 'pointer', color: "var(--PrimaryBlue)" }}
              disabled={currentPage === 1}
            />
            <Typography variant="body2" color={"var(--PrimaryBlue)"}>
              Previous
            </Typography>
          </Box>
        }
        <Pagination
          count={totalPages}
          page={currentPage}
          onChange={handleChange}
          shape='circular'
          hideNextButton={true}
          hidePrevButton={true}
          boundaryCount={2}
          className='pagination-btn'
          sx={{
            '& .Mui-focused': {
              outline: 'none'
            },
          }}

        />
        {!showNextButton &&
          <Box onClick={handleNextPage} display={'flex'} sx={{ cursor: 'pointer' }}>
            <Typography variant="body2" color="var(--PrimaryBlue)">
              More
            </Typography>
            <NextIcon
              style={{ cursor: 'pointer', color: "var(--PrimaryBlue)" }}
              disabled={currentPage === totalPages}
            />
          </Box>
        }
      </Box>
      <Box sx={{ marginLeft: '0 !important', textAlign: 'center' }}>
        <ChartText dBlock mt="6px" className="text-Gray">
          Page {currentPage} of {totalPages}
        </ChartText>
      </Box>

    </Stack>
  );
};

export default PaginationComponent;
