import { ReactNode, useState } from "react";
import { ErrorMessage, useField } from "formik";
import { Box, TextareaAutosize, Typography } from "@mui/material";
interface ITextarea {
  type?: string;
  name: string;
  label?: string;
  onChange?: (val: string) => void
  placeholder?: string;
  disabled?: boolean;
  margin?: "dense" | "normal" | "none";
  extraElement?: ReactNode;
  size?: "small" | "medium";
  isValid?: boolean
}

const Textarea = ({
  type,
  name,
  label,
  placeholder,
  disabled,
  margin,
  size,
  isValid = false,
  onChange
}: ITextarea) => {

  const [focused, setFocused] = useState<boolean>(false);
  const [field] = useField(name);
  const onBlur = () => setFocused(false);
  const onFocus = () => setFocused(true);

  return (
    <>
      <Box
        sx={{
          ":not(lastchild)": {
          },
        }}
      >
        <TextareaAutosize
          placeholder={focused || field.value || placeholder}
          minRows={4}
          {...field}
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled ? disabled : false}

          // '& .Mui-focused': {
          //   'fieldset': {
          //     borderColor: isValid ? 'red' : 'var(--PrimaryBlue) !important',
          //     borderRadius: '10px',
          //     // background: 'red'
          //   },

          // },
          // '& fieldset': {
          //   borderRadius: '10px',
          //   borderColor: isValid ? 'red' : 'var(--Gray) !important',
          //   // borderColor: isValid ? 'red' : '',
          // }
        />
        <ErrorMessage name={name}>
          {(msg) => (
            <Typography
              style={{
                color: "red",
                textAlign: "left",
                fontSize: "var(--text14)",
                fontWeight: "500",
                fontFamily: "var(--bodyFont)",
                marginTop: "2px",
              }}
            >
              {msg}
            </Typography>
          )}
        </ErrorMessage>
      </Box>
    </>
  );
};

export default Textarea;
