import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PlanModalInterface {
  planAccountError: boolean;
  adminModalOpen: boolean;
  userModalOpen: boolean;
  teamModalOpen: boolean;
  teamMemberOpen: boolean;
  planDetailLimits: Array<any>;
}

const initialState = {
  planAccountError: false,
  adminModalOpen: false,
  userModalOpen: false,
  teamModalOpen: false,
  teamMemberOpen: false,
};

export const planModalSlice = createSlice({
  name: 'planModal',
  initialState,
  reducers: {
    setIsPlanAccountError: (state: any, action: PayloadAction<boolean>) => {
      state.planAccountError = action.payload;
    },
    setIsAdminModal: (state: any, action: PayloadAction<boolean>) => {
      state.adminModalOpen = action.payload;
    },
    setIsUserModal: (state: any, action: PayloadAction<boolean>) => {
      state.userModalOpen = action.payload;
    },
    setIsTeamModal: (state: any, action: PayloadAction<boolean>) => {
      state.teamModalOpen = action.payload;
    },
    setIsTeamMemberModal: (state: any, action: PayloadAction<boolean>) => {
      state.teamMemberOpen = action.payload;
    },
    setPlanDetailLimit: (state: any, action: PayloadAction<any>) => {
      state.planDetailLimits = action.payload;
    },
  },
});

export const getPlanModal = (state: { planModal: PlanModalInterface }) => state.planModal;
export const getPlanLimit = (state: { planModal: PlanModalInterface }) => state.planModal.planDetailLimits;

export const { actions, reducer } = planModalSlice;

export const {
  setIsAdminModal,
  setIsUserModal,
  setIsTeamMemberModal,
  setIsTeamModal,
  setIsPlanAccountError,
  setPlanDetailLimit,
} = actions;

export default reducer;
