import React from 'react';

interface INextIcons {
    onClick?: () => void,
    style?: React.CSSProperties;
    disabled?: boolean
}

const NextIcon = ({ onClick, style, disabled }: INextIcons) => {
    return disabled ? null : (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" onClick={onClick} style={style}>
            <path d="M0 0h24v24H0z" fill="none" />
            <path
                fill="currentColor"
                d="M8.59 16.34L13.17 12 8.59 7.66a.996.996 0 1 1 1.41-1.41l5.66 5.66c.39.39.39 1.02 0 1.41l-5.66 5.66a.996.996 0 1 1-1.41-1.41z"
            />
        </svg>
    );
}

export default NextIcon;