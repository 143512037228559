import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Accounts, IUser } from "../../types/user/User";
import { IPrimaryTeams, IRoles } from "../../types/admin/Admin";

export interface AuthInterface {
  token: null | string;
  orguuid: null | string;
  isAuthenticated: boolean;
  userDetails: null | IUser;
  accountDetails: null | Accounts;
  roles: null | IRoles[];
  primaryTeams: null | IPrimaryTeams[];
}

const initialState = {
  token: null,
  orguuid: null,
  isAuthenticated: false,
  userDetails: {},
  accountDetails: {},
  roles: [],
  primaryTeams: [],
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAccessToken: (state: any, action: PayloadAction<string>) => {
      state.token = action.payload;
      state.isAuthenticated = true;
    },

    setOrguuid: (state: any, action: PayloadAction<string>) => {
      state.orguuid = action.payload;
    },

    setUserDetails: (state: any, action: PayloadAction<string>) => {
      state.userDetails = action.payload;
      state.userDetails.isFirstTime = true;
    },

    setAccountDetails: (state: any, action: PayloadAction<object>) => {
      state.accountDetails = {
        ...state.accountDetails,
        ...action.payload,
      };
    },

    setIsFirstTime: (state: any, action: PayloadAction<boolean>) => {
      state.userDetails.isFirstTime = false;
    },

    setRoles: (state: any, action: PayloadAction<Array<IRoles>>) => {
      state.roles = action.payload;
    },

    setPrimaryTeams: (
      state: any,
      action: PayloadAction<Array<IPrimaryTeams>>
    ) => {
      state.primaryTeams = action.payload;
    },

    setLogoutData(state: any) {
      state.token = null;
      state.orguuid = null;
      state.isAuthenticated = false;
      state.userDetails = null;
      state.roles = [];
      state.primaryTeams = [];
    },
  },
});

export const getAuthData = (state: { auth: AuthInterface }) => state.auth;

export const tokenSelector = (state: { auth: AuthInterface }) =>
  state.auth.token;

export const getRolesData = (state: { auth: AuthInterface }) =>
  state.auth.roles;

export const getPrimaryData = (state: { auth: AuthInterface }) =>
  state.auth.primaryTeams;

export const userDetails = (state: { auth: AuthInterface }) =>
  state.auth.userDetails;

export const getAccountDetails = (state: { auth: AuthInterface }) =>
  state.auth.accountDetails;

export const { actions, reducer } = authSlice;

export const {
  setAccessToken,
  setOrguuid,
  setRoles,
  setLogoutData,
  setUserDetails,
  setPrimaryTeams,
  setIsFirstTime,
  setAccountDetails,
} = actions;

export default reducer;
