import { axiosGet, axiosPost, axiosPut } from '../axios/config';

export const signup = (data: object) => {
  return axiosPost('auth/register', data);
};

export const login = (data: object) => {
  return axiosPost('auth/login', data);
};

export const verifyAccount = (data: object) => {
  return axiosPost('auth/verify-account-by-email', data);
};

export const forgotPassword = (data: object) => {
  return axiosPost('auth/forgot-password', data);
};

export const resetPassword = (data: object) => {
  return axiosPost('auth/reset-password', data);
};

export const changePassword = (data: object) => {
  return axiosPost('auth/change-password', data);
};

export const getPermissionDetails = () => {
  return axiosGet('user/logged-in-user');
};

export const getPaymentCheckOutSession = (data = {}) => {
  return axiosPost('auth/create-checkout-session', data);
};

export const updateProfile = (data: object, userId: number) => {
  return axiosPut(`user/${userId}`, data);
};

export const getBillingInvoice = (data: object = {}) => {
  return axiosPost(`auth/create-portal-session`, data);
};

export const sendVerificationEmail = (data: object = {}) => {
  return axiosPost('auth/send-sender-verification-email', data);
};

export const verifyEmail = (data: object = {}) => {
  return axiosPost('auth/verify-sender-identity', data);
};

export const getClientSecretKey = (data: object = {}) => {
  return axiosPost('auth/create-customer-session', data);
};

export const getPlanDetails = (params: object = {}) => {
  return axiosGet(`account/get-account-plan-details`);
};

export const getPlanLimitDetail = (params: object = {}) => {
  return axiosGet(`account/get-account-plan-limit-details`);
};


