import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CardComponent } from '../../../components/card/CardComponent';
import ModalView from '../../../components/modal/Modal';
import MiovaAdminTable from './components/MiovaAdminTable';
// import MiovaAdminEditDetail from './components/MiovaAdminCreateDetails';
import { getAdminList, removeAdminById, resetPasswordDashboard } from '../../../services/SuperAdmin';
import { IDashboardAdminList } from '../../../types/dashboard/admin/Admin';
import { useSelector } from 'react-redux';
import { getDashboardModal, setIsCreateAdmin } from '../../../redux/slices/DashboardModalSlice';
import { useDispatch } from 'react-redux';
import MiovaAdminCreateDetails from './components/MiovaAdminCreateDetails';

const MiovaAdmin = () => {

  const dashboardModals = useSelector(getDashboardModal);
  const dispatch = useDispatch();

  const [adminList, setAdminList] = useState<IDashboardAdminList[]>([]);
  const [adminCount, setAdminCount] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const handleView = useCallback(async (email: string) => {
    try {
      const payload = {
        email
      };
      await resetPasswordDashboard(payload);

    } catch (error) {
      console.log("error: ", error);
    }
  }, []);

  const handleMiovaAdminModalClose = () => {
    dispatch(setIsCreateAdmin(false));
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const query = useMemo(() => {
    let _query = {
      limit: 10,
      page: currentPage
    };
    return _query;
  }, [currentPage,]);

  const fetchAdminList = useCallback(async () => {
    try {
      const result = await getAdminList(query);
      if (result.data.data) {
        setAdminList(result.data.data.rows);
        setAdminCount(result.data.data?.count);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }, [query]);

  const handleDeleteAdmin = useCallback(async (id: number) => {
    try {
      const result = await removeAdminById(id);
      if (result.data.data) {
        fetchAdminList();
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }, [fetchAdminList]);

  useEffect(() => {
    fetchAdminList();
  }, [fetchAdminList]);

  return (
    <>
      <CardComponent
        title="Miova Admins"
      >
        <MiovaAdminTable
          data={adminList}
          handleReset={handleView}
          handleAdminDelete={handleDeleteAdmin}
          count={adminCount}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      </CardComponent>
      <ModalView Height='60dvh' open={dashboardModals?.createAdminModal} closeModal={handleMiovaAdminModalClose} >
        <MiovaAdminCreateDetails updateList={fetchAdminList} />
      </ModalView>
    </>
  );
};

export default MiovaAdmin;