import { Box, Card, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Anchor, MediumText } from "../../../../components/commonStyled/Typography";
import { ICompanyList } from "../../../../types/dashboard/company/Company";
import { getFormattedDate } from "../../../../utils/Index";
import PaginationComponent from "../../../../components/pagination/PaginationComponent";


interface ICompanyTable {
  data: ICompanyList[] | null;
  modelType?: string | null,
  count?: number;
  currentPage?: number,
  handleView?: (id: number) => void;
  handleTier?: (id: number) => void;
  handlePageChange?: (value: number) => void;
}

const CompanyTable = ({ data, handleView, handlePageChange, count, currentPage, handleTier }: ICompanyTable) => {

  const teamLength = data?.length;

  const handleViewClick = (id: number) => (e: React.MouseEvent<HTMLAnchorElement> | undefined): void => {
    if (handleView)
      handleView(id);
  };

  const handleTierClick = (id: number) => (e: React.MouseEvent<HTMLAnchorElement> | undefined): void => {
    if (handleTier)
      handleTier(id);
  };

  const handlePage = (page: number) => {
    if (handlePageChange)
      handlePageChange(page);
  };


  return (
    <Card
      sx={{
        width: "100%",
        boxShadow: "none",
        borderRadius: "20px",
        backgroundColor: "var(--White)",
        padding: "19px 23px",
      }}
    >
      <CardContent
        sx={{
          padding: "0 !important",
          marginTop: "0",
        }}
      >
        <TableContainer sx={{ paddingBottom: '10px' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead
              sx={{
                th: {
                  borderBottom: "1px solid var(--PurpleExtraLight)",
                  fontFamily: "var(--headerFont)",
                  fontSize: " 1rem",
                  lineHeight: "1.25rem",
                  fontWeight: "600",
                  color: 'var(--PrimaryBlue)',
                  padding: "16px 0",
                  "&:not(:first-of-type)": {
                    paddingLeft: "10px",
                  },
                },
              }}
            >
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Date Created</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row) => {
                return (
                  <TableRow
                    key={row.id}
                    sx={{
                      td: {
                        fontFamily: "var(--bodyFont)",
                        fontSize: " 0.75rem",
                        lineHeight: "1.25rem",
                        borderBottom: "1px solid var(--PurpleExtraLight)",
                        padding: "16px 0",
                        "&:not(:first-of-type)": {
                          paddingLeft: "10px",
                        },
                      },
                    }}
                  >
                    <TableCell>{row.company_name || '-'}</TableCell>
                    <TableCell>{(row.created_at && getFormattedDate(row.created_at)) || '-'}</TableCell>
                    <TableCell>
                      <Anchor sx={{ color: 'var(--PrimaryBlue)' }} onClick={handleViewClick(row.id)}>
                        <MediumText> Edit Details </MediumText>
                      </Anchor>
                      {` / `}
                      <Anchor sx={{ color: 'var(--PrimaryBlue)' }} onClick={handleTierClick(row.id)}>
                        <MediumText> Edit Tier </MediumText>
                      </Anchor>
                    </TableCell>
                  </TableRow>
                );
              })}
              {teamLength === 0 && (
                <TableRow
                  sx={{
                    td: {
                      addAsAdmin: "all 0.3s",
                      borderBottom: "1px solid var(--PurpleExtraLight)",
                      padding: "16px 0",
                      "&:not(:first-of-type)": {
                        paddingLeft: "10px",
                      },
                    },
                    position: "relative",
                    zIndex: "9",
                  }}
                >
                  <TableCell colSpan={4} align="center">
                    No data Available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            marginTop: "20px",
            fontFamily: "var(--bodyFont)",
            textAlign: "center",
            td: {
              fontSize: " 0.75rem",
              lineHeight: "1.25rem",
              transition: "all 0.3s",
              borderBottom: "1px solid var(--PurpleExtraLight)",
              padding: "16px 0",
              "&:not(:first-of-type)": {
                paddingLeft: "10px",
              },
            },
            position: "relative",
            zIndex: "9",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'

          }}
        >
          {(teamLength && teamLength > 0) ?
            <PaginationComponent
              count={count || 10}
              limit={10}
              currentPage={currentPage || 1}
              onChange={handlePage}
            />
            : null
          }
        </Box>
      </CardContent>
    </Card>
  );
};

export default CompanyTable;;