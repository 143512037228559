import { Backdrop, Box, Fade, Grid, Modal } from "@mui/material";
import { Anchor, CardTitleSmall } from "../commonStyled/Typography";

interface IModalView {
  open: boolean;
  modalTitle?: string;
  closeModal: VoidFunction;
  children: JSX.Element;
  MaxWidth?: string;
  Height?: string;
  hideCloseButton?: boolean;
  disableEscapeKeyDown?: boolean;
}

const ModalView = ({
  open,
  closeModal,
  children,
  modalTitle,
  MaxWidth,
  Height,
  hideCloseButton,
  disableEscapeKeyDown,
}: IModalView) => {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={closeModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
        disableEscapeKeyDown={disableEscapeKeyDown}
        BackdropProps={{
          onClick: (e) => disableEscapeKeyDown && e.stopPropagation(),
        }}
        sx={{ maxWidth: "90vw", marginLeft: "auto", marginRight: "auto" }}
      >
        <Fade in={open}>
          <Box
            maxWidth={`${MaxWidth ? MaxWidth : "1290px"}`}
            maxHeight={`${Height ? Height : "85dvh"}`}
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "100%",
              // maxWidth: 1290,
              backgroundColor: "var(--ThemeWhite)",
              boxShadow: "0px 33px 62px rgba(176, 183, 195, 0.239216)",
              borderRadius: "15px",
              overflow: "auto",
              // height: `${Height ? Height : "85dvh"}`,
            }}
          >
            {hideCloseButton ? (
              ""
            ) : (
              <Box
                sx={{
                  position: "absolute",
                  right: "20px",
                  top: "12px",
                }}
              >
                <Anchor
                  sx={{ color: "var(--PrimaryBlue)" }}
                  onClick={closeModal}
                >
                  close window
                </Anchor>
              </Box>
            )}
            {modalTitle && (
              <Box
                sx={{
                  padding: "30px 30px 15px",
                }}
              >
                <Grid container spacing={0}>
                  <Grid xs={10}>
                    <CardTitleSmall sx={{ color: "var(--PrimaryBlue)" }}>
                      {modalTitle}
                    </CardTitleSmall>
                  </Grid>
                  <Grid
                    xs={2}
                    sx={{ textAlign: "right", height: "fit-content" }}
                  ></Grid>
                </Grid>
              </Box>
            )}
            {children}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ModalView;
