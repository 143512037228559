import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { useSelector } from "react-redux";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { CardComponent } from "../../../components/card/CardComponent";
import { FormHeadText } from "../../../components/commonStyled/Typography";
import InputField from "../../../components/input-field/InputField";
import Textarea from "../../../components/textarea/Textarea";
import CTAButton from "../../../components/button/CTAButton";
import { billingInitialValues, generatePayloadBilling, updateBillingValidationSchema } from "../form-helper/FormHelper";
import data from '../../../utils/country.json'
import SingleSelect from "../../../components/single-select/SingleSelect";
import { updateAccount } from "../../../services/Admin";
import { setBillingDetails, theme } from "../../../redux/slices/SettingSlice";


interface IStates {
    label: string,
    value: string
}

const UpdateBilling = () => {

    const themes = useSelector(theme)
    const dispatch = useDispatch();

    const mainFormRef = useRef<any>(null)
    const [states, setStates] = useState<IStates[]>([])

    const initialValues = useMemo(() => {
        return billingInitialValues(themes.billingDetails)
    }, [themes.billingDetails])

    const assignValues = useCallback((name: string) => (value: any) => {
        mainFormRef.current.setFieldValue(name, value.value);
        if (name === 'country_billing') {
            const _selectedCountry = data?.countries.find((item) => item.country === value.value)
            const _options = _selectedCountry?.states?.map((item, index) => {
                return {
                    label: item,
                    value: item
                }
            })
            setStates(_options || [])
        }
    }, []);

    const handleUpdateProfile = useCallback(async (values: any) => {
        try {
            const payload = generatePayloadBilling(values)
            if (themes.billingDetails?.id) {
                const result = await updateAccount(payload, themes.billingDetails.id);
                if (result.data) {
                    dispatch(setBillingDetails(result.data?.data))
                }
            }
        } catch (error) {
            console.log("error: ", error);
        }
    }, [themes.billingDetails?.id, dispatch])

    const countryOptions = useMemo(() => {
        const _options = data?.countries.map(({ country }, index) => {
            return {
                label: country,
                value: country,
            }
        });
        return _options
    }, [])

    useEffect(() => {
        (async () => {
            let countryName = themes.billingDetails?.country;
            const _selectedCountry = data?.countries.find((item) => item.country === countryName)
            const _options = _selectedCountry?.states?.map((item) => {
                return {
                    label: item,
                    value: item
                }
            })
            setStates(_options || [])
        })()
    }, [themes.billingDetails?.country])

    return (
        <CardComponent title={'Edit Billing Details'}>
            <Card
                sx={{
                    width: "100%",
                    boxShadow: "none",
                    borderRadius: "20px",
                    backgroundColor: "var(--ThemeWhite)",
                    padding: "19px 23px",
                }}
            >
                <CardContent
                    sx={{
                        padding: "0 !important",
                        marginTop: "0",
                    }}
                >
                    <Formik
                        initialValues={initialValues}
                        validationSchema={updateBillingValidationSchema}
                        onSubmit={handleUpdateProfile}
                        enableReinitialize
                    >
                        {(formik) => {
                            mainFormRef.current = formik
                            return (
                                <Form>
                                    <Grid container sx={{}} spacing={{ xs: '24px', md: '48px', lg: '65px', xl: '96px' }} marginTop={'0 !important'}>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                paddingTop: "0 !important",
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        all: "initial",
                                                        // marginBottom: "12px",
                                                        display: "block",
                                                        fontFamily: "var(--Montserrat)",
                                                    }}
                                                >
                                                    <FormHeadText >Company Name</FormHeadText>
                                                </Typography>
                                                <InputField
                                                    size="small"
                                                    name="company_name"
                                                    placeholder="Company name"
                                                    margin="normal"
                                                // if(mainFormRef.current.e)
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                paddingTop: "0 !important",
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        all: "initial",
                                                        // marginBottom: "12px",
                                                        display: "block",
                                                        fontFamily: "var(--Montserrat)",
                                                    }}
                                                >
                                                    <FormHeadText>Billing Contact</FormHeadText>
                                                </Typography>
                                                <InputField
                                                    size="small"
                                                    name="billing_contact"
                                                    placeholder="Billing Contact"
                                                    margin="normal"
                                                    type="email"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                paddingTop: {
                                                    sm: "20px !important",
                                                    md: "28px !important",
                                                    lg: "34px !important",
                                                },
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        all: "initial",
                                                        // marginBottom: "12px",
                                                        display: "block",
                                                        fontFamily: "var(--Montserrat)",
                                                    }}
                                                >
                                                    <FormHeadText>Company Address</FormHeadText>
                                                </Typography>
                                                <Textarea
                                                    name="address1"
                                                    placeholder="Address 1"
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                paddingTop: {
                                                    sm: "20px !important",
                                                    md: "28px !important",
                                                    lg: "34px !important",
                                                },
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        all: "initial",
                                                        // marginBottom: "12px",
                                                        display: "block",
                                                        fontFamily: "var(--Montserrat)",
                                                    }}
                                                >
                                                    <FormHeadText>Country</FormHeadText>
                                                </Typography>

                                                <SingleSelect
                                                    name="country_billing"
                                                    options={countryOptions}
                                                    selected={formik.values?.country_billing}
                                                    onChange={assignValues("country_billing")}
                                                    labelKey={"label"}
                                                    valueKey={"value"}
                                                    variable={true}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                paddingTop: {
                                                    sm: "20px !important",
                                                    md: "28px !important",
                                                    lg: "34px !important",
                                                },
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        all: "initial",
                                                        display: "block",
                                                        fontFamily: "var(--Montserrat)",
                                                    }}
                                                >
                                                    <FormHeadText>State</FormHeadText>
                                                </Typography>
                                                <SingleSelect
                                                    name="state_billing"
                                                    selected={formik.values?.state_billing}
                                                    onChange={assignValues("state_billing")}
                                                    options={states}
                                                    labelKey={"label"}
                                                    valueKey={"value"}
                                                    variable={true}
                                                />
                                            </Box>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                paddingTop: {
                                                    sm: "20px !important",
                                                    md: "28px !important",
                                                    lg: "34px !important",
                                                },
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        all: "initial",
                                                        // marginBottom: "12px",
                                                        display: "block",
                                                        fontFamily: "var(--Montserrat)",
                                                    }}
                                                >
                                                    <FormHeadText>City</FormHeadText>
                                                </Typography>
                                                <InputField
                                                    size="small"
                                                    name="city"
                                                    placeholder="City"
                                                    margin="normal"
                                                />
                                            </Box>
                                        </Grid>


                                        <Grid
                                            item
                                            xs={12}
                                            md={6}
                                            sx={{
                                                paddingTop: {
                                                    sm: "20px !important",
                                                    md: "28px !important",
                                                    lg: "34px !important",
                                                },
                                            }}
                                        >
                                            <Box>
                                                <Typography
                                                    sx={{
                                                        all: "initial",
                                                        display: "block",
                                                        fontFamily: "var(--Montserrat)",
                                                    }}
                                                >
                                                    <FormHeadText>Zip Code</FormHeadText>
                                                </Typography>
                                                <InputField
                                                    size="small"
                                                    name="zip"
                                                    placeholder="Zip"
                                                    margin="normal"
                                                    type="number"
                                                />
                                            </Box>
                                        </Grid>

                                    </Grid>
                                    <Box sx={{ textAlign: 'center', display: 'flex', justifyContent: 'center', marginTop: "60px", gap: 15, marginBottom: "30px" }}>
                                        <CTAButton type="submit" varient="Primary" text="Update Billing" />
                                    </Box>
                                </Form>
                            )
                        }}

                    </Formik>
                </CardContent>
            </Card>

        </CardComponent>
    );
};

export default UpdateBilling;
