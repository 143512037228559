import { IAddAdminDetails } from "../../../types/admin/Admin";
import { Accounts, BillingDetail, IThemeState } from "../../../types/user/User";
import * as Yup from "yup";
import { removeEmptyValues } from "../../../utils/Index";

export const generateThemePayload = (data: IThemeState) => {
  const {
    primaryBrandColor,
    ctaButtonText,
    ctaButtonColor,
    headerFont,
    bodyFont,
    fontColor,
    backgroundColor,
  } = data;

  const payload = {
    primary_brand_color: primaryBrandColor,
    cta_button_color: ctaButtonColor,
    cta_text_color: ctaButtonText,
    font_color: fontColor,
    background_color: backgroundColor,
    header_font: headerFont,
    body_font: bodyFont,
  };
  return removeEmptyValues(payload);
};

export const addAdminInitialValues = {
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  primaryTeam: null,
  role: null,
};

export const billingInitialValues = (values: BillingDetail) => {
  //
  let _country, _state;
  if (values?.country) {
    _country = { label: values?.country, value: values.country };
  }
  if (values?.state) {
    _state = { label: values?.state, value: values?.state };
  }

  const _values = {
    company_name: values?.company_name || "",
    domain_address: values?.domain_address || "",
    address1: values?.address1 || "",
    city: values?.city || "",
    country_billing: _country,
    state_billing: _state,
    zip: values?.zip || "",
    billing_contact: values?.billing_contact || "",
  };
  return _values;
};

export const updateBillingValidationSchema = Yup.object().shape({
  company_name: Yup.string()
    .min(2, "Too short!")
    .max(70, "Too long!")
    .required("*Company name is required"),
  address1: Yup.string()
    .min(2, "Too short!")
    .max(70, "Too long!")
    .required("*Address is required"),
  country_billing: Yup.mixed().test("*Country is required", (value) => {
    return typeof value === "string" || typeof value === "object";
  }),
  state_billing: Yup.mixed().test("*State is required", (value) => {
    return typeof value === "string" || typeof value === "object";
  }),
  city: Yup.string().required("*City is required"),
  zip: Yup.string().required("*Zip is required"),
  billing_contact: Yup.string()
    .required("*Billing contact is required")
    .email("Enter valid email"),
});

export const addAdminValidationSchema = Yup.object().shape({
  first_name: Yup.string()
    .min(2, "Too short!")
    .max(70, "Too long!")
    .required("*First name is required"),
  last_name: Yup.string()
    .min(2, "Too short!")
    .max(70, "Too long!")
    .required("*Last name is required"),
  email: Yup.string()
    .email("Please enter valid email")
    .required("*Email is required"),
  phone_number: Yup.string().required("*Phone number is required"),
});

export const generateAddAdminPayload = (data: IAddAdminDetails) => {
  const { first_name, last_name, email, phone_number, primaryTeam, role } =
    data;
  const payload = {
    first_name,
    last_name,
    email,
    mobile: phone_number,
    primary_team_id: primaryTeam,
    role,
  };
  return payload;
};

export const generatePayloadBilling = (values: Accounts | null) => {
  let country;
  let state;

  if (typeof values?.country_billing !== "string") {
    country = values?.country_billing?.value;
  } else country = values?.country_billing;

  if (typeof values?.state_billing !== "string") {
    state = values?.state_billing?.value;
  } else state = values?.state_billing;

  const _values = {
    company_name: values?.company_name || "",
    domain_address: values?.domain_address || "",
    address1: values?.address1 || "",
    city: values?.city || "",
    state: state || "",
    country: country || "",
    zip: values?.zip?.toString() || "",
    billing_contact: values?.billing_contact?.toString() || "",
  };
  return removeEmptyValues(_values);
};

export const logoType = {
  WHITE_LOGO: "WHITE_LOGO",
  COLOUR_LOGO: "COLOUR_LOGO",
};
