import { Box, Typography } from "@mui/material";
import { ErrorMessage, useFormikContext } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import Select, { SingleValue, StylesConfig } from "react-select";
import { ChartText } from "../commonStyled/Typography";
interface SingleSelectProps<T> {
  name: string;
  options: T[];
  selected?: SingleValue<T>;
  labelKey: keyof T;
  valueKey: keyof T;
  onChange?: (selectedOptions: SingleValue<T>) => void;
  onInputChange?: (searchVal: string) => void;
  onFocus?: () => Promise<void>;
  classname?: string;
  label?: string
  disabled?: boolean
  variable?: boolean
  loadMore?: (pageNumber: number) => Promise<void>
  totalCount?: number;
  initialPage?: number;
  minMenuHeight?: number;
  menuHeight?: number;
  fontBlue?: boolean,
  background?: string
}

export interface Option {
  label: string;
  value: string | number;
}

export const customStylesdefault = (fontPrimary: boolean, background?: string): StylesConfig<any> => ({
  control: (provided) => ({
    ...provided,
    border: '1px solid var(--Gray)',
    backgroundColor: background ? background : "transparent",
    flexWrap: "nowrap",
    minHeight: '47px',
    borderRadius: "6px",
    transition: 'all 0.3s',
    // boxShadow: "0 0 0 0px var(--Gray) !important",
    boxShadow: "none !important",
    "&:focus": {
      // borderColor: "var(--PrimaryBlue)",
      border: '1px solid transparent',
      boxShadow: "0 0 0 2px var(--PrimaryBlue) !important",
      outline: "0 !important",
    },
    "&:hover": {
      border: '1px solid transparent',
      boxShadow: "0 0 0 1.5px var(--PrimaryBlue) !important",
      outLine: "none !important",
    },
  }),
  valueContainer: (provided) => ({
    ...provided,
    minWidth: "100px",
    paddingRight: "0",
    paddingLeft: "15px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  menu: (provided) => ({
    ...provided,
  }),
  menuList: (provided) => ({
    ...provided,
  }),
  input: (provided) => ({
    ...provided,
    // margin: "0",
    // padding: "8px 0",
    padding: "0 0",
    margin: "7px 0px",
    fontSize: "1rem",
    lineHeight: "1.4375em",
    color: "var(--PrimaryDark)",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: "1rem",
    lineHeight: "1.4375em",
    color: fontPrimary ? "var(--PrimaryBrandColour)" : 'inherit',
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "1rem",
    lineHeight: "1.4375em",
    color: "var(--PrimaryDark)",
    backgroundColor: "var(--ThemeWhite)",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "var(--themeColor)",
    },
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
  }),
  placeholder: (provided) => {
    return {
      ...provided,
      color: "var(--Gray)",
      fontWeight: "400",
      fontSize: "1rem",
      lineHeight: "17px",
    };
  },
});

const SingleSelect = <T,>({
  onInputChange,
  onFocus,
  options,
  onChange,
  labelKey,
  valueKey,
  name,
  selected,
  classname,
  label,
  disabled = false,
  variable = false,
  loadMore,
  totalCount,
  initialPage = 1,
  menuHeight = 175,
  minMenuHeight = 160,
  fontBlue = false,
  background
}: SingleSelectProps<T>) => {

  const formik = useFormikContext();

  const [selectedOptions, setSelectedOptions] = useState<SingleValue<T>>(null);
  const [pageNumber, setPageNumber] = useState(initialPage);

  const handleSelectChange = useCallback(
    (selectedOptions: SingleValue<T>) => {
      setSelectedOptions(selectedOptions);
      if (onChange) onChange(selectedOptions);
    },
    [onChange]
  );

  const handleInputChange = (inputValue: string) => {
    if (onInputChange) onInputChange(inputValue);
  };

  const mappedOptions: any = useMemo(() => {
    if (options?.length === 0) {
      return [];
    } else {
      return options?.map?.((option) => ({
        label: option[labelKey] as string,
        value: option[valueKey],
      }));
    }
  }, [labelKey, options, valueKey]);

  const mappedlValues: any = useMemo(() => {
    if (selected) {
      return {
        label: selected[labelKey as keyof SingleValue<T>] as string,
        value: selected[valueKey as keyof SingleValue<T>],
      };
    }
  }, [labelKey, valueKey, selected,])

  const mappedInitialValues: any = useMemo(() => {
    if (!selected) {
      return {}
    } else {
      return mappedOptions?.find?.((option: any) =>
        // eslint-disable-next-line
        option.value == mappedlValues.value
      )
    }
  }, [selected, mappedOptions, mappedlValues])

  useEffect(() => {
    if (selected) {
      setSelectedOptions(mappedInitialValues);
    }
    // eslint-disable-next-line
  }, [mappedInitialValues])

  const isWrappedInFormik = !!formik

  const handleMenuScroll = async (event: WheelEvent | TouchEvent) => {
    const target = event.target as HTMLDivElement;
    const { scrollHeight, scrollTop, clientHeight } = target;
    const isAtBottom = scrollHeight - (scrollTop + clientHeight) <= 10;
    const shouldLoadMore = !(totalCount && mappedOptions.length === totalCount);

    if (isAtBottom && loadMore && shouldLoadMore) {
      await loadMore(pageNumber + 1);
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const handlePageNumber = () => {
    setPageNumber(1)
  }

  return (
    <>
      <Box sx={{ pointerEvents: disabled ? 'none' : 'auto', }}>
        <ChartText sx={{ marginBottom: '8px' }} dBlock className="text-Gray">
          {label}
        </ChartText>

        <Select
          name={name}
          className={`${classname} ${(disabled ? 'select-disable' : '')} ${((variable && !disabled) ? 'select-active' : '')} `}
          options={mappedOptions}
          value={selectedOptions}
          onChange={handleSelectChange}
          isSearchable
          onFocus={onFocus}
          placeholder={"Select"}
          onInputChange={handleInputChange}
          styles={customStylesdefault(fontBlue,background)}
          maxMenuHeight={menuHeight}
          minMenuHeight={minMenuHeight}
          menuPlacement="auto"
          onMenuClose={handlePageNumber}
          onMenuScrollToBottom={handleMenuScroll}
          components={{
            DropdownIndicator: () => (
              <div style={{ marginRight: "10px", display: "flex" }}>
                <svg
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.646447 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L7 6.29289L12.6464 0.646447C12.8417 0.451184 13.1583 0.451184 13.3536 0.646447C13.5488 0.841709 13.5488 1.15829 13.3536 1.35355L7.35355 7.35355C7.15829 7.54882 6.84171 7.54882 6.64645 7.35355L0.646447 1.35355C0.451184 1.15829 0.451184 0.841709 0.646447 0.646447Z"
                    fill="#9E9E9E"
                  ></path>
                </svg>
              </div>
            ),

          }}
        />

      </Box>
      {isWrappedInFormik && (
        <ErrorMessage name={name}>
          {(msg) => (
            <Typography
              style={{
                color: "red",
                textAlign: "left",
                fontSize: "var(--text14)",
                fontWeight: "500",
                fontFamily: "var(--bodyFont)",
                marginTop: "2px",
              }}
            >
              {msg}
            </Typography>
          )}
        </ErrorMessage>
      )}
    </>
  );
};

export default SingleSelect;
