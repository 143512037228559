import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BillingDetail, FontData, Theme } from "../../types/user/User";

export interface ISettingTheme {
  settingTheme: Theme;
  billingDetails: BillingDetail;
  fontData: FontData[];
  isEditable: boolean;
}

const initialState = {
  settingTheme: {
    PrimaryBrandColour: "",
    CTATextColour: "",
    CTAButtonColour: "",
    FontColor: "",
    BackgroundColour: "",
    ThemeOffWhite1: "",
    ThemeWhite: "",
    Montserrat: "",
    BodyFont: "Montserrat",
    HeaderFont: "Montserrat",
  },
  billingDetails: {
    address1: "",
    address2: "",
    billing_contact: "",
    city: "",
    color_logo: "",
    company_name: "",
    country: "",
    domain_address: "",
    id: null,
  },
  fontData: [],
  isEditable: false,
};

export const settingSlice: any = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setThemeInitially: (state: any, action: PayloadAction<any>) => {
      state.settingTheme = {
        ...state.settingTheme,
        ...action.payload,
      };
    },
    setFontData: (state: any, action: PayloadAction<object>) => {
      state.fontData = action.payload;
    },
    setIsEditable: (state: any, action: PayloadAction<boolean>) => {
      state.isEditable = action.payload;
    },
    setBillingDetails: (state: any, action: PayloadAction<object>) => {
      state.billingDetails = {
        ...state.billingDetails,
        ...action.payload,
      };
    },
    flushThemeData: (state: any) => {
      state.settingTheme = [];
      state.billingDetails = {};
      state.fontData = [];
    },
  },
});

export const theme = (state: { theme: ISettingTheme }) => state.theme;

export const getEditableValue = (state: { theme: ISettingTheme }) =>
  state.theme.isEditable;

export const { actions, reducer } = settingSlice;

export const {
  setThemeInitially,
  setFontData,
  setBillingDetails,
  flushThemeData,
  setIsEditable,
} = actions;

export default reducer;
