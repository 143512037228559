import React from 'react';

interface IPreviousIcons {
    onClick?: () => void,
    style?: React.CSSProperties;
    disabled?: boolean
}

const PreviousIcon = ({ onClick, style, disabled }: IPreviousIcons) => {
    return disabled ? null : (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" onClick={onClick} style={style} >
            <path d="M0 0h24v24H0z" fill="none" />
            <path
                fill="currentColor"
                d="M15.41 16.34L10.83 12l4.58-4.34a.996.996 0 1 0-1.41-1.41l-5.66 5.66a.996.996 0 0 0-.01 1.41l5.66 5.66a.996.996 0 1 0 1.41-1.41z"
            />
        </svg>
    );
}
export default PreviousIcon;