import React, { useMemo } from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';
import CTAButton from '../../../../components/button/CTAButton';
import { IPlanList } from '../../../../types/dashboard/tier-limit/TierCompany';
import { ChartText } from '../../../../components/commonStyled/Typography';
import { CONSTANTS } from '../../../../constant/general.constant';

interface PricingCardProps {
  planDetails: IPlanList;
  handleUpdate: (id: number) => void;
}

interface OfferDetail {
  [key: string]: number | string;
}

const PricingCard: React.FC<PricingCardProps> = ({ planDetails, handleUpdate }) => {

  const { name, price, admin_limit, email_limit, team_limit, user_limit, team_members_limit, secondary_team_functionality, is_active, id, is_enterprise } = planDetails;

  const handleUpdateClick = (id: number) => (e: any) => {
    if (handleUpdate) {
      handleUpdate(id);
    }
  };

  const offerDetail: OfferDetail = useMemo(() => {
    return {
      'Admin Limit': admin_limit || (is_enterprise ? CONSTANTS.CUSTOM : '-'),
      'Email Limit': email_limit || (is_enterprise ? CONSTANTS.CUSTOM : '-'),
      'Team Limit': team_limit || (is_enterprise ? CONSTANTS.CUSTOM : '-'),
      'User Limit': user_limit || (is_enterprise ? CONSTANTS.CUSTOM : '-'),
      'Team Member': team_members_limit || (is_enterprise ? CONSTANTS.CUSTOM : '-')
    };
  }, [admin_limit, email_limit, user_limit, team_limit, team_members_limit, is_enterprise]);

  const offerDetails = useMemo(() => {
    return Object.keys(offerDetail).map(key => ({ key, value: offerDetail[key] }));
  }, [offerDetail]);

  const _price = price ? `${price}/month` : 'Custom';
  const _secondaryTeam = secondary_team_functionality ? CONSTANTS.ENABLED : CONSTANTS.DISABLED;

  return (
    <Card>
      <CardContent>
        <Typography variant="h5" gutterBottom>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {name}
            <Box className="online-indicator" sx={{ background: is_active ? 'green' : 'red' }}>
              <span className="blink" style={{ background: is_active ? 'green' : 'red' }} ></span>
            </Box>
          </Box>
        </Typography>
        <Typography variant="h4" color=" var(--PrimaryBlue);" gutterBottom>
          {_price}
        </Typography>
        <Typography variant="body1" paragraph sx={{display:'flex', flexDirection: 'column', gap: '0.5rem',}}>
          {offerDetails?.map?.((item) =>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', marginRight: { lg: 5, md: 3, sm: 1 } }}>
              <ChartText dBlock className="text-Gray">
                {item.key}
              </ChartText>
              <ChartText dBlock sx={{ color: 'var(--PrimaryBlue)' }} className="text-PrimaryDark">
                {item.value || '-'}
              </ChartText>
            </Box>
          )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginRight: { lg: 5, md: 3, sm: 1 } }}>
            <ChartText dBlock className="text-Gray">
              Secondary Team Functionality
            </ChartText>
            <ChartText dBlock sx={{ color: 'var(--PrimaryBlue)' }} className="text-PrimaryDark">
              {_secondaryTeam}
            </ChartText>
          </Box>

        </Typography>
        <Box textAlign={'end'} marginTop={5}>
          <CTAButton varient="Primary" text="Edit" onClick={handleUpdateClick(id)} />
        </Box>
      </CardContent>
    </Card>
  );
};

export default PricingCard;
