import { experimentalStyled } from "@mui/material/styles";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import { ChangeEvent } from "react";
import { CardTitle } from "../commonStyled/Typography";
import CTAButton from "../button/CTAButton";
import CustomFilter from "../filters/CustomFilter";
import { CreatedBy } from "../../types/templates/templates";
import MultiSelect from "../multi-select/MultiSelect";
import CustomTextField from "./CustomTextField";


export const CardStyledComponent = experimentalStyled(Card)``;

export interface ICardComponent {
  children?: JSX.Element | string;
  title?: JSX.Element | string;
  titleClass?: string;
  search?: boolean;
  filterValue?: string;
  handleSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
  handleFilter?: (value: string) => void;
  filter?: boolean;
  multiFilter?: boolean;
  isButton?: boolean;
  buttonVarient?: "Primary" | "Secondary" | "Transparent" | undefined;
  buttonText?: string;
  searchPlaceholder?: string
  handleButtonClick?: (values: any) => Promise<void>
  handleMultiFiterFocus?: () => Promise<void>
  onChange?: (selectedOptions: any) => void;
  filterOptions?: CreatedBy[];
  onInputChange?: (searchVal: string) => void
  borderRadius?: string;
  checkboxFilter?: boolean;
  handleFilterCheckBox?: (filterFlag: boolean) => void;
  dropRef?: React.RefObject<HTMLDivElement> | null;
  myRef?: React.RefObject<HTMLDivElement | null>;
  id?: string,
  searchValue?: string;
  loadMore?: (page: number) => Promise<void>;
  totalCount?: number
}

const options = [
  { label: "Primary", value: "PRIMARY" },
  { label: "Secondary", value: "SECONDARY" },
];

export const CardComponent = (props: ICardComponent) => {

  return (
    <>
      <Card
        sx={{
          boxShadow: "none",
          borderRadius: `${props.borderRadius ? props.borderRadius : '20px'}`,
          backgroundColor: "var(--OffWhite)",
          padding: { xs: "12px", lg: "18px 24px", xl: "24px 36px" },
        }}
        id={props.id}
      >
        <Box className="" sx={{ display: "flex", flexWrap: "wrap", justifyContent: 'space-between', rowGap: '20px' }}>
          <CardTitle
            sx={{
              width: { sm: "100%", lg: "auto" },
              maxWidth: {lg:'50%'},
            }}
            className={`${props.titleClass ? props.titleClass : ""}`}
          >
            {props.title}
          </CardTitle>
          {props.search || props.filter || props.isButton || props.multiFilter ? (
            <Box
              sx={{
                marginLeft: { sm: "0", lg: "auto" },
                display: "flex",
                flexWrap: "wrap",
                justifyContent: { xs: 'center', md: 'flex start', lg: "flex-end" },
                width: "auto",
                gap: "10px 0",
                maxWidth: { lg: "calc(100% - 300px)" },
              }}
            >
              {props.isButton ? (
                <CTAButton text="" varient={props.buttonVarient} onClick={props.handleButtonClick} >
                  {props.buttonText}
                </CTAButton>
              ) : (
                ""
              )}
              {props.search ? (
                <Box
                  sx={{
                    maxWidth: { sm: "280px", xl: "350px" },
                    minWidth: { sm: "280px", xl: "350px" },
                    width: { sm: "100%", xl: "350px" },
                    marginRight: { md: "20px", xl: "0" },
                  }}
                >
                  <FormControl
                    sx={{
                      width: "100%",
                      fontSize: "var(--text14)",
                      lineHeight: "1.25rem",
                      ".MuiOutlinedInput-root": {
                        borderRadius: "100px",
                        input: {
                          padding: "10.5px 14px",
                        },
                      },
                      '&:hover': {
                        '.MuiOutlinedInput-notchedOutline': {
                          borderColor: 'var(--PrimaryBrandColour) !important',
                        }
                      },
                      '.MuiOutlinedInput-notchedOutline': {
                        borderColor: 'rgba(0, 0, 0, 0.23) !important',
                      },
                      '.Mui-focused': {
                        '.MuiOutlinedInput-notchedOutline': {
                          borderColor: 'var(--PrimaryBrandColour) !important',
                        }
                      }
                    }}
                    size="small"
                  >
                    <OutlinedInput
                      placeholder={props.searchPlaceholder}
                      value={props.searchValue}
                      onChange={props.handleSearch} 
                      sx={{background : 'var(--ThemeWhite)'}}
                      />
                  </FormControl>
                </Box>
              ) : (
                ""
              )}
              {props.filter ? (
                <Box
                  sx={{
                    maxWidth: { sm: "270px", xl: "322px" },
                    minWidth: { sm: "270px", xl: "322px" },
                    width: { sm: "100%", xl: "322px" },
                    marginLeft: { xl: "34px" },
                  }}
                >
                  <FormControl size="small" sx={{ width: "100%" }}>
                    <CustomFilter
                      name="filterSearch"
                      options={options}
                      handleFilterValue={props.handleFilter}
                      {...props}
                    />
                  </FormControl>
                </Box>
              ) : (
                ""
              )}
              {props.multiFilter ? (
                <Box
                  sx={{
                    maxWidth: { sm: "270px", xl: "322px" },
                    minWidth: { sm: "270px", xl: "322px" },
                    width: { sm: "100%", xl: "322px" },
                    marginLeft: { xl: "34px" },
                  }}
                >
                  <FormControl size="small" sx={{ width: "100%" }}>
                    <MultiSelect
                      placeholder="Created By"
                      onChange={props.onChange}
                      options={props.filterOptions}
                      onInputChange={props.onInputChange}
                      onFocus={props.handleMultiFiterFocus}
                      labelKey={'first_name'}
                      valueKey={'id'}
                      loadMore={props.loadMore}
                      totalCount={props.totalCount}
                      background ='var(--ThemeWhite)'
                    />
                  </FormControl>
                </Box>
              ) : (
                ""
              )}
              {props.checkboxFilter ? (
                <Box
                  sx={{
                    maxWidth: { sm: "270px", xl: "322px" },
                    minWidth: { sm: "270px", xl: "322px" },
                    width: { sm: "100%", xl: "322px" },
                    marginLeft: { xl: "34px" },
                  }}
                >
                  <FormControl size="small" sx={{ width: "100%" }}>
                    <CustomTextField onFilterClick={props.handleFilterCheckBox} dropRef={props.dropRef} />
                  </FormControl>
                </Box>
              ) : (
                ""
              )}
            </Box>
          ) : (
            ""
          )}
        </Box>
        {props.children && (
          <CardContent
            sx={{
              // backgroundColor: "var(--OffWhite)",
              padding: "0 !important",
              marginTop: "40px",
            }}
          >
            {props.children}
          </CardContent>
        )}
      </Card>
    </>
  );
};
