import * as Yup from 'yup';
import { Accounts } from '../../../types/user/User';
import { removeEmptyValues } from '../../../utils/Index';

export const getInitialValues = (data: Partial<Accounts> | null) => {
  const values = {
    company_name: data?.company_name || '',
    domain_address: data?.domain_address || '',
    billing_contact: data?.billing_contact || '',
    address1: data?.address1 || '',
    state: data?.state || '',
    city: data?.city || '',
    country: data?.country || '',
    zip: data?.zip || '',
    accept_non_matching_domains: data?.accept_non_matching_domains || false,
  };
  return values;
};

export const getPlanLimitInitialValues = (data: any | null) => {
  const values = {
    account_plan: data?.plan_detail_id || '',
    admin_limit: data?.admin_limit || '',
    user_limit: data?.user_limit || '',
    team_members_limit: data?.team_members_limit || '',
    email_limit: data?.email_limit || '',
    team_limit: data?.team_limit || '',
    secondary_team_functionality: data?.secondary_team_functionality || false,
    billing_id: data?.billing_id || '',
  };
  return values;
};

export const validationSchema = Yup.object().shape({
  company_name: Yup.string().required('Company name is required'),
  domain_address: Yup.string().required('Domain address is required'),
  billing_contact: Yup.string().required('Billing contact is required'),
  address1: Yup.string().required('Street address is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  country: Yup.string().required('Country is required'),
  zip: Yup.number().required('Post code is required'),
});

export const validationSchemaLimit = Yup.object().shape({
  account_plan: Yup.string().required('Account Plan is required'),
  admin_limit: Yup.string().required('Admin limit is required'),
  user_limit: Yup.string().required('User limit is required'),
  team_limit: Yup.string().required('Team limit is required'),
  email_limit: Yup.string().required('Monthly send Limit is required'),
  team_members_limit: Yup.string().required('Team member limit is required'),
  secondary_team_functionality: Yup.boolean().required('Accept secondary is required'),
  billing_id: Yup.string().required('Billing id is required'),
});

export const generatePayload = (data: any) => {
  const payload = {
    company_name: data?.company_name,
    domain_address: data?.domain_address,
    billing_contact: data?.billing_contact,
    address1: data?.address1,
    address2: data?.address2,
    city: data?.city,
    state: data?.state,
    country: data?.country,
    zip: data?.zip,
    accept_non_matching_domains: data?.accept_non_matching_domains,
  };

  return removeEmptyValues(payload);
};

export const generatePayloadLimit = (data: any) => {
  const payload = {
    plan_detail_id: data?.account_plan,
    admin_limit: data?.admin_limit,
    user_limit: data?.user_limit,
    team_members_limit: data?.team_members_limit,
    email_limit: data?.email_limit,
    team_limit: data?.team_limit,
    secondary_team_functionality: data?.secondary_team_functionality,
  };

  return removeEmptyValues(payload);
};
