import { axiosDelete, axiosGet, axiosPost, axiosPut } from '../axios/config';

export const dashboardLogin = (data: any) => {
  return axiosPost(`admin/auth/login`, data);
};

export const createDashboardAdmin = (data: any) => {
  return axiosPost(`admin`, data);
};

export const getAdminList = (params: any = {}) => {
  return axiosGet(`admin`, params);
};

export const adminForgotPassword = (data: object) => {
  return axiosPost('admin/auth/forgot-password', data);
};

export const adminResetPassword = (data: object) => {
  return axiosPost('admin/auth/reset-password', data);
};

export const resetPasswordDashboard = (data: any = {}) => {
  return axiosPost(`admin/auth/forgot-password`, data);
};

export const removeAdminById = (id: number) => {
  return axiosDelete(`admin/${id}`, {});
};

export const removeUserById = (id: number) => {
  return axiosDelete(`admin/user/${id}`, {});
};

export const removeTeamMemberById = (id: number) => {
  return axiosDelete(`admin/contact/${id}`, {});
};

export const getCompanyList = (params: any = {}) => {
  return axiosGet(`admin/account`, params);
};

export const getCompanyById = (id: string, params: any = {}) => {
  return axiosGet(`admin/account/${id}`, params);
};

export const getUserById = (id: string, params: any = {}) => {
  return axiosGet(`admin/user/${id}`, params);
};


export const getPlanList = (params: any = {}) => {
  return axiosGet(`admin/planDetail`, params);
};

export const getPlanById = (id: number | string) => {
  return axiosGet(`admin/planDetail/${id}`);
};

export const getDashboardCount = () => {
  return axiosGet(`admin/dashboard/get-all-module-count`);
};

export const getUserList = (params: any = {}) => {
  return axiosGet(`admin/user`, params);
};

export const updateUserDetails = (data: any = {}, id: number | string) => {
  return axiosPut(`admin/user/${id}`, data);
};

export const updateTeamMemberDetails = (data: any = {}, id: number | string) => {
  return axiosPut(`admin/contact/${id}`, data);
};

export const getContactList = (params: any = {}) => {
  return axiosGet(`admin/contact`, params);
};

export const updatePlan = (data: any = {}, id: number | string) => {
  return axiosPut(`admin/planDetail/${id}`, data);
};

export const updateCompanyDetails = (data: any = {}, id: number | string) => {
  return axiosPut(`admin/account/${id}`, data);
};

export const getPlans = (params: any = {}) => {
  return axiosGet(`admin/planDetail`, params);
};

export const getRoles = (id: number | string, params: any = {}) => {
  return axiosGet(`admin/roles/${id}`, params);
};

export const getPlansById = (id: number | string, params: any = {}) => {
  return axiosGet(`/admin/planDetail/${id}`, params);
};

export const updateCompanyPlanLimit = (data: any = {}, id: number | string) => {
  return axiosPut(`admin/account/update-plan/${id}`, data);
};

export const getTeamListByAccountId = (id: number | string, params: any = {}) => {
  return axiosGet(`admin/team/account/${id}`, params);
};

export const getTeamMemberById = (id: string, params: any = {}) => {
  return axiosGet(`admin/contact/${id}`, params);
};

export const sendSuperAdminVerificationMail = (data: any = {}) => {
  return axiosPost(`admin/auth/send-sender-verification-email`, data);
};