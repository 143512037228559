import { Box, Typography } from "@mui/material";

import {
  Anchor,
  CardTitle,
  ChartText,
} from "../../components/commonStyled/Typography";
import { LogoFull } from "../../components/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getAuthData, setSuperAdminLogoutData } from "../../redux/slices/SuperAdminSlice";
import { setLogoutData } from "../../redux/slices/AuthSlice";
import { flushThemeData } from "../../redux/slices/SettingSlice";
import { flushPermissions } from "../../redux/slices/permissionSlice";

const UnAuth = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const superAdminDetail = useSelector(getAuthData);

  const handleGoBack = () => {
    if (superAdminDetail && superAdminDetail.token !== null)
      dispatch(setSuperAdminLogoutData());
    else {
      dispatch(setLogoutData());
      dispatch(flushThemeData());
      dispatch(flushPermissions());
    }
    navigate('/');
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ paddingTop: { xs: '50px', lg: '0' } }}
      bgcolor={"#FAFBFC"}
      minHeight={"100dvh"}
      flexWrap={'wrap'}
      flexDirection={'column'}
    >
      <Box
        sx={{ maxWidth: { sm: "90vw", md: "90vw", lg: "824px" } }}
        p={5}
        width={"100%"}
        bgcolor="white"
        borderRadius={"20px"}
        textAlign="center"
        position={"relative"}
      >
        <Typography position={"absolute"} right={"20px"} top={"12px"}>
          <ChartText>
            <Anchor onClick={handleGoBack}>go back</Anchor>
          </ChartText>
        </Typography>
        <Typography
          sx={{
            all: "initial",
            display: "block",
            textAlign: "center",
            fontFamily: "var(--bodyFont)",
          }}
        >
          <CardTitle className={``}>Permissions Denied</CardTitle>
        </Typography>
      </Box>
      <Box width={'100%'} sx={{ marginTop: { sm: '30px', md: '60px', lg: '90px', xl: '120px' }, paddingBottom: { xs: '50px', lg: '0' } }}>
        <Box color={'var(--PrimaryBrandColour)'} width={152} height={42} margin={'0 auto'}>
          <LogoFull className="w-full h-full" />
        </Box>
      </Box>
    </Box>
  );
};

export default UnAuth;
