import { Box, Grid, Switch, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { MenuText } from '../../../../components/commonStyled/Typography';
import InputField from '../../../../components/input-field/InputField';
import { generatePayload, getInitialValues, validationSchema } from '../helper';
import CTAButton from '../../../../components/button/CTAButton';
import { getCompanyById, updateCompanyDetails } from '../../../../services/SuperAdmin';
import { Accounts } from '../../../../types/user/User';

interface ICompanyEditDetail {
  updateCompanyList: () => void;
  closeModal: () => void;
}

const CompanyEditDetail = ({ updateCompanyList, closeModal }: ICompanyEditDetail) => {

  const [companyDetails, setCompanyDetails] = useState<Partial<Accounts | null>>(null);

  const companyId = useMemo(() => {
    const queryString = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    const _id = searchParams.get('id');
    return _id;
  }, []);

  const handleUpdateCompany = useCallback(async (values: Partial<Accounts>) => {
    if (companyId) {
      try {
        const payload = generatePayload(values);
        const result = await updateCompanyDetails(payload, companyId);
        if (result.data.data) {
          updateCompanyList();
          closeModal();
        }
      } catch (error) {
        console.log("error: ", error);
      }
    }
  }, [closeModal, companyId, updateCompanyList]);



  const fetchCompanyById = useCallback(async (id: string) => {
    try {
      const result = await getCompanyById(id);
      if (result.data.data) {
        setCompanyDetails(result.data.data);
      }
    } catch (error) {
      console.log("error: ", error);
    }
  }, []);


  const initialValues = useMemo(() => {
    return getInitialValues(companyDetails);
  }, [companyDetails]);

  useEffect(() => {
    if (companyId)
      fetchCompanyById(companyId);
  }, [fetchCompanyById, companyId]);

  return (
    <Box
      sx={{
        padding: "30px 30px 15px",
        marginTop: "30px"
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleUpdateCompany}
        enableReinitialize={true}
      >
        {(formik) => {
          return (
            <Form>
              <Grid container spacing={{ xs: 4, sm: 6, }}>
                <Grid item xs={12} lg={8}>
                  <Grid container spacing={{ xs: 4, sm: 6, }}>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className='form-label-typography'>
                          <MenuText>Company Name</MenuText>
                        </Typography>
                        <InputField name="company_name" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className='form-label-typography'>
                          <MenuText>Domain Address</MenuText>
                        </Typography>
                        <InputField name="domain_address" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className='form-label-typography'>
                          <MenuText>Billing Contact</MenuText>
                        </Typography>
                        <InputField name="billing_contact" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className='form-label-typography'>
                          <MenuText>Street Address</MenuText>
                        </Typography>
                        <InputField name="address1" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className='form-label-typography'>
                          <MenuText>City</MenuText>
                        </Typography>
                        <InputField name="city" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className='form-label-typography'>
                          <MenuText>State</MenuText>
                        </Typography>
                        <InputField name="state" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Box>
                        <Typography className='form-label-typography'>
                          <MenuText>Country</MenuText>
                        </Typography>
                        <InputField name="country" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} >
                      <Box>
                        <Typography className='form-label-typography'>
                          <MenuText>Post Code</MenuText>
                        </Typography>
                        <InputField name="zip" />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12}>
                      <Box >
                        <Typography>
                          <MenuText>Accept Non-Matching Domains</MenuText>
                          <Switch
                            checked={formik.values.accept_non_matching_domains}
                            onChange={(e: any) => formik.setFieldValue('accept_non_matching_domains', e.target.checked)}
                            sx={{ marginLeft: 1 }}
                          />
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} >
                      <Box sx={{ marginTop: { xs: 4 }, marginBottom: { xs: 2 }, textAlign: 'center' }}>
                        <CTAButton text='Update' varient='Primary' type='submit' />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>

    </Box>
  );
};

export default CompanyEditDetail;