import React from "react";

interface IMembersIcons {
  onClick?: () => void;
  style?: React.CSSProperties;
  disabled?: boolean;
}

const MembersIcon = ({ onClick, style, disabled }: IMembersIcons) => {
  return disabled ? null : (
    <svg
      className=""
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      onClick={onClick}
      style={style}
    >
      <path d="M16.67 13.13C18.04 14.06 19 15.32 19 17v3h4v-3c0-2.18-3.57-3.47-6.33-3.87zM15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4c-.47 0-.91.1-1.33.24C14.5 5.27 15 6.58 15 8s-.5 2.73-1.33 3.76c.42.14.86.24 1.33.24zm-6 0c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0-6c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm0 7c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4zm6 5H3v-.99C3.2 16.29 6.3 15 9 15s5.8 1.29 6 2v1z"></path>
    </svg>
  );
};

export default MembersIcon;
