import { lazy } from "react";
import ResetPasswordUser from "../../pages/profile/ChangePassword";
import UpdateBilling from "../../pages/settings/components/UpdateBilling";
import VerifyEmail from "../../pages/forgot-password/verify-email/VerifyEmail";
import PricingTable from "../../pages/login/PricingTable";
import Companies from "../../pages/admin/companies/Index";
import Users from "../../pages/admin/users/Index";
import MiovaAdmin from "../../pages/admin/miova-admin/Index";
import TeamMember from "../../pages/admin/team-member/Index";
import TierLimit from "../../pages/admin/tier-limit/Index";
import Dashboard from "../../pages/admin/dashboard/Index";
import UnAuth from "../../pages/unauth/Unauth";
const Templates = lazy(() => import("../../pages/templates/Index"));
const EditTemplate = lazy(() => import("../../pages/templates/edit-template/EditTemplate"));
const RegistrationCompletion = lazy(() => import("../../pages/unauth/RegistrationCompletion"));
const SetPassword = lazy(() => import("../../pages/forgot-password/reset-password/SetPassword"));
const CreateTemplate = lazy(() => import("../../pages/templates/create-template/CreateTemplate"));
const SelectTemplate = lazy(() => import("../../pages/emails/email-builder/SelectTemplate"));
const EmailEditTemplate = lazy(() => import("../../pages/emails/email-builder/EmailEditTemplate"));
const ScheduleEmail = lazy(() => import("../../pages/emails/email-builder/ScheduleEmail"));
const Categories = lazy(() => import("../../pages/templates/categories/Categories"));
const Profile = lazy(() => import("../../pages/profile/Profile"));

const Insights = lazy(() => import("../../pages/insights/Index"));
const Login = lazy(() => import("../../pages/login/Index"));
const Registration = lazy(() => import("../../pages/registration/Index"));
const ForgotPassword = lazy(() => import("../../pages/forgot-password/Index"));
const ResetPassword = lazy(
  () => import("../../pages/forgot-password/reset-password/Index")
);
const VerifyAccount = lazy(
  () => import("../../pages/registration/VerifyAccount")
);
const Emails = lazy(() => import("../../pages/emails/Index"));
const Teams = lazy(() => import("../../pages/teams/Index"));
const Settings = lazy(() => import("../../pages/settings/Index"));

export const publicRoutes = [
  { id: "login", path: "/login", element: <Login /> },
  { id: "admin-login", path: "admin/login", element: <Login /> },
  { id: "registration", path: "/registration", element: <Registration /> },
  {
    id: "forgot-password",
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    id: "admin-forgot-password",
    path: "admin/forgot-password",
    element: <ForgotPassword />,
  },
  { id: "reset-password", path: "/reset-password", element: <ResetPassword /> },
  { id: "admin-reset-password", path: "admin/reset-password", element: <ResetPassword /> },
  { id: "set-password", path: "/set-password", element: <SetPassword /> },
  { id: "admin-set-password", path: "admin/set-password", element: <SetPassword /> },
  { id: "verify-account", path: "/verify-account", element: <VerifyAccount /> },
  { id: "verify-sender", path: "/verify-sender", element: <VerifyEmail /> },
  { id: "registration-completion", path: "/registration-completion", element: <RegistrationCompletion /> },
  { id: "pricing", path: "/pricing-table", element: <PricingTable /> },
  { id: "unauth", path: "/unauth", element: <UnAuth /> },
];

export const privateRoutes = [
  { id: "insights", path: "/", element: <Insights /> },
  { id: "Teams", path: "/teams", element: <Teams /> },
  { id: "emails", path: "/emails", element: <Emails /> },
  { id: "templates", path: "/template", element: <Templates /> },
  { id: "template-edit", path: "/template/edit/:templateId", element: <EditTemplate /> },
  { id: "template-create", path: "/template/create", element: <CreateTemplate /> },
  { id: "template-categories", path: "/template/categories", element: <Categories /> },
  { id: "settings", path: "/settings", element: <Settings /> },
  { id: "update-billing", path: "/settings/billing-edit", element: <UpdateBilling /> },
  { id: "email-template", path: "/emails/template", element: <SelectTemplate /> },
  { id: "email-template-edit", path: "/emails/template-edit/:emailId", element: <SelectTemplate /> },
  { id: "email-template-id", path: "/emails/template/:templateId", element: <EmailEditTemplate /> },
  { id: "email-schedule", path: "/emails/template/:templateId/create/:emailId", element: <ScheduleEmail /> },
  { id: "email-schedule-edit", path: "/emails/template/:templateId/edit/:emailEditId", element: <ScheduleEmail /> },
  { id: "profile", path: "/profile", element: <Profile /> },
  { id: "change-password", path: "/change-password", element: <ResetPasswordUser /> },
];

export const adminRoutes = [
  { id: "admin", path: "admin/dashboard", element: <Dashboard /> },
  { id: "admin-companies", path: "admin/companies", element: <Companies /> },
  { id: "admin-users", path: "admin/users", element: <Users /> },
  { id: "admin-team-member", path: "admin/team-member", element: <TeamMember /> },
  { id: "admin-tier-limit", path: "admin/tier-limit", element: <TierLimit /> },
  { id: "admin-miova-admin", path: "admin/miova-admin", element: <MiovaAdmin /> },
];
